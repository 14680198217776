<template>
  <transition name="modal" v-if="show">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h6>Edit Visit Date</h6>
            //
            <button @click="closeBtn" class="btn blue darken-3">Close</button>
          </div>
          <div class="modal-body">
            <div class="row" style="margin-top: 50px">
              <div class="col m6">
                <span>Date</span>
                <input
                  type="text"
                  v-model.lazy="detailData.date"
                  class="datepicker"
                  readonly="true"
                />
              </div>
              <div class="col m6 center">
                <br />
                <button
                  :disabled="action_loading"
                  class="btn blue darken-3"
                  @click="editButton"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["show", "detailData"],
  mounted() {
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },

  updated() {
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  methods: {
    editButton() {
      this.$store.dispatch("editVisitDate", {
        date: this.detailData.date,
        dots_patient_id: this.detailData.dots_patient_id,
        id: this.detailData.id,
      });
    },
    closeBtn() {
      location.reload();
    },
  },
  computed: {
    edit_success() {
      return this.$store.getters.dots_visit_date_edit_success;
    },
    edit_error() {
      return this.$store.getters.dots_visit_date_edit_error;
    },
    action_loading() {
      return this.$store.getters.dots_visitdates_action_loading;
    },
  },
  watch: {
    edit_success(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "Updated",
        });
        this.$emit("close");
      }
    },
    edit_error(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "error",
          text: "Your new date is existed!!",
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  margin: 0px auto;
  height: 300px;
  overflow-y: auto;
  padding: 20px 30px;
  background-color: var(--app-modal-background);
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-body {
  margin: 20px 0;
}

.modal-header {
  border-bottom: 0.2px solid black;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.side_effect_container {
  padding: 20px;
}
</style>
