<template>
  <div class="row">
    <div class="col m4">
      <span>Completed DOT supervision (Y/N)</span>
      <select
        v-if="data.patient.finish == 1 || data.patient.finish == 2"
        disabled
        v-model="data.patient.finish"
      >
        <option value="1">No</option>
        <option value="2">Yes</option>
      </select>
      <select v-else v-model="finish">
        <option value="1">No</option>
        <option value="2">Yes</option>
      </select>
    </div>
    <div class="col m4">
      <span>DOTS Enddate</span>
      <input
        v-if="data.patient.finish == 1 || data.patient.finish == 2"
        type="text"
        class="datepicker"
        v-model="data.patient.dots_enddate"
        disabled
      />
      <input
        v-else
        type="text"
        class="datepicker"
        v-model.lazy="dots_enddate"
      />
    </div>
    <div
      class="col m4 center"
      v-show="data.patient.finish != 1 && data.patient.finish != 2"
    >
      <br />
      <button
        class="btn blue darken-3"
        :disabled="action_loading"
        @click="updateBtn"
      >
        Update
      </button>
    </div>
  </div>
</template>

<script>
import { dayDiff } from "./../../../misc/visit_date_service";
export default {
  data() {
    return {
      dots_endate: "",
      finish: "",
    };
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
  },
  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
  },
  methods: {
    updateBtn() {
      if (this.dots_enddate && this.finish) {
        if (
          !(
            new Date(this.dots_enddate).getTime() >
            new Date(this.data.patient.dots_startdate).getTime()
          )
        ) {
          this.$notify({
            group: "noti",
            type: "error",
            text: "DOTS enddate should be greater than DOTS startdate",
          });
        } else {
          this.$store.dispatch("updateDotsPatientStatus", {
            dots_enddate: this.dots_enddate,
            finish: this.finish,
            dots_patient_id: this.$route.params.id,
          });
        }
      } else {
        alert("Some fields are required");
      }
    },
  },
  computed: {
    action_loading() {
      return this.$store.getters.dots_action_loading;
    },
    data() {
      return this.$store.getters.dots_visitdates;
    },
  },
};
</script>

<style></style>
