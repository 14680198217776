<template>
  <div class="volunteer_list">
            <div class="center">
                    <h5>Block List</h5>
                    
            </div>
             <div class="row">
                    <back-container link="/volunteers"></back-container>
            </div>
            <div class="row">
                <div class="input-field col s8 offset-s2">
                    <input  placeholder="Search here by phone,code,name" type="text" class="validate"
                     @keydown.enter="searchVolunteer" v-model="search"
                    >
                
                </div>
            </div>
            <div class="row center">
                
                <div class="card card-content " >
                    
                    <table class="app-table responsive-table">
                            <thead>
                            <tr>
                                <th>No</th><th>Name</th><th>Code</th><th>Phone</th>
                                <th>Village</th><th>Ward</th>
                                <th>Education</th><th>Occupation</th>
                                <th>Drop Out Date</th>
                                <th>Actions</th>
                                <th></th>
                            </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(volunteer,index) in volunteers">
                                    <td>{{ index+1 }}</td>
                                    <td>{{ volunteer.name }}</td>
                                    <td>{{ volunteer.code }}</td>
                                    <td>{{ volunteer.phone }}</td>
                                    <td>{{ volunteer.village }}</td>
                                    <td>{{ volunteer.ward }}</td>
                                    <td>{{ volunteer.education_status && volunteer.education_status.name }}</td>
                                    <td>{{ volunteer.occupation && volunteer.occupation.name }}</td>
                                    <td>{{  volunteer.updated_at }}</td>
                                    <td><button :disabled="actionloader"  @click="unblock(volunteer.id)" class="btn blue darken-2">Unblock</button></td>
                                </tr>
                                
                            </tbody>
                            
                    </table>
                    <div class="row center" style="margin-top:10px;">
                              <loading v-if="loading"></loading>
                    </div>
                </div>
                <div class="row center" v-if="!loading && (volunteers.length<volunteer_count)">
                    <button class="btn blue darken-2" @click="loadMore">Load More</button>
                </div>
                     
              
                  
             </div>
         
      </div>
</template>

<script>
import Loading from '@/components/Partials/Loading.vue';
export default {
  name: 'VolunteerList',
  data () {
    return {
        search:"",
    }
  },
  mounted()
  {
      
    this.$store.dispatch("clearVolunteer");
    this.getData();
  },
  methods: {
    loadMore()
    {
        this.getData(true);
    },
    searchVolunteer()
    {

            this.$router.push({name:"VolunteerBlockListSearch",params:{search:this.search}});

    },
    unblock(id)
    {
        var data={
            id:id
        };

        this.$store.dispatch("unblockVolunteer",data);
    },
    getData(load=false)
    {
         var data={
          lastid:0,
          load:load,
          block:true,
        };

        if (this.volunteers.length>0)
        {
            data.lastid=this.volunteers.length;
        }

        if (this.$route.params.search)
        {
            data.search=this.$route.params.search;
            if (!data.load)
            {
                data.lastid=0;
            }
            
        }

     
        
          this.$store.dispatch("getVolunteer",data);
    }
  },
  components: {
    "loading":Loading
  },
  computed:
  {
      volunteers()
      {
          return this.$store.getters.volunteers;
      },
      loading()
      {
          return this.$store.getters.loading;
      },
      volunteer_count()
      {
          return this.$store.getters.volunteer_count;
      },
      actionloader()
      {
          return this.$store.getters.actionloader;
      }
  },
   watch:{
    $route (to, from){
       this.getData();
     }
    } ,
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .volunteer_list 
  {
      padding-left:20px;
      padding-right:20px;
  }

  .volunteer_list .card-content 
  {
      padding:10px;
  }
</style>
