import Vue from "vue";
import Router from "vue-router";
import Login from "@/components/Login";
import Home from "@/components/Home/Home";
import Volunteer from "@/components/Volunteer/Volunteer";
import AddNew from "@/components/Volunteer/AddNew";
import EditVolunteer from "@/components/Volunteer/Edit";
import Setting from "@/components/Setting/Setting";
import VolunteerList from "@/components/Volunteer/List/VolunteerList";
import VolunteerAllList from "@/components/Volunteer/AllList/VolunteerAllList";
import VolunteerBlockList from "@/components/Volunteer/BlockList/BlockList";
import VolunteerTrashList from "@/components/Volunteer/TrashList/TrashList";
import EachVolunteer from "@/components/Volunteer/View/EachVolunteer";
import EachAllVolunteer from "@/components/Volunteer/AllView/EachVolunteer";
import PageNotFound from "@/components/Partials/PageNotFound";
import Checkings from "@/components/Checkings/Checkings";
import TptCheckings from "@/components/Checkings/TptCheckings";
import PatientEdit from "@/components/Checkings/PatientEdit";
import TptFill from "@/components/Patient/Fill/TptFill";
import CheckingBox from "@/components/CheckingBox/CheckingBox";
import FeedBack from "@/components/FeedBack/FeedBack";
import ConfirmedList from "@/components/Checkings/ConfirmedList";
import Patient from "@/components/Patient/Patient";
import TPTPatient from "@/components/Patient/TPTPatient";
import PatientFill from "@/components/Patient/Fill/Fill";
import PatientTrash from "@/components/Patient/Trash/Trash.vue";
import TransferChecking from "@/components/Checkings/Transfer/Transfer.vue";
import AddNewPatient from "@/components/Patient/NewPatient/NewPatient.vue";
import NewTptPatient from "@/components/Patient/NewPatient/NewTptPatient.vue";
import VolunteerAddTraining from "@/components/Volunteer/AddTraining.vue";
import SCC from "@/components/SCC/SCC.vue";
import Activities from "@/components/Activities/Activities.vue";
import NewActivity from "@/components/Activities/NewActivity.vue";
import EachActivity from "@/components/Activities/EachActivity.vue";
import EditActivity from "@/components/Activities/EditActivity.vue";
import ActivityChooseBox from "@/components/Activities/ChooseBox.vue";
import PAReport from "@/components/PAReport/PAReport.vue";
import PAReportResult from "@/components/PAReport/Result.vue";
import ReportedVolunteer from "@/components/PAReport/ReportedVolunteer.vue";
import NewRefer from "@/components/Patient/Refer/AddNew.vue";
import TptNewRefer from "@/components/Patient/Refer/tpt/AddNew.vue";
import EditRefer from "@/components/Patient/Refer/EditRefer.vue";
import TptEditRefer from "@/components/Patient/Refer/tpt/EditRefer.vue";
import TrashRefer from "@/components/Patient/Refer/Trash.vue";
import TptTrashRefer from "@/components/Patient/Refer/tpt/Trash.vue";
import VolunteerReport from "@/components/VolunteerReport/VolunteerReport.vue";

import MoveDOTSPatient from "@/components/DOTS/MoveDOTSPatient/MoveDOTSPatient.vue";
import DOTSPatientList from "@/components/DOTS/DOTSPatientList/DOTSPatientList.vue";
import DOTSPatientEdit from "@/components/DOTS/DOTSPatientEdit/DOTSPatientEdit.vue";
import DOTSPatientOnlyDetail from "@/components/DOTS/DOTSPatientEdit/DOTSPatientOnlyDetail.vue";
import DOTSPATIENTMonthInfo from "@/components/DOTS/DOTSPATIENTMonthInfo/DOTSPATIENTMonthInfo.vue";

import DOTSPatientEditHistory from "@/components/DOTS/DOTSPatientEditHistory/DOTSPatientEditHistory.vue";
import DOTSPatientAdd from "@/components/DOTS/DOTSPatientAdd/DOTSPatientAdd.vue";
import DOTSPatientReferList from "@/components/DOTS/DOTSPatientReferList/DOTSPatientReferList.vue";
import DOTSPatientTrash from "@/components/DOTS/DOTSPatientList/Trash.vue";
import DOTSVisitDate from "@/components/DOTS/DOTSVisitDate/DOTSVisitDate.vue";
import DOTSAllVisitDate from "@/components/DOTS/DOTSVisitDate/DOTSAllVisitDate.vue";
import CreateDOTSVisitDate from "@/components/DOTS/DOTSVisitDate/CreateDOTSVisitDate.vue";
import DOTSVISITREPORT from "@/components/DOTS/VisitReport/VisitReport.vue";
import DOTSVolunteerChange from "@/components/DOTS/VolunteerChange/VolunteerChange.vue";

import { islogin } from "./../misc/auth.js";

Vue.use(Router);

var router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Login",
      component: Login,
    },
    {
      path: "/home",
      name: "Home",
      component: Home,
      meta: {
        auth: true,
      },
    },
    {
      path: "/checkings",
      name: "Checkings",
      component: Checkings,
      meta: {
        auth: true,
      },
    },
    {
      path: "/checkings-tpt",
      name: "tpt-checkings",
      component: TptCheckings,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patient-edit/:id/:tptStatus",
      name: "PatientEdit",
      component: PatientEdit,
      meta: {
        auth: true,
      },
    },
    {
      path: "/tpt-fill/:id",
      name: "TptPatientFill",
      component: TptFill,
      meta: {
        auth: true,
      },
    },
    {
      path: "/feedback",
      name: "FeedBack",
      component: FeedBack,
      meta: {
        auth: true,
      },
    },

    {
      path: "/checkings/:search/search",
      name: "CheckingsSearch",
      component: Checkings,
      meta: {
        auth: true,
      },
    },
    {
      path: "/checkings-tpt/:search/search",
      name: "tpt-checkings-search",
      component: TptCheckings,
      meta: {
        auth: true,
      },
    },
    {
      path: "/checkings/:status/confirmed",
      name: "ConfirmedList",
      component: ConfirmedList,
      meta: {
        auth: true,
      },
    },
    {
      path: "/transfer-checkings",
      name: "TransferChecking",
      component: TransferChecking,
      meta: {
        auth: true,
      },
    },
    {
      path: "/setting",
      name: "Setting",
      component: Setting,
      meta: {
        auth: true,
      },
    },

    {
      path: "/checkingbox",
      name: "CheckingBox",
      component: CheckingBox,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patients",
      name: "Patient",
      component: Patient,
      meta: {
        auth: true,
      },
    },
    {
      path: "/tpt-patients",
      name: "TPTPatient",
      component: TPTPatient,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patients/trash",
      name: "PatientTrash",
      component: PatientTrash,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patients/:search/search",
      name: "PatientSearch",
      component: Patient,
      meta: {
        auth: true,
      },
    },
    {
      path: "/tpt-patients/:search/search",
      name: "TPTPatientSearch",
      component: TPTPatient,
      meta: {
        auth: true,
      },
    },
    {
      path: "/volunteer/blocklist",
      name: "VolunteerBlockList",
      component: VolunteerBlockList,
      meta: {
        auth: true,
      },
    },

    {
      path: "/volunteer/trashlist",
      name: "VolunteerTrashList",
      component: VolunteerTrashList,
      meta: {
        auth: true,
      },
    },

    {
      path: "/volunteers",
      name: "Volunteer",
      component: Volunteer,
      meta: {
        auth: true,
      },
    },
  
    {
      path: "/volunteer/addnew",
      name: "AddNew",
      component: AddNew,
      meta: {
        auth: true,
      },
    },
    ,
    {
      path: "/volunteer/:id/edit",
      name: "EditVolunteer",
      component: EditVolunteer,
      meta: {
        auth: true,
      },
    },

    {
      path: "/volunteer/list",
      name: "VolunteerList",
      component: VolunteerList,
      meta: {
        auth: true,
      },
    },
    {
      path: "/volunteer/all-list",
      name: "All olunteer",
      component: VolunteerAllList,
      meta: {
        auth: true,
      },
    },

    {
      path: "/volunteer/list/:search/search",
      name: "VolunteerListSearch",
      component: VolunteerList,
      meta: {
        auth: true,
      },
    },
    {
      path: "/volunteer/all-list/:search/search",
      name: "VolunteerAllListSearch",
      component: VolunteerAllList,
      meta: {
        auth: true,
      },
    },
    {
      path: "/volunteer/blocklist/:search/search",
      name: "VolunteerBlockListSearch",
      component: VolunteerBlockList,
      meta: {
        auth: true,
      },
    },
    {
      path: "/volunteer/blocklist/:search/search",
      name: "VolunteerTrashListSearch",
      component: VolunteerTrashList,
      meta: {
        auth: true,
      },
    },

    {
      path: "/volunteer/:id",
      name: "VolunteerView",
      component: EachVolunteer,
      meta: {
        auth: true,
      },
    },
    {
      path: "/all-volunteer/:id",
      name: "AllVolunteerView",
      component: EachAllVolunteer,
      meta: {
        auth: true,
      },
    },
    {
      path: "/volunteer/training/add/:id",
      name: "VolunteerAddTraining",
      component: VolunteerAddTraining,
      meta: {
        auth: true,
      },
    },

    {
      path: "/patient/fill/:id",
      name: "PatientFill",
      component: PatientFill,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patient/new",
      name: "AddNewPatient",
      component: AddNewPatient,
      meta: {
        auth: true,
      },
    },
    {
      path: "/tpt-patient/new",
      name: "NewTptPatient",
      component: NewTptPatient,
      meta: {
        auth: true,
      },
    },
    {
      path: "/scc",
      name: "SCC",
      component: SCC,
      meta: {
        auth: true,
      },
    },
    {
      path: "/activities/:year/:month",
      name: "Activities",
      component: Activities,
      meta: {
        auth: true,
      },
    },
    {
      path: "/activity/choosebox",
      name: "ActivityChooseBox",
      component: ActivityChooseBox,
      meta: {
        auth: true,
      },
    },

    {
      path: "/activities/:id/:year/:month",
      name: "EachActivity",
      component: EachActivity,
      meta: {
        auth: true,
      },
    },

    {
      path: "/activities/:id/:year/:month/new",
      name: "NewActivity",
      component: NewActivity,
      meta: {
        auth: true,
      },
    },
    {
      path: "/edit/:id/activities",
      name: "EditActivity",
      component: EditActivity,
      meta: {
        auth: true,
      },
    },
    {
      path: "/pareport",
      name: "PAReport",
      component: PAReport,
      meta: {
        auth: true,
      },
    },
    {
      path: "/pareport/result",
      name: "PAReportResult",
      component: PAReportResult,
      meta: {
        auth: true,
      },
    },
    {
      path: "/refer/new",
      name: "NewRefer",
      component: NewRefer,
      meta: {
        auth: true,
      },
    },
    {
      path: "/tpt/refer/new",
      name: "TptNewRefer",
      component: TptNewRefer,
      meta: {
        auth: true,
      },
    },
    {
      path: "/refer/edit/:patient_id",
      name: "EditRefer",
      component: EditRefer,
      meta: {
        auth: true,
      },
    },
    {
      path: "/tpt/refer/edit/:patient_id",
      name: "TptEditRefer",
      component: TptEditRefer,
      meta: {
        auth: true,
      },
    },
    {
      path: "/reported_volunteer/:month/:year",
      name: "ReportedVolunteer",
      component: ReportedVolunteer,
      meta: {
        auth: true,
      },
    },
    {
      path: "/refer/trash",
      name: "TrashRefer",
      component: TrashRefer,
      meta: {
        auth: true,
      },
    },
    {
      path: "/tpt/refer/trash",
      name: "TptTrashRefer",
      component: TptTrashRefer,
      meta: {
        auth: true,
      },
    },
    {
      path: "/volunteer_report",
      name: "VolunteerReport",
      component: VolunteerReport,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patient/dots/move/:id",
      name: "MoveDOTSPatient",
      component: MoveDOTSPatient,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patient/dots/list",
      name: "DOTSPatientList",
      component: DOTSPatientList,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patient/dots/edit/:id",
      name: "DOTSPatientEdit",
      component: DOTSPatientEdit,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patient/dots-only/edit/:id",
      name: "DOTSPatientOnlyDetail",
      component: DOTSPatientOnlyDetail,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patient/dots/month-info/:id",
      name: "DOTSPATIENTMonthInfo",
      component: DOTSPATIENTMonthInfo,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patient/dots/edit-history/:id",
      name: "DOTSPatientEditHistory",
      component: DOTSPatientEditHistory,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patient/dots/add",
      name: "DOTSPatientAdd",
      component: DOTSPatientAdd,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patient/dots/refer",
      name: "DOTSPatientReferList",
      component: DOTSPatientReferList,
      meta: {
        auth: true,
      },
    },
    {
      path: "/patient/dots/trash",
      name: "DOTSPatientTrash",
      component: DOTSPatientTrash,
      meta: {
        auth: true,
      },
    },
    {
      path: "/dots/visitdate/:id",
      name: "DOTSVisitDate",
      component: DOTSVisitDate,
      meta: {
        auth: true,
      },
    },
    {
      path: "/dots/visitdate/all/:id",
      name: "DOTSAllVisitDate",
      component: DOTSAllVisitDate,
      meta: {
        auth: true,
      },
    },
    {
      path: "/dots/visitdate/create/:id",
      name: "CreateDOTSVisitDate",
      component: CreateDOTSVisitDate,
      meta: {
        auth: true,
      },
    },
    {
      path: "/dots/visitdate/report/:id",
      name: "DOTSVISITREPORT",
      component: DOTSVISITREPORT,
      meta: {
        auth: true,
      },
    },
    {
      path: "/dots/volunteer/change/:id",
      name: "DOTSVolunteerChange",
      component: DOTSVolunteerChange,
      meta: {
        auth: true,
      },
    },

    {
      path: "*",
      name: "PageNotFound",
      component: PageNotFound,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (islogin("admin")) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    next();
  }
});

export default router;
