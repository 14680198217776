<template>
  <fragment v-if="data.tb_conclusion">
    <td>{{ conclusion }}</td>
    <td>{{ tb_status }}</td>
    <td>{{ data.tb_conclusion.union_temporary_code }}</td>
    <td>{{ tb_type }}</td>
    <td>{{ tb_site }}</td>
    <td>{{ treatment_reg }}</td>
    <td>{{ data.tb_conclusion.start_date }}</td>
    <td>{{ treatment_outcome }}</td>
    <td>{{ data.tb_conclusion.outcome_date }}</td>
    <td>{{ smokingstatus }}</td>
    <td>
      {{
        data.tb_conclusion.urban_rural == 1
          ? "Urban"
          : data.tb_conclusion.urban_rural == 2
          ? "Rural"
          : ""
      }}
    </td>
    <td>{{ dmstatus }}</td>
    <td>{{ hivstatus }}</td>
    <td>{{ data.tb_conclusion.cpt_date }}</td>
    <td>{{ data.tb_conclusion.art_date }}</td>
    <td>{{ hivcare }}</td>
    <td>{{ data.tb_conclusion.hiv_code }}</td>
    <td>{{ data.tb_conclusion.remark }}</td>
  </fragment>
  <fragment v-else>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
  </fragment>
</template>

<script>
export default {
  name: "Body",
  props: ["data"],
  data() {
    return {};
  },

  computed: {
    conclusion() {
      if (this.data.tb_conclusion.tb_status == 1) {
        return "TB";
      } else if (this.data.tb_conclusion.tb_status == 2) {
        return "No TB";
      } else if (this.data.tb_conclusion.tb_status == 3) {
        return "MDRTB";
      } else {
        return "Waiting for TB Conclusion";
      }
    },

    tb_status() {
      var result = null;
      var value = parseInt(this.data.tb_conclusion.treatment_status);
      switch (value) {
        case 1:
          result = "Received TB Treatment";
          break;
        case 2:
          result = "Refer to other township";
          break;
        case 3:
          result = "Initial Loss";
          break;
        case 4:
          result = "Died before treatment";
          break;
      }
      return result;
    },
    tb_type() {
      var result = null;
      var value = parseInt(this.data.tb_conclusion.tb_type);
      switch (value) {
        case 1:
          result = "New";
          break;
        case 2:
          result = "Relapse";
          break;
        case 3:
          result = "Treatment after failure";
          break;
        case 4:
          result = "Treatment after loss to follow up";
          break;
        case 5:
          result = "Other previously treated";
          break;
        case 6:
          result = "Previous treatment history unknown";
          break;
      }
      return result;
    },
    tb_site() {
      var result = null;
      var value = parseInt(this.data.tb_conclusion.tb_site);
      switch (value) {
        case 1:
          result = "Pulmonary";
          break;
        case 2:
          result = "Extra Pulmonary";
          break;
      }
      return result;
    },
    treatment_reg() {
      var result = null;
      var value = parseInt(this.data.tb_conclusion.treatment_reg);
      switch (value) {
        case 1:
          result = "Initial Regimen";
          break;
        case 2:
          result = "Retreatment Regimen";
          break;
        case 3:
          result = "Childhood Regimen";
          break;
        case 4:
          result = "MDRTB Regimen";
          break;
      }
      return result;
    },
    treatment_outcome() {
      var result = null;
      var value = parseInt(this.data.tb_conclusion.treatment_outcome);
      switch (value) {
        case 1:
          result = "Cured";
          break;
        case 2:
          result = "Treatment complete";
          break;
        case 3:
          result = "Treatment failure";
          break;
        case 4:
          result = "Died";
          break;
        case 5:
          result = "Lost to follow up";
          break;
        case 6:
          result = "Not Evaluated";
          break;
        case 7:
          result = "Moved to second line treatment";
          break;
      }
      return result;
    },

    result() {
      var result = null;
      var value = parseInt(this.data.tb_conclusion.result);

      switch (value) {
        case 1:
          result = "Negative";
          break;
        case 2:
          result = "Positive";
          break;
        case 3:
          result = "Missing";
          break;
      }

      return result;
    },
    hivstatus() {
      if (this.data.tb_conclusion.hiv_status == 1) {
        return "Positive";
      } else if (this.data.tb_conclusion.hiv_status == 2) {
        return "Negative";
      } else if (this.data.tb_conclusion.hiv_status == 3) {
        return "Unknown";
      }
    },
    dmstatus() {
      if (this.data.tb_conclusion.dm_status == 1) {
        return "Yes";
      } else if (this.data.tb_conclusion.dm_status == 2) {
        return "No";
      } else if (this.data.tb_conclusion.dm_status == 3) {
        return "Unknown";
      }
      return "";
    },
    smokingstatus() {
      if (this.data.tb_conclusion.smoking_status == 1) {
        return "Current";
      } else if (this.data.tb_conclusion.smoking_status == 2) {
        return "Never";
      } else if (this.data.tb_conclusion.smoking_status == 3) {
        return "Past";
      } else if (this.data.tb_conclusion.smoking_status == 4) {
        return "Unknown";
      }

      return "";
    },
    hivcare() {
      if (this.data.tb_conclusion.hiv_care == 1) {
        return "IHC";
      } else if (this.data.tb_conclusion.hiv_care == 2) {
        return "NAP";
      } else if (this.data.tb_conclusion.hiv_care == 3) {
        return "Other";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
