import { sendData, getData, getExcel } from "./../../misc/server.js";
export default {
  state: {
    volunteers: [],
    sccs: [],
    data: [],
    loading: false,
    activity_headers: [],
    filterables: [],
    add_loading: false,
    activity_add_finish: false,
    activity_township: false,
  },
  mutations: {
    setData(state, payload) {
      state.data = payload;
    },
    setLoader(state, payload) {
      state.loading = payload;
    },
    setVolunteer(state, payload) {
      state.volunteers = payload;
    },
    setSCC(state, payload) {
      state.sccs = payload;
    },
    setaddLoader(state, payload) {
      state.add_loading = payload;
    },
    setaddFinish(state, payload) {
      state.activity_add_finish = payload;
    },
    setActivityHeader(state, payload) {
      state.activity_headers = payload;
    },
    setActivityTownship(state, payload) {
      state.activity_township = payload;
    },
    setFilterableColumn(state, payload) {
      state.filterables = payload;
    },
  },
  actions: {
    getActivities({ commit }) {
      var url = "/activities/get";

      commit("setLoader", true);
      getData(url, (response) => {
        commit("setLoader", false);

        commit("setData", response.activities);
      });
    },
    getNewActivity({ commit }, data) {
      var url = "/activities/get?id=" + data.id;
      url += "&";
      commit("setLoader", true);
      getData(url, (response) => {
        commit("setLoader", false);
        commit("setData", response.activities);
        commit("setVolunteer", response.volunteers);
        commit("setSCC", response.sccs);
      });
    },
    getEachActivity({ commit }, data) {
      var url = "/activities/each/get?";

      if (data.id) {
        url += "id=" + data.id;
      }

      if (data.year) {
        url += "&year=" + data.year;
      }

      if (data.month) {
        url += "&month=" + data.month;
      }

      url += "&";
      commit("setLoader", true);
      getData(url, (response) => {
        commit("setLoader", false);
        commit("setData", response.activities);
        commit("setActivityTownship", response.township);
        commit("setActivityHeader", response.activity_headers);
        commit("setFilterableColumn", response.filterables);
        commit("setVolunteer", response.volunteers);
        commit("setSCC", response.sccs);
      });
    },
    filterActivityData({ commit }, data) {
      var url = "/activities/each/filter";
      commit("setLoader", true);
      sendData(url, data, (response) => {
        commit("setLoader", false);
        commit("setData", response.activities);
      });
    },
    addActivity({ commit }, data) {
      var url = "/activities/add";
      commit("setaddFinish", false);
      commit("setaddLoader", true);
      sendData(url, data, (response) => {
        commit("setaddFinish", true);
        commit("setaddLoader", false);
      });
    },
    deleteActivity({ commit }, data) {
      var url = "/activities/delete?id=" + data.id;
      url += "&";
      commit("setaddFinish", false);
      commit("setaddLoader", true);
      getData(url, (response) => {
        console.log(response);

        commit("setaddFinish", true);
        commit("setaddLoader", false);
      });
    },
    getEditActivity({ commit }, data) {
      var url = "/activities/get/edit?id=" + data.id;
      url += "&";
      commit("setLoader", true);
      getData(url, (response) => {
        console.log(response);
        commit("setLoader", false);
        commit("setData", response.activities);
        commit("setVolunteer", response.volunteers);
        commit("setSCC", response.sccs);
      });
    },
    editActivity({ commit }, data) {
      var url = "/activities/edit";
      commit("setaddFinish", false);
      commit("setaddLoader", true);
      sendData(url, data, (response) => {
        commit("setaddLoader", false);
        commit("setaddFinish", true);
      });
    },
    exportActivityReportExcel({ commit }, data) {
      var url = "/activities/export?";

      if (data.id) {
        url += "id=" + data.id;
      }

      if (data.year) {
        url += "&year=" + data.year;
      }

      if (data.month) {
        url += "&month=" + data.month;
      }

      url += "&";

      getExcel(url);
    },
  },
  getters: {
    activity(state) {
      return state.data[0];
    },
    activities(state) {
      return state.data;
    },
    activities_loading(state) {
      return state.loading;
    },
    activity_volunteers(state) {
      return state.volunteers;
    },
    activity_sccs(state) {
      return state.sccs;
    },
    activity_add_loading(state) {
      return state.add_loading;
    },
    activity_add_finish(state) {
      return state.activity_add_finish;
    },
    activity_headers(state) {
      return state.activity_headers;
    },
    activity_values(state) {
      return state.data;
    },

    edit_activity(state) {
      return state.data;
    },
    edit_activity_headers(state) {
      var data = state.data.columns;
      return data;
    },
    edit_activity(state) {
      return state.data;
    },
    edit_activity_values(state) {
      var data = state.data.columns;
      var values = [];
      data.map((value) => {
        if (value.type == 7) {
          //values.push(value.pivot.multiple_values.option_data);
          var multiples = [];
          value.pivot.multiple_values.map((val) => {
            multiples.push(val.option_data);
          });

          values.push(multiples);
        } else {
          values.push(value.pivot.value);
        }
      });
      return values;
    },
    activity_township(state) {
      return state.activity_township;
    },
    filterables(state) {
      return state.filterables;
    },
  },
};
