<template>
  <div class="row">
    <div class="col m8 offset-m2">
      <div class="loading" v-if="loading">
        <pre-loader></pre-loader>
      </div>
      <section v-else>
        <h6>Sputum and Gene Xpert Info</h6>
        <div class="card card-content">
          <h6>Month 0</h6>
          <div class="input-field">
            <span>Sputum Date</span>
            <input
              type="text"
              v-model="data.patient.sputum.date"
              readonly="true"
            />
          </div>
          <div class="input-field">
            <span>Sputum Result</span>
            <select v-model="data.patient.sputum.result" disabled>
              <option value="" disabled selected>Choose your option</option>
              <option value="1">Negative</option>
              <option value="2">Positive</option>
              <option value="3">Missing</option>
            </select>
          </div>
          <div class="input-field">
            <span>Gene Xpert Date</span>
            <input
              type="text"
              v-model="data.patient.gene_xpert.date"
              readonly="true"
            />
          </div>
          <div class="input-field">
            <span>Gene Xpert Result</span>
            <select v-model="data.patient.gene_xpert.result" disabled>
              <option value="" disabled selected>Choose your option</option>
              <option value="1">N-MTB not detected</option>
              <option value="2">T-MTB detected</option>
              <option value="3">RR-Rif resistance</option>
              <option value="4">Tl-MTB detected, RR indeterminate</option>
              <option value="5">l-invalid result</option>
              <option value="6">Missing</option>
            </select>
          </div>
        </div>
        <div class="card card-content">
          <h6>Month 2/3</h6>
          <div class="input-field">
            <span>Sputum Date</span>
            <input
              type="text"
              v-model.lazy="data.patient.sputum.month2_date"
              class="datepicker"
            />
          </div>
          <div class="input-field">
            <span>Sputum Result</span>
            <select v-model="data.patient.sputum.month2_result">
              <option value="" disabled selected>Choose your option</option>
              <option value="1">Negative</option>
              <option value="2">Positive</option>
              <option value="3">Missing</option>
            </select>
          </div>
        </div>
        <div class="card card-content">
          <h6>Month 5</h6>
          <div class="input-field">
            <span>Sputum Date</span>
            <input
              type="text"
              v-model.lazy="data.patient.sputum.month5_date"
              class="datepicker"
            />
          </div>
          <div class="input-field">
            <span>Sputum Result</span>
            <select v-model="data.patient.sputum.month5_result">
              <option value="" disabled selected>Choose your option</option>
              <option value="1">Negative</option>
              <option value="2">Positive</option>
              <option value="3">Missing</option>
            </select>
          </div>
        </div>
        <div class="card card-content">
          <h6>Month End</h6>
          <div class="input-field">
            <span>Sputum Date</span>
            <input
              type="text"
              v-model.lazy="data.patient.sputum.month_end_date"
              class="datepicker"
            />
          </div>
          <div class="input-field">
            <span>Sputum Result</span>
            <select v-model="data.patient.sputum.month_end_result">
              <option value="" disabled selected>Choose your option</option>
              <option value="1">Negative</option>
              <option value="2">Positive</option>
              <option value="3">Missing</option>
            </select>
          </div>
        </div>
        <div class="input-field center">
          <button
            style="margin-left:20px;"
            :disabled="action_loading"
            type="button"
            class="btn blue darken-3"
            @click="submitData"
          >
            Submit
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BackContainer from "@/components/ReUse/BackContainer.vue";

export default {
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
    this.$store.dispatch("getDOTSPatientEditData", {
      id: this.$route.params.id,
    });
  },
  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  computed: {
    loading() {
      return this.$store.getters.dots_initial_data_loading;
    },
    data() {
      this.$store.dispatch(
        "getTownshipsByProject",
        this.$store.getters.dots_initial_data.project_id
      );
      return this.$store.getters.dots_initial_data;
    },
    action_loading() {
      return this.$store.getters.dots_action_loading;
    },
    finish() {
      return this.$store.getters.dots_action_finish;
    },
    delete_finish() {
      return this.$store.getters.dots_delete_finish;
    },
    townships() {
      return this.$store.getters.townships;
    },
  },
  methods: {
    submitData() {
      var data = {
        month2_date: this.data.patient.sputum.month2_date,
        month2_result: this.data.patient.sputum.month2_result,
        month5_date: this.data.patient.sputum.month5_date,
        month5_result: this.data.patient.sputum.month5_result,
        month_end_date: this.data.patient.sputum.month_end_date,
        month_end_result: this.data.patient.sputum.month_end_result,
        patient_id: this.data.patient.id,
      };
      this.$store.dispatch("submitSputumInfo", data);
    },
  },
  watch: {
    finish(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "Updated",
        });
      }
    },
    delete_finish(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "Deleted",
        });

        this.$router.push("/patient/dots/list");
      }
    },
  },
  components: {
    "back-container": BackContainer,
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 30px;
  padding: 20px;
  background: var(--card-background-color) !important;
}
</style>
