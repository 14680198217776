<template>
<div id="pagenotfound" class="center">
 

    <h1>Page Not Found</h1>
  
</div>
</template>

<script>

export default {
  name: 'PageNotFound',
  data () {
    return {
     
    }
  },
  components: {
      
  }
  

  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
   
   #pagenotfound
   {
       padding-top:10%;
       
   }
    
</style>
