<template>
 
       <fragment>             
                               
                                <th>Smeartest</th>
                                <th>Smeardate</th>
                                <th>Smearres</th>
                            
                              
        </fragment>                     
                    
                  
</template>

<script>



export default {
  name: 'Header',
  data () {
    return {
    }
  },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    
     
</style>
