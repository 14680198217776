<template>
  <div class="EditActivity">
    <div class="container">
      <div>
        <back-container
          :link="
            '/activities/' +
              activity.activity_id +
              '/' +
              activity.year +
              '/' +
              activity.month
          "
        ></back-container>
      </div>

      <div v-if="loading" class="center">
        <loading></loading>
      </div>
      <div v-else class="card z-depth-3">
        <div class="card-content">
          <div class="center">
            <h5>Edit {{ activity.activity.name }}</h5>
          </div>
          <div
            class="row"
            v-for="(item, index) in columns"
            v-bind:key="item.id"
          >
            <div class="col s12">
              <label class="black-text">{{ item.name }}</label>

              <input
                v-if="item.type == 1"
                type="number"
                v-model="values[index]"
              />
              <input
                v-if="item.type == 2"
                type="text"
                v-model="values[index]"
              />
              <section v-if="item.type == 3" class="volunteer_container">
                <select class="vounteer-box" v-model="values[index]">
                  <option value="" selected>Choose your option</option>
                  <option
                    v-for="item in volunteers"
                    v-bind:value="item.code"
                    v-bind:key="item.id"
                    >{{ item.code }}  ({{ item.name }})</option
                  >
                </select>
              </section>
              <input
                v-if="item.type == 4"
                type="text"
                v-model.lazy="values[index]"
                class="datepicker"
                readonly
              />
              <select v-if="item.type == 5" v-model="values[index]">
                <option value disabled selected>Choose your option</option>
                <option
                  v-for="opt in item.options"
                  v-bind:key="opt.id"
                  v-bind:value="opt.name"
                  >{{ opt.name }}</option
                >
              </select>
              <input
                v-if="item.type == 6"
                type="number"
                v-model="values[index]"
              />
              <div v-if="item.type == 7" class="row" style="padding-top:20px;">
                <p
                  v-for="op in item.options"
                  v-bind:key="op.id"
                  class="col m3"
                  style="margin-top:5px;"
                >
                  <label>
                    <input
                      type="checkbox"
                      name="multiple_option"
                      :value="op.id"
                      @change="checkBoxChange($event, index)"
                      class="mycheck_box"
                      :checked="checkIfChecked(op.id, index)"
                    />
                    <span>{{ op.name }}</span>
                  </label>
                </p>
              </div>
              <section v-if="item.type == 8" class="volunteer_container">
                <select class="vounteer-box" v-model="form.data_id[index]">
                  <option value disabled selected>Choose your option</option>
                  <option
                    v-for="item in sccs"
                    v-bind:value="item.name"
                    v-bind:key="item.id"
                    >{{ item.name }}</option
                  >
                </select>
              </section>
            </div>
          </div>
          <div class="row center" v-show="validate">
            <button
              :disabled="add_loading"
              @click="edit"
              class="btn blue darken-1"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Partials/Loading.vue";
import BackContainer from "@/components/ReUse/BackContainer.vue";
import M from "materialize-css";
export default {
  name: "EditActivity",
  data() {
    return {
      form: {
        data_id: [],
      },
    };
  },
  mounted() {
    this.$store.dispatch("getEditActivity", { id: this.$route.params.id });
    var elems1 = document.querySelectorAll("select");
    M.FormSelect.init(elems1, {});
    var elems2 = document.querySelectorAll(".datepicker");
    M.Datepicker.init(elems2, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  updated() {
    var elems1 = document.querySelectorAll("select");
    M.FormSelect.init(elems1, {});
    var elems2 = document.querySelectorAll(".datepicker");
    M.Datepicker.init(elems2, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  components: {
    loading: Loading,
    "back-container": BackContainer,
  },

  watch: {
    add_finish(val) {
      if (val) {
        location.reload();
      }
    },
  },

  computed: {
    columns() {
      return this.$store.getters.edit_activity_headers;
    },
    activity() {
      return this.$store.getters.edit_activity;
    },
    values() {
      this.form.data_id = this.$store.getters.edit_activity_values;

      return this.$store.getters.edit_activity_values;
    },
    volunteers() {
      return this.$store.getters.activity_volunteers;
    },
    sccs() {
      return this.$store.getters.activity_sccs;
    },
    loading() {
      return this.$store.getters.activities_loading;
    },
    validate() {
      var istrue = true;
      if (this.form.data_id.length == 0) {
        istrue = false;
      } else {
        for (var i = 0; i < this.activity.columns.length; i++) {
          if (!this.form.data_id[i] && this.activity.columns[i].nullable == 0) {
            istrue = false;
          }
        }
      }

      return istrue;
    },
    add_finish() {
      return this.$store.getters.activity_add_finish;
    },
    add_loading() {
      return this.$store.getters.activity_add_loading;
    },
  },
  methods: {
    edit() {
      var maindata = [];
      for (var i = 0; i < this.activity.columns.length; i++) {
        let data = {
          id: this.activity.columns[i].id,
          type: this.activity.columns[i].type,
        };

        if (this.form.data_id[i]) {
          data.value = this.form.data_id[i];
        } else {
          data.value = "null";
        }

        maindata.push(data);
      }

      var data = {
        id: this.activity.id,
        columns: JSON.stringify(maindata),
      };

      this.$store.dispatch("editActivity", data);
    },
    checkIfChecked(value, index) {
      var isExisted = false;
      this.form.data_id[index].map((val) => {
        if (val == value) {
          isExisted = true;
        }
      });

      return isExisted;
    },
    checkBoxChange(event, index) {
      if (event.target.checked) {
        if (this.form.data_id[index]) {
          this.form.data_id[index].push(event.target.value);
        } else {
          this.form.data_id[index] = [];
          this.form.data_id[index].push(event.target.value);
        }
      } else {
        this.form.data_id[index] = this.form.data_id[index].filter((val) => {
          return val != event.target.value;
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.EditActivity {
  padding-top: 40px;
}

.volunteer_container .dropdown-content {
  height: 200px !important;
}
</style>
