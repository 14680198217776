<template>
    <div class="container trash_list">
      <div>
        <br />
        <back-container link="/checkings/7/confirmed"></back-container>
      </div>
      <h5>TPT Trash</h5>
      <div v-if="patient_loading" class="row center" style="margin-top:20px;">
        <loading></loading>
      </div>
      <table v-else>
        <thead>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Phone</th>
            <th>TB Code</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in patients">
            <td>{{ index + 1 }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.phone }}</td>
            <!-- <td>{{ item.tb_conclusion.union_temporary_code }}</td> -->
            <td>
              <button
                :disabled="patient_add_loading"
                @click="recover(item.id)"
                class="btn blue darken-2"
              >
                Recover
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  <script>
  import Loading from "@/components/Partials/Loading.vue";
  import BackContainer from "@/components/ReUse/BackContainer.vue";
  export default {
    mounted() {
      this.$store.dispatch("getReferPatientTrash", {tptStatus: 1});
    },
    computed: {
      patients() {
        return this.$store.getters.patients;
      },
      patient_loading() {
        return this.$store.getters.patient_loading;
      },
      patient_add_loading() {
        return this.$store.getters.patient_add_loading;
      },
      patient_add_finish() {
        return this.$store.getters.patient_add_finish;
      },
    },
    methods: {
      recover(id) {
        this.$store.dispatch("recoverReferPatient", { id: id });
      },
    },
    components: {
      loading: Loading,
      "back-container": BackContainer,
    },
    watch: {
      patient_add_finish(val) {
        if (val) {
          location.reload();
        }
      },
    },
  };
  </script>
  <style scoped>
  .trash_list {
    padding-top: 30px;
  }
  </style>
  