<template>
        <span>{{ uniqueCode }}</span>
</template>

<script>

import moment from 'moment'

export default {
  name: 'PresumptiveCode',
  props:['data'],
  data () {
    return {
    }
  },
  methods: {
    
  },
  computed: {
        uniqueCode()
        {
            var code=this.data.presumptive_tb.presumptive_tb_number ;
            if (code)
            {
                if (code<10)
                {
                    code='000'+code;
                }

                else if (code<100)
                {
                    code='00'+code;
                }
                else if (code<1000)
                {
                    code='0'+code;
                }

                var year=""+moment(this.data.presumptive_tb.registeration_date).year()+"";
                year=year.trim();
                var township=this.data.township.short_name;
    
                code=township+"/"+code+"/"+year[2]+year[3];
            }
            
            return code;
        },
        user()
        {
            
        }
  }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


  

</style>
