<template>
    <div class="row">
      <div class="col m8 offset-m2">
        <section>
          <div v-show="data.is_editable">
            <back-container link="/patient/dots/list"></back-container>
          </div>
          <setting-bar v-show="data.is_editable"></setting-bar>
          <div class="card card-content">
            <h6>Presumptive Information</h6>
  
            <div class="input-field">
              <span>Name</span>
              <input type="text" v-model="data.name" readonly="true" />
            </div>
            <div class="input-field">
              <span>Phone</span>
              <input type="text" v-model="data.phone" readonly="true" />
            </div>
            <div class="input-field">
              <span>Age</span>
              <input type="number" v-model="data.age" readonly="true" />
            </div>
            <div class="input-field">
              <span>Gender</span>
              <select v-model="data.gender" disabled>
                <option value="1">Male</option>
                <option value="2">Female</option>
              </select>
            </div>
            <div class="input-field">
              <span>Address</span>
              <input type="text" v-model="data.address" readonly="true" />
            </div>
            <div class="input-field">
              <span>Weight</span>
              <input type="number" v-model="data.weight" />
            </div>
            <div class="input-field">
              <span>Volunteer</span>
              <select v-model="data.volunteer_id" disabled>
                <option
                  v-for="item in data.volunteers"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }} ({{ item.code }})
                </option>
              </select>
            </div>
  
            <div class="input-field">
              <span>Actual Treatment Start Date</span>
              <input type="text" v-model.lazy="data.actual_treatment_startdate" class="datepicker" />
            </div>
            <div class="input-field">
              <span>Treatment End Date</span>
              <input type="text" class="datepicker" v-model.lazy="data.treatment_enddate" readonly="true" />
            </div>
  
            <div class="input-field" v-if="data.is_new == 0">
              <span>Treatment Outcome</span>
              <select v-model.lazy="data.patient.tb_conclusion.treatment_outcome" disabled>
                <option value="1">Cured</option>
                <option value="2">Treatment complete</option>
                <option value="3">Treatment failure</option>
                <option value="4">Died</option>
                <option value="5">Lost to follow up</option>
                <option value="6">Not evaluated</option>
                <option value="7">Moved to second line treatment</option>
              </select>
            </div>
  
            <div class="input-field" v-if="data.is_new == 1">
              <span>Treatment Outcome</span>
              <select v-model.lazy="data.treatment_outcome_id">
                <option value="1">Cured</option>
                <option value="2">Treatment complete</option>
                <option value="3">Treatment failure</option>
                <option value="4">Died</option>
                <option value="5">Lost to follow up</option>
                <option value="6">Not evaluated</option>
                <option value="7">Moved to second line treatment</option>
              </select>
            </div>
  
            <div class="input-field" v-if="data.is_new == 0">
              <span>Outcome Date</span>
              <input type="text" v-model.lazy="data.patient.tb_conclusion.outcome_date" readonly="true" />
            </div>
            <div class="input-field" v-if="data.is_new == 1">
              <span>Outcome Date</span>
              <input type="text" class="datepicker" v-model.lazy="data.outcome_date" />
            </div>
            <div class="input-field">
              <span>DOTS Start Date</span>
              <input type="text" class="datepicker" v-model.lazy="data.dots_startdate" readonly="true" />
            </div>
  
            <div class="input-field" v-show="data.project_id">
              <span>Township</span>
              <select v-model="data.other_township_id">
                <option v-for="item in townships" :value="item.id" :key="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="input-field" v-show="data.type == 2">
              <span>Union TB Code</span>
              <input type="text" v-model="data.temporary_code" />
            </div>
  
            <div class="input-field" v-if="data.is_new == 1">
              <span>Township TB Code</span>
              <input type="text" v-model="data.tb_code" />
            </div>
            <div class="input-field" v-if="data.is_new == 1">
              <span>Type</span>
              <select v-model="data.type">
                <option
                  v-for="item in [{ id: 1, type: 'TPT' }, { id: 2, type: 'Other Township' }]"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.type }}
                </option>
              </select>
            </div>
  
            <div class="input-field">
              <span>Completed DOT supervision (Y/N)</span>
              <select v-model="data.finish" disabled>
                <option value="1">No</option>
                <option value="2">Yes</option>
              </select>
            </div>
            <div class="input-field">
              <span>DOTS End Date</span>
              <input type="text" class="datepicker" v-model.lazy="data.dots_enddate" disabled />
            </div>
  
            <div class="input-field">
              <span>Using DOTS APP</span>
              <select v-model="data.dots_app_status" disabled>
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>
            </div>
            <div class="input-field">
              <span>DOTS outcome verification form received</span>
              <select v-model="data.dots_outcome_verification_form">
                <option value="0">Yes</option>
                <option value="1">No</option>
              </select>
            </div>
            <div class="input-field">
              <span>Type of form received</span>
              <input type="text" v-model="data.dots_form_type" />
            </div>
            <div class="input-field">
              <span>Remark</span>
              <input type="text" v-model="data.remark" />
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  

<script>
import BackContainer from "@/components/ReUse/BackContainer.vue";
import SettingBar from "./SettingBar.vue";
export default {
    mounted() {
        var elems = document.querySelectorAll("select");
        var instances = M.FormSelect.init(elems, {});
        var elems = document.querySelectorAll(".datepicker");
        var instances = M.Datepicker.init(elems, {
            format: "yyyy-mm-dd",
            showClearBtn: true,
        });
        this.$store.dispatch("getDOTSPatientEditData", {
            id: this.$route.params.id,
        });
    },
    updated() {
        var elems = document.querySelectorAll("select");
        var instances = M.FormSelect.init(elems, {});

        var elems = document.querySelectorAll(".datepicker");
        var instances = M.Datepicker.init(elems, {
            format: "yyyy-mm-dd",
            showClearBtn: true,
        });
    },
    computed: {
        loading() {
            return this.$store.getters.dots_initial_data_loading;
        },
        data() {
            this.$store.dispatch(
                "getTownshipsByProject",
                this.$store.getters.dots_initial_data.project_id
            );
            return this.$store.getters.dots_initial_data;
        },
        action_loading() {
            return this.$store.getters.dots_action_loading;
        },
        finish() {
            return this.$store.getters.dots_action_finish;
        },
        delete_finish() {
            return this.$store.getters.dots_delete_finish;
        },
        townships() {
            return this.$store.getters.townships;
        },
    },
    methods: {
        getTownshipByProject() {
            this.$store.dispatch("getTownshipsByProject", this.data.project_id);
        },
    },
    watch: {
    },
    components: {
        "back-container": BackContainer,
        "setting-bar": SettingBar,
    },
};
</script>

<style lang="scss" scoped>
.card {
    margin-top: 30px;
    padding: 20px;
    background: var(--card-background-color) !important;
}
</style>