import { sendData, getData } from "./../../misc/server.js";
export default {
  state: {
    updateloading: false,
    updatefinish: false,
    personalInfoFinish: null,
    fill_finish: false,
    fill_data: null,
    delete_patient: false,
    delete_loading: false,
  },
  mutations: {
    setDeleteLoading(state, payload) {
      state.delete_loading = payload;
    },
    setLoader(state, payload) {
      if (payload) {
        state.fill_data = null;
        state.fill_finish = false;
      }
      state.updateloading = payload;
    },
    setPersonalInfoFinish(state, payload) {
      state.personalInfoFinish = payload;
    },
    setPatientDeleteFinish(state, payload) {
      state.delete_patient = payload;
    },
    setPatientFillFinish(state, payload) {
      state.fill_finish = true;
      state.fill_data = payload;
    },
  },
  actions: {
    submitPresumptiveInfo({ commit }, data) {
      var url = "/presumptive/save/info";
      commit("setPersonalInfoFinish", null);
      commit("setLoader", true);
      sendData(url, data, (response) => {
        commit("setLoader", false);
        if (response.success) {
          if (response.unique) {
            commit("setPersonalInfoFinish", false);
          } else {
            commit("setPatientFillFinish", 1);
            commit("setPersonalInfoFinish", true);
          }
        }
      });
    },

    submitChestXRay({ commit }, data) {
      var url = "/presumptive/save/checkxray";

      commit("setLoader", true);
      sendData(url, data, (response) => {
        if (response.success) commit("setPatientFillFinish", 2);

        commit("setLoader", false);
      });
    },
    submitSputum({ commit }, data) {
      var url = "/presumptive/save/sputum";

      commit("setLoader", true);
      sendData(url, data, (response) => {
        if (response.success) commit("setPatientFillFinish", 3);
        commit("setLoader", false);
      });
    },
    submitGeneExpert({ commit }, data) {
      var url = "/presumptive/save/genexpert";

      commit("setLoader", true);
      sendData(url, data, (response) => {
        if (response.success) commit("setPatientFillFinish", 4);
        commit("setLoader", false);
      });
    },
    submitTuberCulinSkin({ commit }, data) {
      var url = "/presumptive/save/tuberculinskin";
      commit("setLoader", true);
      sendData(url, data, (response) => {
        if (response.success) commit("setPatientFillFinish", 5);
        commit("setLoader", false);
      });
    },
    submitFNAC({ commit }, data) {
      var url = "/presumptive/save/fnac";
      commit("setLoader", true);
      sendData(url, data, (response) => {
        if (response.success) commit("setPatientFillFinish", 6);
        commit("setLoader", false);
      });
    },
    submitSputumCulture({ commit }, data) {
      var url = "/presumptive/save/sputumculture";
      commit("setLoader", true);
      sendData(url, data, (response) => {
        if (response.success) commit("setPatientFillFinish", 7);
        commit("setLoader", false);
      });
    },
    submitDrugSuscept({ commit }, data) {
      var url = "/presumptive/save/drugsuscept";
      commit("setLoader", true);
      sendData(url, data, (response) => {
        if (response.success) commit("setPatientFillFinish", 8);
        commit("setLoader", false);
      });
    },
    submitTBConclusion({ commit }, data) {
      var url = "/presumptive/save/tbconclusion";
      commit("setLoader", true);
      sendData(url, data, (response) => {
        if (response.success) {
          commit("setPatientFillFinish", 9);
          window.location.reload();
        }
        commit("setLoader", false);
      });
    },
    submitOtherInvestigation({ commit }, data) {
      var url = "/presumptive/save/other_invenstigation";
      commit("setLoader", true);
      sendData(url, data, (response) => {
        commit("setLoader", false);
      });
    },

    deletePatient({ commit }, data) {
      var url = "/presumptive/delete";
      commit("setDeleteLoading", true);
      commit("setPatientDeleteFinish", false);
      sendData(url, { id: data.id }, (data) => {
        commit("setDeleteLoading", false);
        if (data.success) {
          commit("setPatientDeleteFinish", true);
        }
      });
    },
  },
  getters: {
    updateloading(state) {
      return state.updateloading;
    },
    personalInfoFinish(state) {
      return state.personalInfoFinish;
    },
    patient_fill_finish(state) {
      return state.fill_finish;
    },
    patient_fill_data(state) {
      return state.fill_data;
    },
    delete_patient_finish(state) {
      return state.delete_patient;
    },
    delete_loading(state) {
      return state.delete_loading;
    },
  },
};
