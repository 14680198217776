import { sendData,getData } from './../../misc/server.js';
export default {
  state: {
    
    loading:false,
    user:null,
  },
  mutations: {
  
   makeloader(state,payload)
     {
      state.loading=payload;
     }
   ,
    setloginstate(state,payload)
     {
      state.user=payload;
     }
   
  },
   actions: {
   login({commit},data)
   {
         commit('makeloader',true);
          var url="/login";
          
        sendData(url,data,(response)=>{
            
            if (response.success)
            {
              
              localStorage.setItem("user",JSON.stringify(response));
            }
            commit('setloginstate',response);
            commit('makeloader',false);
            
        })
   },
   checkLogin({commit})
   {
     var user=JSON.parse(localStorage.getItem("user"));
     commit('setloginstate',user);
   },
   logout({commit})
   {
     localStorage.removeItem("user")
     commit('setloginstate',null);
   }


  },
  getters: {
   
   login_loading(state)
   {
     return state.loading;
   },
   auth(state)
   {
     return state.user;
   }
 
  }
}