<template>
  <div class="NewActivity">
    <div class="container">
      <back-container
        :link="
          '/activities/' +
          $route.params.id +
          '/' +
          $route.params.year +
          '/' +
          $route.params.month
        "
      ></back-container>
      <div v-if="loading" class="center">
        <loading></loading>
      </div>
      <div v-else class="card z-depth-3" style="margin-top: 10px">
        <div class="card-content">
          <div class="center">
            <h5>New {{ activity.name }}</h5>
          </div>
          <div class="row" v-for="(item, index) in activity.columns">
            <div class="col s12">
              <label>{{ item.name }}</label>

              <input
                v-if="item.type == 1"
                type="number"
                v-model="form.data_id[index]"
              />
              <input
                v-if="item.type == 2"
                type="text"
                v-model="form.data_id[index]"
              />
              <section v-if="item.type == 3" class="volunteer_container">
                <select v-model="form.data_id[index]">
                  <option value selected>Choose your option</option>
                  <option
                    v-for="item in volunteers"
                    v-bind:value="item.code"
                    :key="item"
                  >
                    {{ item.code }} ({{ item.name }})
                  </option>
                </select>
              </section>
              <input
                v-if="item.type == 4"
                type="text"
                v-model.lazy="form.data_id[index]"
                class="datepicker"
                @change="reInitializeDatePicker"
              />
              <v-select
                v-if="item.type == 5"
                :options="item.options"
                label="name"
                v-model.lazy="form.data_id[index]"
                :reduce="option => option.name"
              >
              </v-select>

              <input
                v-if="item.type == 6"
                type="number"
                v-model="form.data_id[index]"
              />
              <div v-if="item.type == 7" class="row" style="padding-top: 20px">
                <p
                  v-for="op in item.options"
                  :key="op.id"
                  class="col m3"
                  style="margin-top: 5px"
                >
                  <label>
                    <input
                      type="checkbox"
                      name="multiple_option"
                      :value="op.id"
                      @change="checkBoxChange($event, index)"
                      class="mycheck_box"
                    />
                    <span>{{ op.name }}</span>
                  </label>
                </p>
              </div>
              <section v-if="item.type == 8" class="volunteer_container">
                <select class="vounteer-box" v-model="form.data_id[index]">
                  <option value selected>Choose your option</option>
                  <option
                    v-for="item in sccs"
                    v-bind:value="item.name"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </section>
            </div>
          </div>
          <div class="row center" v-show="validate">
            <button
            
              @click="add"
              class="btn blue darken-1"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Partials/Loading.vue";
import BackContainer from "@/components/ReUse/BackContainer.vue";

export default {
  name: "NewActivity",
  data() {
    return {
      checked_data: [],
      form: {
        data_id: [],
      },
    };
  },
  mounted() {
    this.$store.dispatch("getNewActivity", { id: this.$route.params.id });
  },
  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  components: {
    loading: Loading,
    "back-container": BackContainer,
  },
  watch: {
    add_finish(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "New activity is inserted",
        });
        this.form.data_id = [];
        var elements = document.getElementsByClassName("mycheck_box");
        for (var i in elements) {
          elements[i].checked = false;
        }
      }
    },
  },
  computed: {
    activity() {
      return this.$store.getters.activity;
    },
    volunteers() {
      return this.$store.getters.activity_volunteers;
    },
    sccs() {
      return this.$store.getters.activity_sccs;
    },
    loading() {
      return this.$store.getters.activities_loading;
    },
    validate() {
      var istrue = true;
      if (this.form.data_id == 0) {
        istrue = false;
      } else {
        for (var i = 0; i < this.activity.columns.length; i++) {
          if (
            !this.form.data_id[i] &&
            this.activity.columns[i].nullable == 0 &&
            this.activity.columns[i].type != 7
          ) {
            istrue = false;
          }
        }
      }

      return istrue;
    },
    add_finish() {
      return this.$store.getters.activity_add_finish;
    },
    add_loading() {
      return this.$store.getters.activity_add_loading;
    },
  },
  methods: {
    reInitializeDatePicker() {
      var elems = document.querySelectorAll(".datepicker");
      var instances = M.Datepicker.init(elems, {
        format: "yyyy-mm-dd",
        showClearBtn: true,
      });
    },
    async add() {
      var myindex = 0;
      this.activity.columns.map((val, index) => {
        if (val.type == 7) {
          myindex = index;
        }
      });

      if (this.form.data_id[myindex] && this.form.data_id[myindex].length > 0) {
        var maindata = [];
        for (var i = 0; i < this.activity.columns.length; i++) {
          var data = {
            id: this.activity.columns[i].id,
            type: this.activity.columns[i].type,
          };

          if (this.form.data_id[i]) {
            data.value = this.form.data_id[i];
          } else {
            data.value = "null";
          }

          maindata.push(data);
        }

        var data = {
          activity_id: this.activity.id,
          year: this.$route.params.year,
          month: this.$route.params.month,
          columns: JSON.stringify(maindata),
        };

        await this.$store.dispatch("addActivity", data);
      } else {
        alert("You have to choose at least an option");
      }
    },
    checkBoxChange(event, index) {
      if (event.target.checked) {
        if (this.form.data_id[index]) {
          this.form.data_id[index].push(event.target.value);
        } else {
          this.form.data_id[index] = [];
          this.form.data_id[index].push(event.target.value);
        }
      } else {
        this.form.data_id[index] = this.form.data_id[index].filter((val) => {
          return val != event.target.value;
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.NewActivity {
  padding-top: 10px;
}

.volunteer_container .dropdown-content {
  min-height: 100px !important;
  max-height: 200px !important;
}
</style>
