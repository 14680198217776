export  function islogin(){

     var user=JSON.parse(localStorage.getItem("user"));
     if (user)
     {
        return user.success;

     }
     else 
     {
         return false;
     }
        
    
}