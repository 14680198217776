<template>
  <div class="Volunteer">
      <div class="container">
            <div>
                <back-container link="/volunteers"></back-container>
                <br/>
            </div>
            <div class="card z-depth-3">
                         
                        <div class="card-content">
                            <div class="center">
                            <h5>New Volunteer</h5>
                            </div>
                            <div class="row">
                                <div class=" col s12">
                                    <label >Name</label>
                                    <input type="text"  v-model="name"/>
                                    
                                </div>
                            </div>
                             <div class="row">
                                <div class=" col s12">
                                    <label >Age</label>
                                    <input type="number"  v-model="age"/>
                                    
                                </div>
                            </div>
                             <div class="row">
                                <div class="col s12 ">
                                    <label >Gender</label>
                                    <select v-model="gender">
                                        
                                        <option value="1">Male</option>
                                        <option value="2">Female</option>
                                    </select>
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <label >Village</label>
                                    <input type="text" class="validate"  v-model="village" />
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <label >Ward</label>
                                    <input type="text" class="validate"  v-model="ward" />
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <label >Phone</label>
                                    <input type="number" class="validate"  v-model="phone" />
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12 ">
                                    <label >Education Status</label>
                                    <select v-model="education_status">
                                        <option value="" disabled selected>Choose education status</option>
                                        <option v-bind:value=" item.id "  v-for="item in education_statuses">{{ item.name }}</option>
                                        
                                    </select>
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12 ">
                                    <label >Part History of TB</label>
                                    <select v-model="tb_history">
                                        
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                    </select>
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12 ">
                                    <label >Previous Volunteer Experience</label>
                                    <select v-model="experience">
                                        
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                    </select>
                                    
                                </div>
                            </div>

                            
                             <div class="row">
                                <div class="col s12">
                                    <label >Occupation</label>
                                     <select v-model="occupation" >
                                        <option value="" disabled selected>Choose Occupation</option>
                                        <option v-bind:value=" item.id "  v-for="item in occupations">{{ item.name }}</option>
                                        
                                    </select>
                                    
                                </div>
                            </div>
                            <div class="row" v-show="user.user.township.picts_only==2">
                                <div class="col s12 ">
                                    <label >PICTS Only</label>
                                    <select v-model="picts_only">
                                        
                                        <option value="1">No</option>
                                        <option value="2">Yes</option>
                                    </select>
                                    
                                </div>
                            </div>
                              <div class="row">
                                <div class="col s12 ">
                                    <label >Phone Supported</label>
                                    <select v-model="phone_provided">
                                        
                                        <option value="Not Supported">Not Supported</option>
                                        <option value="AIS">AIS</option>
                                        <option value="GF">GF</option>
                                    </select>
                                    
                                </div>
                            </div>
                              <div class="row" v-show="user.user.township.short_name==='TGI' || user.user.township.short_name==='LSO'">
                                <div class="col s12 ">
                                    <label >Township 1 or 2</label>
                                    <select v-model="seperated_side">
                                        <option value="1">Township 1</option>
                                        <option value="2">Township 2</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <label >Remark</label>
                                    <textarea class="materialize-textarea" v-model="remark"></textarea>
                                    
                                </div>
                            </div>
                             <div class="row" v-if="!loading && completeAll">
                                <div class="input-field col s12 center">
                                        <button  :disabled="addloader" class="btn waves-effect btn-large blue darken-3" @click="add">Add</button>
                                </div>
                            </div>


                        </div>
                    </div>
          </div>
      </div>
         
    </div>
</template>

<script>
export default {
  name: 'AddNew',
  data () {
    return {
      name:"",
      village:"",
      ward:"",
      phone:"",
      education_status:"",
       occupation:"",
      remark:"",
      tb_history:1,
      experience:1,
      age:"",
      gender:1,
      picts_only:1,
      phone_provided:0
    }
  },
  mounted()
  {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems,{});
        
        var elems = document.querySelectorAll('.datepicker');
        var instances = M.Datepicker.init(elems, {
            format:"yyyy-mm-dd",
            showClearBtn:true,
        });

        this.$store.dispatch("getBasicData");

  },
  updated()
  {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems,{});
  },
  watch: 
  {
    addFinish(newVal)
    {
        if (newVal)
        {
              this.name="";
                this.ward="";
                this.village="";
                this.phone="";
                this.education_status="";
              
                this.occupation="";
                this.remark="";
                 this.$notify({
                      group: 'noti',
                      type:"success",
                      text: 'A new volunteer is added successfully'
                 });
        }
    },
    error(newVal)
    {
         this.$notify({
                      group: 'noti',
                      type:"error",
                      text: newVal
                 });
    }
  },
  methods: {
        add()
        {
            var data={
                name:this.name,
                ward:this.ward,
                village:this.village,
                phone:this.phone,
                education_status_id:this.education_status,
                occupation_id:this.occupation,
                remark:this.remark,
                tb_history:this.tb_history,
                seperated_side:this.seperated_side,
                phone_provided:this.phone_provided,
                experience:this.experience,
                age:this.age,
                gender:this.gender,
                picts_only:this.picts_only
            };

            data.township_id=this.user.user.township["id"]
           
            this.$store.dispatch("addVolunteer",data);

          
            
        },
         isValidDate()
         {
                var temp = this.training_date.split('-');
                var d = new Date(temp[0] + '-' + temp[1] + '-' + temp[2]);
                return (d && (d.getMonth() + 1) == temp[1] && d.getDate() == Number(temp[2]) && d.getFullYear() == Number(temp[0]));
          }
  },
  computed: 
  {
      user()
      {
          return this.$store.getters.auth;
      },
      education_statuses()
      {
          return this.$store.getters.education_status;
      },
       training_types()
      {
          return this.$store.getters.training_type;
      },
      occupations()
      {
          return this.$store.getters.occupation;
      },
      completeAll()
      {
          return this.name && this.village && this.ward && this.phone && this.education_status
           && this.occupation && this.remark && this.age;
      },
      loading()
      {
          return this.$store.getters.loading;
      },
      user()
     {
        return this.$store.getters.auth;
      },
      addloader()
      {
          return this.$store.getters.addloader;
      },
      addFinish()
      {
          return this.$store.getters.addFinish;
      },
      error()
      {
          return this.$store.getters.volunteerError;
      },
     
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .Volunteer 
    {
        padding-top:20px;
    }

</style>
