<template>
  <transition name="modal" v-if="show">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h6>Date Filter</h6>
            <button @click="$emit('close')" class="btn blue darken-3">Close</button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col s6">
                <label >Referral Start Date</label>
                <input type="text" class="datepicker" v-model.lazy="startdate" readonly />
              </div>
              <div class="col s6">
                <label >Referral End Date</label>
                <input type="text" class="datepicker" v-model.lazy="enddate" readonly />
              </div>
            </div>
            <div class="row" v-if="type!='checking' ">
              <div class="col s6">
                <label >Register Start Date</label>
                <input type="text" class="datepicker" v-model.lazy="register_startdate" readonly />
              </div>
              <div class="col s6">
                <label >Register End Date</label>
                <input type="text" class="datepicker" v-model.lazy="register_enddate" readonly />
              </div>
            </div>
            <div class="row">
              <div class="col s6">
                <label >Gender</label>
                <select v-model="gender">
                  <option disabled selected>Select an option</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </select>
              </div>
              <div class="col s6">
                <label >Age</label>
                <select v-model="age">
                  <option disabled selected>Select an option</option>
                  <option value="1">Under 15</option>
                  <option value="2">Over 15</option>
                </select>
              </div>
            </div>
            <div class="row" v-if="type!='checking' " style="padding-bottom:10px;">
              <div class="col s6">
                <label >Presumptive</label>
                <input type="text" v-model="code" :disabled="this.presumptive_no" />
              </div>
              <div class="col s6">
                <label >Year</label>
                <input type="text" v-model="year" :disabled="this.presumptive_no" />
              </div>
            </div>
            <div class="row" v-if="type!='checking' ">
              <div class="col s6">
                <label >Volunteer</label>
                <select v-model="volunteer_id">
                  <option disabled selected>Select an option</option>
                  <option
                    v-for="item in volunteers"
                    v-bind:value="item.id"
                  >{{ item.name+" ("+item.code+")" }}</option>
                </select>
              </div>
              <div class="col s6">
                <label >Refer from</label>
                <select v-model="refer_from">
                  <option disabled selected>Select an option</option>
                  <option value="1">Volunteer</option>
                  <option value="2">GP</option>
                  <option value="3">Pharmacy</option>
                  <option value="4">Others</option>
                  <option value="5">Township</option>
                </select>
              </div>
            </div>
            <div class="row" v-if="type!='checking' ">
              <div class="col s6">
                <div class="row check-box-container">
                  <p>
                    <label>
                      <input type="checkbox" v-model="tb" />
                      <span>TB</span>
                    </label>
                  </p>
                  <p>
                    <label>
                      <input type="checkbox" v-model="presumptive_no" />
                      <span>No Presumptive Number</span>
                    </label>
                  </p>
                </div>
              </div>
            </div>
            <div class="row center" v-if="buttonpass">
              <button class="btn blue darken-3" @click="search">Search</button>
            </div>
          </div>

          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import moment from "moment";
export default {
  name: "PatientFilterBox",
  props: ["show", "type"],
  data() {
    return {
      startdate: "",
      enddate: "",
      register_enddate: "",
      register_startdate: "",
      gender: 0,
      age: 0,
      code: "",
      year: "",
      refer_from: null,
      tb: false,
      volunteer_id: null,
      presumptive_no: false
    };
  },
  mounted() {
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd"
    });
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
  },
  updated() {
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd"
    });
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
  },
  watch: {},

  computed: {
    buttonpass() {
      if (
        (this.register_startdate && this.register_enddate) ||
        (this.startdate && this.enddate) ||
        (this.code && this.year) ||
        this.gender ||
        this.age ||
        this.tb ||
        this.presumptive_no ||
        this.volunteer_id || this.refer_from
      ) {
        if (
          (this.startdate && !this.enddate) ||
          (!this.startdate && this.enddate)
        ) {
          return false;
        }
        if (
          (this.register_startdate && !this.register_enddate) ||
          (!this.register_startdate && this.register_enddate)
        ) {
          return false;
        } else if ((this.code && !this.year) || (!this.code && this.year)) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    volunteers() {
      return this.$store.getters.filter_volunteers;
    }
  },

  methods: {
    search() {
      var componentName = "";
      if (this.type == "checking") {
        componentName = "checkings";
      } else if (this.type == "patient") {
        componentName = "patients";
      }

      var params = {};

      if (this.startdate && this.enddate) {
        params.startdate = this.startdate;
        params.enddate = this.enddate;
      }

      if (this.register_startdate && this.register_enddate) {
        params.register_startdate = this.register_startdate;
        params.register_enddate = this.register_enddate;
      }

      if (this.code && this.year) {
        params.code = this.code;
        params.year = this.year;
      }

      if (this.gender) {
        params.gender = this.gender;
      }

      if (this.volunteer_id) {
        params.volunteer_id = this.volunteer_id;
      }

      if (this.age) {
        params.age = this.age;
      }

      if (this.tb) {
        params.tb = this.tb;
      }

      if (this.presumptive_no) {
        params.nopresumptive = this.presumptive_no;
      }

      if (this.refer_from)
      {
        params.refer_from=this.refer_from;
      }

      this.$router.push({ path: componentName, query: params });
      this.$emit("close");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 70%;
  margin: 0px auto;
  height: 600px;
  overflow-y: auto;
  padding: 20px 30px;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: "Zawgyi-One" !important;
  background-color:var(--app-modal-background);
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-header {
  border-bottom: 0.2px solid black;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-container {
  width: 70%;
  height: 750px;
}

.check-box-container {
  display: flex;
}

.check-box-container p {
  margin-right: 40px;
}
</style>
