import { sendData,getData,getExcel } from './../../misc/server.js';

export default {
  state: {

    data:null,
    loading:false,
    reported_volunteers:[],
  
  },
  mutations: {
  
    setData(state,payload)
    {
      state.data=payload;
    },
    setLoading(state,payload)
    {
      state.loading=payload;
    },
    setReportedVolunteer(state,data)
    {
      state.reported_volunteers=data;
    }

   
  },
   actions: {
    
    generatePAReport({commit},data)
    {
        var url="/report";

        url+="?month="+data.month+"&year="+data.year;

        url+="&";
        
      
        commit("setLoading",true);
        getData(url,(response)=>{
            
          commit("setLoading",false);
            commit("setData",response);
             
        })
    },
    getReportedVolunteer({commit},data)
    {
      var url="/report/reported_volunteer";

      url+="?month="+data.month+"&year="+data.year;

      url+="&";
      
    
      commit("setLoading",true);
      getData(url,(response)=>{
        
        commit("setLoading",false);
        commit("setReportedVolunteer",response);
           
      })
    },  
    exportPAReport({commit},data)
    {
      var url="/report/export";
      url+="?month="+data.month+"&year="+data.year;
      url+="&";
     
      getExcel(url);
    },
    exportReportedVolunteer({commit},data)
    {
      var url="/volunteer/report/export";
      url+="?month="+data.month+"&year="+data.year;
      url+="&";
     getExcel(url);
     
    }
  
  },
  getters: {
   
    pa_report(state)
    {
      return state.data;
    },
    pa_report_loading(state)
    {
      return state.loading;
    },
    reported_volunteers(state)
    {
      return state.reported_volunteers;
    }
 
  }
}