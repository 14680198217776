<template>
    <div>
        <div class="row center">
            <h5>Reported Volunteer for {{$route.params.month}}/{{$route.params.year}}</h5>
            
        </div>

        <div class="row">
            <div class="col m6 offset-m3">
                <div class="progress" v-if="loading">
                    <div class="indeterminate"></div>
                </div>
                <div v-else>
                <div class="center" style="padding-bottom:20px;">
                    <button @click="exportReportedVolunter" class="btn green darken-3">Export as Excel</button>
                </div>
                <table class="app-table">
                    <thead>
                    <tr>
                        <th>No</th><th>Code</th><th>Name</th><th></th>
                    </tr>
                    </thead>
                    <tr v-for="(item,index) in reported_volunteers">
                        <td>{{ index+1 }}</td>
                        <td>{{ item.code }}</td>
                        <td>{{ item.name }}</td>
                        <td>
                            <router-link :to="'/volunteer/list?id='+item.id" class="btn blue darken-2">View</router-link>
                        </td>
                    </tr>
                
                </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted()
    {
        
       this.$store.dispatch("getReportedVolunteer",{month:this.$route.params.month,year:this.$route.params.year});
    },
    methods: 
    {
        exportReportedVolunter()
        {
            this.$store.dispatch("exportReportedVolunteer",{month:this.$route.params.month,year:this.$route.params.year});
        }
    },
    computed: 
    {
        reported_volunteers()
        {
            return this.$store.getters.reported_volunteers;
        },
        loading()
        {
            return this.$store.getters.pa_report_loading;
        }
    }
}
</script>
<style>
    
</style>