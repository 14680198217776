<template>
  <div class="container">
    <div class="row">
      <back-container
        :link="'/dots/visitdate/' + $route.params.id"
      ></back-container>
    </div>
    <div class="row">
      <div class="col m3">
        <span>Start Date</span>
        <input type="text" v-model.lazy="start_date" class="datepicker" />
      </div>
      <div class="col m3">
        <span>Month</span>
        <select v-model="week">
          <option value>Choose an option</option>
          <option value="1">1 week</option>
          <option value="2">2 week</option>
          <option value="4">1 month</option>
          <option value="8">2 months</option>
          <option value="12">3 months</option>
          <option value="16">4 months</option>
          <option value="20">5 months</option>
        </select>
      </div>
      <div class="col m3">
        <span>Phase</span>
        <select v-model="phase">
          <option value selected>Choose an option</option>
          <option value="1">Initial Phase</option>
          <option value="2">Continours Phase</option>
          <option value="3">TPT</option>
        </select>
      </div>
      <div class="col m3 center" v-show="start_date && week && phase">
        <br />
        <br />
        <button
          :disabled="dots_checking_loading"
          @click="generateDate"
          class="btn blue darken-3"
        >
          Generate
        </button>
        &nbsp&nbsp&nbsp
        <button
          :disabled="dots_checking_loading"
          @click="clearDate"
          class="btn blue darken-3"
        >
          Clear
        </button>
      </div>
    </div>
    <div class="row" v-if="dates.length > 0 && valid && !dots_checking_loading">
      <div class="card">
        <div class="card-title">
          <p class="blue-text" v-if="phase == 1">**** two days a week ****</p>
          <p class="blue-text" v-if="phase == 2">
            **** one day in two weeks ****
          </p>
        </div>
        <div class="card-contetn">
          <table class="app-table">
            <tr v-for="(item, index) in dates">
              <td class="header_column">
                <strong>Week {{ index + 1 }}</strong>
              </td>
              <td v-for="day in item">
                <p
                  @click="changeDate(day, index)"
                  v-bind:class="{ choosendate: day.choosen }"
                >
                  {{ day.date }}
                  <span class="remove_btn" v-show="day.choosen">X</span>
                </p>
              </td>
            </tr>
          </table>
        </div>
        <div class="card-footer center">
          <br />
          <button
            :disabled="action_loading"
            @click="btnSave"
            class="btn blue darken-3"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackContainer from "@/components/ReUse/BackContainer.vue";
import * as DateManagment from "@/misc/visit_date_service.js";
export default {
  data() {
    return {
      start_date: null,
      week: "",
      dates: [],
      phase: "",
      valid: true,
    };
  },
  mounted() {
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    this.$store.dispatch("getDOTSVisitDateInitDate", {
      id: $route.params.id,
    });
  },
  methods: {
    generateDate() {
      this.$store
        .dispatch("checkDOTSVisitDate", {
          patient_id: this.$route.params.id,
          date: this.start_date,
        })
        .then(() => {
          this.dates = DateManagment.generateDate(
            this.start_date,
            this.week,
            this.phase
          );
        });
    },
    clearDate() {
      this.dates = [];
    },
    changeDate(day, week_id) {
      if (day.choosen == false) {
        if (
          this.phase == 1 &&
          DateManagment.getChoosenDate(week_id).length < 2
        ) {
          day.choosen = true;
        } else if (this.phase == 2) {
          var length_1 = DateManagment.getChoosenDate(week_id).length;
          var length_2 = null;

          if (week_id % 2 == 0) {
            length_2 = DateManagment.getChoosenDate(week_id + 1).length;
          } else {
            length_2 = DateManagment.getChoosenDate(week_id - 1).length;
          }

          if (length_1 + length_2 == 0) {
            day.choosen = true;
          } else {
            alert("Please clear the selected date");
          }
        } else if (this.phase == 3) {
          day.choosen = true;
        } else {
          alert("Please clear the selected date");
        }
      } else {
        day.choosen = false;
      }
    },
    btnSave() {
      var dates = [];
      for (var i in this.dates) {
        for (var b in this.dates[i]) {
          if (this.dates[i][b].choosen == true) {
            dates.push(this.dates[i][b].date);
          }
        }
      }

      if (
        (this.phase == 1 && dates.length == this.dates.length * 2) ||
        (this.phase == 2 && dates.length == this.dates.length / 2) ||
        (this.phase == 3 && dates.length > 0)
      ) {
        this.$store.dispatch("saveDOTSVisitDate", {
          dates: dates,
          patient_id: this.$route.params.id,
        });
      } else {
        alert("can't generate dates");
      }
    },
  },
  computed: {
    action_loading() {
      return this.$store.getters.dots_visitdates_action_loading;
    },
    action_finish() {
      return this.$store.getters.dots_visitdates_action_finish;
    },
    dots_checking_loading() {
      return this.$store.getters.dots_checking_loading;
    },
    dots_generate_checking_error() {
      return this.$store.getters.dots_generate_checking_error;
    },
  },
  watch: {
    action_finish(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "Created",
        });

        this.$router.push("/dots/visitdate/" + this.$route.params.id);
      }
    },
    dots_generate_checking_error(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "error",
          text:
            "Start Date should be larger than the last visit date or actual treament startdate of this patient",
        });
        this.valid = false;
      } else {
        this.valid = true;
      }
    },
  },
  components: {
    "back-container": BackContainer,
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 20px;
}

table {
  margin-top: 30px;
}

td {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100px;
  position: relative;
  border: 1px solid var(--general-background-color);
  text-align: center;
}

.choosendate {
  border: 2px solid blue;
  padding: 10px;
  border-radius: 50%;

  width: 130px;
}

.remove_btn {
  position: absolute;
  right: 20px;
  top: 5px;
  cursor: pointer;
}

.card-title {
  text-align: center;
}

.card-title p {
  font-size: 18px;
}
</style>
