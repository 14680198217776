import { sendData, getData, getExcel } from "./../../misc/server.js";

export default {
  state: {
    initial_data: null,
    loading: false,
    action_loading: false,
    action_finish: false,
    data: null,
    dots_page: 1,
    data_count: null,
    delete_finish: false,
    dots_visit_reports: [],
    volunteer_changes: [],
  },
  mutations: {
    setInitialData(state, payload) {
      state.initial_data = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setActionLoading(state, payload) {
      state.action_loading = payload;
    },
    setActionFinish(state, payload) {
      state.action_finish = payload;
    },
    setDeleteFinsih(state, payload) {
      state.delete_finish = payload;
    },
    setDOTSPatientTrashData(state, payload) {
      state.data = payload;
    },
    setDOTSPatientData(state, payload) {
      state.data = payload.data;
      state.dots_page = payload.page;
    },
    setDOTSPatientDataCount(state, payload) {
      state.data_count = payload;
    },
    setDOTSVisitReport(state, payload) {
      state.dots_visit_reports = payload;
    },
    appendDOTSVisitReport(state, payload) {
      state.dots_visit_reports = state.dots_visit_reports.filter((val) => {
        return val.id != payload.id;
      });
      state.dots_visit_reports.push(payload);
    },
    setVolunteerChangeActivity(state, payload) {
      state.volunteer_changes = payload;
    },
  },
  actions: {
    moveToDOTSPateint({ commit }, id) {
      var url = "/dots/patient/move";
      sendData(url, { id: id }, (data) => {
        console.log(data);
      });
    },
    getDOTSPatientInitData({ commit }, data) {
      var url = "/dots/patient/getInitialData?id=" + data.id + "&";
      commit("setLoading", true);
      getData(url, (data) => {
        commit("setInitialData", data);
        commit("setLoading", false);
      });
    },
    getDOTSPatientAddInitData({ commit }) {
      var url = "/dots/patient/getAdInitialData";
      commit("setLoading", true);
      getData(url, (data) => {
        commit("setInitialData", data);
        commit("setLoading", false);
      });
    },
    getDOTSPatientEditData({ commit }, data) {
      var url = "/dots/patient/getEditData?id=" + data.id + "&";
      // commit("setLoading", true);
      getData(url, (data) => {
        commit("setInitialData", data);
        // commit("setLoading", false);
      });
    },
    add_dots_patient({ commit }, payload) {
      var url = "/dots/patient/add";
      commit("setActionLoading", true);
      sendData(url, payload, (data) => {
        commit("setActionLoading", false);
        if (data.success) {
          commit("setActionFinish", true);
        }
      });
    },
    getDOTSPatient({ commit }, payload) {
      var url = "/dots/patient/get?";
      if (payload.page != null) {
        url += "&page=" + payload.page;
      }

      if (payload.filter) {
        url += "&filter=" + payload.filter;
      }
      if (payload.month && payload.year) {
        url += "&month=" + payload.month + "&year=" + payload.year;
      }

      if (payload.startDate && payload.endDate) {
        url +=
          "&startDate=" + payload.startDate + "&endDate=" + payload.endDate;
      }

      if (payload.name) {
        url += "&name=" + payload.name;
      }

      if (payload.volunteer_id) {
        url += "&volunteer_id=" + payload.volunteer_id;
      }

      url += "&";

      commit("setLoading", true);
      getData(url, (response) => {
        commit("setLoading", false);
        commit("setDOTSPatientDataCount", response.patient_count);
        commit("setDOTSPatientData", {
          data: response.patients,
          page: response.page,
        });
      });
    },
    editDOTSPatient({ commit }, payload) {
      var url = "/dots/patient/edit";
      commit("setActionFinish", false);
      commit("setActionLoading", true);
      sendData(url, payload, (data) => {
        commit("setActionLoading", false);
        if (data.success) {
          commit("setActionFinish", true);
        }
      });
    },
    updateDotsPatientStatus({ commit }, payload) {
      var url = "/dots/patient/updateFinishStatus";

      commit("setActionLoading", true);
      sendData(url, payload, (data) => {
        commit("setActionLoading", false);
        if (data.success) {
          location.reload();
        }
      });
    },
    deleteDOTSPatient({ commit }, id) {
      var url = "/dots/patient/delete";
      commit("setDeleteFinsih", false);
      commit("setActionLoading", true);
      sendData(url, { id: id }, (data) => {
        commit("setActionLoading", false);
        if (data.success) {
          commit("setDeleteFinsih", true);
        }
      });
    },
    getDOTSPatientTrash({ commit }) {
      var url = "/dots/patient/trash/get";

      commit("setLoading", true);
      getData(url, (response) => {
        commit("setLoading", false);
        commit("setDOTSPatientTrashData", response.data);
      });
    },
    recoverDotsPatient({ commit }, payload) {
      var url = "/dots/patient/recover";
      commit("setActionFinish", false);
      commit("setActionLoading", true);
      sendData(url, { id: payload.id }, (data) => {
        commit("setActionLoading", false);
        if (data.success) {
          commit("setActionFinish", true);
        }
      });
    },
    saveDOTSVisitReport({ commit }, payload) {
      var url = "/dots/visitdate/report";
      commit("setActionLoading", true);
      sendData(url, payload, (data) => {
        location.reload();
        commit("setActionLoading", false);
      });
    },
    getDOTSVisitReport({ commit }, payload) {
      var url = `/dots/visitdate/report/get?dots_patient_id=${payload.id}`;
      commit("setLoading", true);
      getData(url, (response) => {
        commit("setLoading", false);
        commit("setDOTSVisitReport", response.data);
      });
    },
    changeDOTSVolunteer({ commit }, payload) {
      var url = `/dots/volunteer/change`;
      commit("setActionLoading", true);
      commit("setActionFinish", false);
      sendData(url, payload, (response) => {
        commit("setActionLoading", false);
        commit("setActionFinish", true);
      });
    },
    getVolunteerChangeActivity({ commit }, payload) {
      var url = `/dots/volunteer/change/activity?dots_patient_id=${payload.dots_patient_id}`;
      getData(url, (response) => {
        commit("setVolunteerChangeActivity", response.data);
      });
    },
    recoverDOTSEnd({ commit }, payload) {
      var url = `/dots/patient/recoverEnd?dots_patient_id=${payload.id}`;
      commit("setLoading", true);
      getData(url, (response) => {
        commit("setLoading", false);
        if (response.success) {
          location.reload();
        }
      });
    },
    dotsExcelExport({}, payload) {
      var url = "/dots/patient/export?";

      if (payload.page != null) {
        url += "&page=" + payload.page;
      }

      if (payload.filter) {
        url += "&filter=" + payload.filter;
      }
      if (payload.month && payload.year) {
        url += "&month=" + payload.month + "&year=" + payload.year;
      }

      if (payload.name) {
        url += "&name=" + payload.name;
      }

      if (payload.volunteer_id) {
        url += "&volunteer_id=" + payload.volunteer_id;
      }

      url += "&";
      getExcel(url);
    },
  },
  getters: {
    dots_initial_data_loading(state) {
      return state.loading;
    },
    dots_initial_data(state) {
      return state.initial_data;
    },
    dots_action_finish(state) {
      return state.action_finish;
    },
    dots_action_loading(state) {
      return state.action_loading;
    },
    dots_data(state) {
      return state.data;
    },
    dots_page(state) {
      return state.dots_page;
    },
    dots_data_count(state) {
      return state.data_count;
    },
    dots_delete_finish(state) {
      return state.delete_finish;
    },
    dots_visit_reports(state) {
      return state.dots_visit_reports;
    },
    dots_volunteer_changes(state) {
      return state.volunteer_changes;
    },
  },
};
