<template>
<div class="card card-content">
    <h6>Activity</h6>
    <table class="app-table">
        <thead>
            <tr>
                <th>No</th>

                <th>From :Volunteer</th>
                <th>App</th>

                <th>To :Volunteer</th>
                <th>App</th>
                <th>Date</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item,index) in activity" v-bind:key="item.id">
                <td>{{ index+1 }}</td>

                <td>{{ item.from_volunteer.code }} ( {{ item.from_volunteer.name }} )</td>
                <td>{{ item.from_dots_app_status==1?"Yes":"No" }}</td>
                <td>{{ item.to_volunteer.code }} ( {{ item.to_volunteer.name }} )</td>
                <td>{{ item.to_dots_app_status==1?"Yes":"No" }}</td>
                <td>{{ item.date }}</td>

            </tr>
        </tbody>
    </table>
</div>
</template>

<script>
export default {
    mounted() {
        this.$store.dispatch("getVolunteerChangeActivity", {
            dots_patient_id: this.$route.params.id
        });
    },
    computed: {
        activity() {
            return this.$store.getters.dots_volunteer_changes;
        }
    }
};
</script>

<style>
</style>
