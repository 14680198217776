<template>
  <div class="container transfer_checking_list">
        <div style="margin-bottom:10px;">
          <back-container link="/checkingbox"></back-container>
        </div>
        <div class="row center">
                             <div class="card card-content "  style="max-width:100%;overflow-x: auto;">
               
                    <table class="app-table">
                            <thead>
                            <tr>
                                <th>No</th><th>Name</th><th>Phone</th>
                                <th>Transfer From</th>
                                <th>Actions</th>
                                
                            </tr>
                            </thead>
                            
                            <tbody>
                                
                                 <tr v-for="(item,index) in transferedData">
                                  <td>{{ index+1 }}</td> 
                                  <td>{{ item.patient.name }}</td>
                                   <td>{{ item.patient.phone }}</td>
                                    <td>{{ item.project_associate.township.name }}</td>
                                  
                                
                                  <td>
                                    <button class="btn blue darken-3"
                                            :disabled="confirmLoading"
                                            @click="transferConfirm(item.id)"
                                            v-if="item.confirmed==0"
                                      >Confirm</button>
                                      <p v-else>Confirmed</p>
                                    </td>
                                 </tr>
                            </tbody>
                            
                    </table>
                    <div class="center" v-if="transferLoading">
                                  <loading ></loading>
                      </div>
                   
                     
              
                  
             </div>
    </div>
    </div>
</template>

<script>

import Loading from '@/components/Partials/Loading.vue';
import BackContainer from '@/components/ReUse/BackContainer.vue';

export default {
  name: 'TransferChecking',
  data () {
    return {

    }
  },
  mounted()
  {
      
   
     this.getData();
  },
  methods: {
    
    getData(load=false)
    {
         this.$store.dispatch("getTransferedChecking",{});
    },
    transferConfirm(id)
    {
        this.$store.dispatch("confirmTransfer",{id:id});
    }

  
  },
  components: {
    "loading":Loading,
    "back-container":BackContainer,
    
  },
  computed:
  {
      transferedData()
      {
        return this.$store.getters.transferedData;
      },
      transferLoading()
      {
        return this.$store.getters.transferLoading;
      },
      confirmLoading()
      {
        return this.$store.getters.confirmLoading;
      },
      confirmFinish()
      {
        return this.$store.getters.confirmFinish;
      }
  },

  watch: 
  {
    confirmFinish(val)
    {
      if (val)
      {
        location.reload();
      }
    }
  }
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .transfer_checking_list 
  {
      padding-left:20px;
      padding-right:20px;
      padding-top:30px;
  }

  .transfer_checking_list .card-content 
  {
      padding:10px;
      background:var(--card-background-color);
  }
</style>
