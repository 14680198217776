<template>
  <div class="patient_list">
    <patient-filter @sort="sortData" v-bind:type="sort"></patient-filter>
    <div class="row card card-content" style="padding:0px;margin:0px;">
      <div class="col m3">
        <p>Patients</p>
      </div>
      <div class="col m3">
        <p>Total Count : {{ patient_count }}</p>
      </div>
      <div class="col m4">
        <p>Filter by : {{ filtertype }}</p>
      </div>
      <div class="col m2" style="padding-top:5px;">
        <back-container link="/checkingbox"></back-container>
      </div>
    </div>
    <section style="margin-top:0px;padding-top:0px;">
      <div class="fixed-table-container" style="margin-top:0px;padding-top:0px;">
        <table class="app-table patient_display">
          <thead>
            <table-header></table-header>
          </thead>
          <tbody>
            <table-body v-bind:data="patients"></table-body>
          </tbody>
        </table>
      </div>
      <div v-if="patient_loading" class="row center" style="margin-top:20px;">
        <loading></loading>
      </div>
      <div
        class="row center"
        style="margin-top:20px;"
        v-if="!patient_loading && (patients.length<patient_count)"
      >
        <button class="btn blue darken-2" @click="loadMore">Load More</button>
      </div>
    </section>
  </div>
</template>

<script>
import Loading from "@/components/Partials/Loading.vue";
import TableHeader from "@/components/Patient/TableHeader.vue";
import TableBody from "@/components/Patient/TableBody.vue";
import PatientFilter from "@/components/Patient/PatientFilter.vue";
import { getFilterType } from "./../../misc/filtertype.js";
import BackContainer from "@/components/ReUse/BackContainer.vue";

export default {
  name: "Patient",
  data() {
    return {
      search: "",
      datefilterShow: false,
      sort: 2
    };
  },

  mounted() {
    this.$store.dispatch("clearPatientData");
    this.getData();
  },
  watch: {
    $route(to, from) {
      this.getData();
    }
  },
  methods: {
    loadMore() {
      this.getData(true);
    },

    sortData() {
      if (this.sort == 1) {
        this.sort = 2;
      } else if (this.sort == 2) {
        this.sort = 1;
      }

      this.$store.dispatch("clearPatientData");
      this.getData(false);
    },

    getData(load = false) {
      var data = {
        lastid: 0,
        load: load
      };

      if (this.patients.length > 0) {
        data.lastid = this.patients.length;
      }

      if (!data.load) {
        data.lastid = 0;
      }

      if (this.$route.params.search) {
        data.search = this.$route.params.search;
      }

      if (
        (this.$route.query.register_startdate &&
          this.$route.query.register_enddate) ||
        (this.$route.query.startdate && this.$route.query.enddate) ||
        (this.$route.query.code && this.$route.query.year) ||
        this.$route.query.gender ||
        this.$route.query.age ||
        this.$route.query.tb ||
        this.$route.query.nopresumptive ||
        this.$route.query.volunteer_id ||
        this.$route.query.refer_from
      ) {
        if (this.$route.query.startdate && this.$route.query.enddate) {
          data.startdate = this.$route.query.startdate;
          data.enddate = this.$route.query.enddate;
        }

        if (
          this.$route.query.register_startdate &&
          this.$route.query.register_enddate
        ) {
          data.register_startdate = this.$route.query.register_startdate;
          data.register_enddate = this.$route.query.register_enddate;
        }

        if (this.$route.query.code && this.$route.query.year) {
          data.code = this.$route.query.code;
          data.year = this.$route.query.year;
        }

        if (this.$route.query.age) {
          data.age = this.$route.query.age;
        }

        if (this.$route.query.gender) {
          data.gender = this.$route.query.gender;
        }

        if (this.$route.query.tb) {
          data.tb = this.$route.query.tb;
        }

        if (this.$route.query.nopresumptive) {
          data.nopresumptive = this.$route.query.nopresumptive;
        }

        if (this.$route.query.volunteer_id) {
          data.volunteer_id = this.$route.query.volunteer_id;
        }

        if (this.$route.query.refer_from) {
          data.refer_from = this.$route.query.refer_from;
        }

        this.datefilterShow = false;
      }

      data.sort = this.sort;

      data.status = 3;

      this.$store.dispatch("getPatients", data);
    }
  },
  components: {
    loading: Loading,
    "table-header": TableHeader,
    "table-body": TableBody,
    "patient-filter": PatientFilter,
    "back-container": BackContainer
  },
  computed: {
    patients() {
      return this.$store.getters.patients;
    },
    patient_loading() {
      return this.$store.getters.patient_loading;
    },
    patient_count() {
      return this.$store.getters.patient_count;
    },
    filtertype() {
      return getFilterType(this.$route);
    }
  },
  watch: {
    $route(to, from) {
      this.getData();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.patient_list {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  margin-top: 0px;
}

.patient_list .card-content {
  padding: 10px;
}

.fixed-table-container {
  margin-top: 40px;
  max-width: 100%;
  max-height: 530px;
  overflow: scroll;
  position: relative;
}

.fixed-table-container table {
  position: relative;
  border-collapse: collapse;
  white-space: nowrap;
}

.fixed-table-container td,
.fixed-table-container th {
  padding: 20px;
}

.fixed-table-container thead th {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  top: 0;

  color: #fff;
}

.fixed-table-container thead .fixed_header {
  left: 0;
  z-index: 3;
  color: white;
}

.fixed-table-container tbody .fixed_header {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  left: 0;

  border-right: 1px solid #ccc;
}

.card {
  background: var(--card-background-color);
  color: var(--card-text-color);
}
</style>
