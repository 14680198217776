<template>
<transition name="modal" v-if="show">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            
             <h6>Manage Wrong Township</h6>
             <button @click="$emit('close')"  class="btn blue darken-3">Close</button>
          </div>

          <div class="modal-body">
              <div class="row formContainer">
                    <div class="input-field col s12 m6">
                        <select class="icons" v-model="township_id">
                        <option value="" disabled selected>Choose a township</option>
                        <option v-for="item in townships" v-bind:value="item.id">{{ item.name }}</option>
                
                        </select>
                        <label>Choose a township</label>
                    </div>
                    <div class="input-field col s12 m6">
                        <button class="btn blue darken-3" @click="transfer"
                          :disabled="transferLoading"
                        >Transfer</button>
                    </div>
              </div>
              
          </div>

          <div class="modal-footer">
            
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>

export default {
  name: 'WrongTownshipManage',
  props:["data","show"],
  data () {
    return {
        township_id:"",
    }
  },



computed: {
      townships()
      {
          return this.$store.getters.townships;
      },
    user()
     {
        return this.$store.getters.auth;
      },

      transferLoading()
      {
        return this.$store.getters.transferLoading;
      }
  },


  methods: 
  {
      transfer()
      {
        if (this.township_id)
        {
            var data={
             township_id:this.township_id,
             patient_id:this.data.id,
            
            };
            
            this.$store.dispatch("transferTownship",data);

        }
        else 
        {
            alert("No township chosen");
        }
         

         
      }
  }
  

  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  margin: 0px auto;
  height:400px;
  overflow-y: auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family:'Zawgyi-One' !important;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-header
{
    border-bottom: 0.2px solid black;
    padding-bottom:5px;
    display: flex;
    justify-content: space-between;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.formContainer 
{
    padding-top:30px;
}

    
</style>
