var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill"},[(_vm.patient_loading)?_c('div',{staticClass:"center"},[_c('loading')],1):_c('section',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"btn-box"},[_c('div',[_c('router-link',{staticClass:"btn green darken-2",attrs:{"to":'/patient/new'}},[_vm._v("New Patient")]),_c('router-link',{staticClass:"btn green darken-2",attrs:{"to":'/patients/'}},[_vm._v("All Patient")])],1),_c('button',{staticClass:"btn blue darken-3",attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(1)}}},[_vm._v(" Presumptive Info ")]),(_vm.patient.metadata && _vm.patient.metadata.refer_from != 5)?_c('fragment',[_c('button',{staticClass:"btn blue darken-3",class:{
              blue: _vm.patient.sputum,
              'required-box': _vm.patient.sputum
                ? _vm.patient.sputum.result == 4
                : false,
              'yellow black-text': !_vm.patient.sputum,
            },attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(3)}}},[_vm._v(" Sputum ")]),_c('button',{staticClass:"btn darken-3",class:{
              blue: _vm.patient.chest_xray,
              'required-box': _vm.patient.chest_xray
                ? _vm.patient.chest_xray.result == 4
                : false,
              'yellow black-text': !_vm.patient.chest_xray,
            },attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(2)}}},[_vm._v(" Chest X-ray ")]),_c('button',{staticClass:"btn blue darken-3",class:{
              blue: _vm.patient.gene_xpert,
              'required-box': _vm.patient.gene_xpert
                ? _vm.patient.gene_xpert.result == 7
                : false,
              'yellow black-text': !_vm.patient.gene_xpert,
            },attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(4)}}},[_vm._v(" Gene Xpert ")]),_c('button',{staticClass:"btn blue darken-3",attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(5)}}},[_vm._v(" Tuberculin Skin ")]),_c('button',{staticClass:"btn blue darken-3",class:{
              blue: _vm.patient.fnac,
              'required-box': _vm.patient.fnac ? _vm.patient.fnac.result == 4 : false,
              'yellow black-text': !_vm.patient.fnac,
            },attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(6)}}},[_vm._v(" FNAC ")]),_c('button',{staticClass:"btn blue darken-3",class:{
              'required-box': _vm.patient.sputum_culture
                ? _vm.patient.sputum_culture.result == 4
                : false,
            },attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(7)}}},[_vm._v(" Sputum Culture ")]),_c('button',{staticClass:"btn blue darken-3",class:{
              'required-box': _vm.patient.drug_suscept
                ? _vm.patient.drug_suscept.result == 9
                : false,
            },attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(8)}}},[_vm._v(" Drug Sensitivity ")])]):_vm._e(),_c('button',{staticClass:"btn blue darken-3",attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(10)}}},[_vm._v(" Other Investigation ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.allpassforConclusion || _vm.patient.metadata.refer_from == 5),expression:"allpassforConclusion || patient.metadata.refer_from == 5"}],staticClass:"btn blue darken-3",attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(9)}}},[_vm._v(" TB Conclusion ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col m6 offset-m3"},[(_vm.active == 1)?_c('personal-info'):_vm._e(),(_vm.active == 2)?_c('chest-xray'):_vm._e(),(_vm.active == 3)?_c('sputum'):_vm._e(),(_vm.active == 4)?_c('gene-xpert'):_vm._e(),(_vm.active == 6)?_c('fnac'):_vm._e(),(_vm.active == 5)?_c('tuber-culin-skin'):_vm._e(),(_vm.active == 7)?_c('sputum-culture'):_vm._e(),(_vm.active == 8)?_c('drug-susceptibility'):_vm._e(),(_vm.active == 9)?_c('tb-conclusion'):_vm._e(),(_vm.active == 10)?_c('other-investigation'):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }