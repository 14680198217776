<template>
  <div class="row">
    <div class="col m8 offset-m2">
      <div class="loading" v-if="loading">
        <pre-loader></pre-loader>
      </div>
      <div class="card card-content" v-else>
        <h6>Presumptive Information</h6>

        <div class="input-field">
          <span>Name</span>
          <input type="text" v-model="data.name" readonly="true" />
        </div>
        <div class="input-field">
          <span>Phone</span>
          <input type="text" v-model="data.phone" readonly="true" />
        </div>
        <div class="input-field">
          <span>Age</span>
          <input type="number" v-model="data.age" readonly="true" />
        </div>
        <div class="input-field">
          <span>Gender</span>
          <select v-model="data.gender" disabled>
            <option value="1">Male</option>
            <option value="2">Female</option>
          </select>
        </div>
        <div class="input-field">
          <span>Address</span>
          <input type="text" v-model="data.address" readonly="true" />
        </div>
        <div class="input-field">
          <span>Weight</span>
          <input type="number" v-model="data.weight" />
        </div>
        <div class="input-field">
          <span>Volunteer</span>
          <select v-model="data.volunteer_id">
            <option
              v-for="item in data.volunteers"
              :value="item.id"
              :key="item.id"
              >{{ item.name }} ({{ item.code }})</option
            >
          </select>
        </div>
        <div
          class="input-field"
          v-if="data.patient.tb_conclusion.tb_status != 2"
        >
          <span>Actual TB Code</span>
          <input
            type="text"
            :value="
              `${data.patient.tb_conclusion.new_tb_code_type}-${data.patient.tb_conclusion.new_tb_code_township}/${data.patient.tb_conclusion.new_tb_code}`
            "
            readonly="true"
          />
        </div>
        <div
          class="input-field"
          v-if="data.patient.tb_conclusion.tb_status != 2"
        >
          <span>Union Temporary Code</span>
          <input
            type="text"
            :value="`${data.patient.tb_conclusion.union_temporary_code}`"
            readonly="true"
          />
          <div
            class="input-field"
            v-if="data.patient.tb_conclusion.tb_status != 2"
          >
            <span> Temporary Code (Other)</span>
            <input
              type="text"
              :value="`${data.patient.tb_conclusion.other_temporary_code}`"
              readonly="true"
            />
          </div>
        </div>
        <div
          class="input-field"
          v-show="data.patient.tb_conclusion.tb_status == 2"
        >
          <span> TPT Status</span>
          <input
            type="text"
            :value="
              `${
                data.patient.tb_conclusion.tpt_status === 0 ? 'No TPT' : 'TPT'
              }`
            "
            readonly="true"
          />
        </div>
        <div class="input-field">
          <span> MDR TB Code / TPT Code</span>
          <input
            type="text"
            :value="`${data.patient.tb_conclusion.mdr_tb_code}`"
            readonly="true"
          />
        </div>
        <div
          class="input-field"
          v-if="data.patient.tb_conclusion.tb_status != 2"
        >
          <span>Defiend TB Type</span>
          <select v-model="data.defined_tb_type_id" disabled>
            <option value="1">Pulmonary BC</option>
            <option value="2">Extra Pulmonary BC</option>
            <option value="3">Pulmonary CD</option>
            <option value="4">Extra Pulmonary CD</option>
          </select>
        </div>
        <div
          class="input-field"
          v-if="data.patient.tb_conclusion.tb_status != 2"
        >
          <span>Treatment Regimen</span>
          <select v-model="data.treatment_reg_id" disabled>
            <option value="1">Initial Regimen</option>
            <option value="2">Retreatment Regiment</option>
            <option value="3">Childhood Regiment</option>
            <option value="4">MDRTB Regiment</option>
          </select>
        </div>
        <div
          class="input-field"
          v-if="data.patient.tb_conclusion.tb_status != 2"
        >
          <span>Sputum Date</span>
          <input
            type="text"
            v-model.lazy="data.sputum_date"
            class="datepicker"
          />
        </div>
        <div class="input-field">
          <span>Treatment Start Date</span>
          <input
            type="text"
            v-model.lazy="data.treatment_startdate"
            readonly="true"
          />
        </div>
         <div class="input-field">
          <span>Actual Treatment Start Date</span>
          <input
            type="text"
            v-model.lazy="data.actual_treatment_startdate"
          class="datepicker"
          />
        </div>
        <div class="input-field">
          <span>Treatment End Date</span>
          <input
            type="text"
            class="datepicker"
            v-model.lazy="data.treatment_enddate"
            readonly="true"
          />
        </div>

        <div class="input-field">
          <span>Treatment Outcome</span>
          <select
            v-model.lazy="data.treatment_outcome_id"
            v-model="data.treatment_outcome_id"
            disabled
          >
            <option value="1">Cured</option>
            <option value="2">Treatment complete</option>
            <option value="3">Treament Failure</option>
            <option value="4">Died</option>
            <option value="5">Lost to follow up</option>
            <option value="6">Not Evaluated</option>
            <option value="7">Moved to second line treatment</option>
          </select>
        </div>
        <div class="input-field">
          <span>Outcome Date</span>
          <input type="text" v-model.lazy="data.outcome_date" readonly="true" />
        </div>
        <div class="input-field">
          <span>DOTS Start Date</span>
          <input
            type="text"
            class="datepicker"
            v-model.lazy="data.dots_startdate"
            readonly="true"
          />
        </div>
        <!-- <div class="input-field">
          <span>DOTS End Date</span>
          <input
            type="text"
            class="datepicker"
            readonly="true"
            v-model.lazy="data.dots_enddate"
          />
        </div> -->
        <div class="input-field">
          <span>Using DOTS APP</span>
          <select v-model="data.dots_app_status">
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
        <div class="input-field">
          <span>Remark</span>
          <input type="text" v-model="data.remark" />
        </div>
        <div class="input-field center" v-if="validate">
          <button
            :disabled="action_loading"
            type="button"
            class="btn blue darken-3"
            @click="submitData"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dots_app_status: "",
    };
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
    this.$store.dispatch("getDOTSPatientInitData", {
      id: this.$route.params.id,
    });
  },
  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  computed: {
    loading() {
      return this.$store.getters.dots_initial_data_loading;
    },
    data() {
      return this.$store.getters.dots_initial_data;
    },
    action_loading() {
      return this.$store.getters.dots_action_loading;
    },
    finish() {
      return this.$store.getters.dots_action_finish;
    },
    validate() {
      if (
        this.data.name &&
        this.data.age &&
        this.data.gender &&
        this.data.address &&
        this.data.phone &&
        this.data.weight &&
        this.data.actual_treatment_startdate &&
        ((this.data.defined_tb_type_id &&
          this.data.treatment_reg_id &&
          this.data.treatment_startdate) ||
          this.data.patient.tb_conclusion.tpt_status == 1) &&
        this.data.dots_startdate &&
        this.data.dots_app_status
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    submitData() {
      var date1 = new Date(this.data.treatment_startdate);
      var date2 = new Date(this.data.dots_startdate);
      var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
      if (diffDays >= 0) {
        this.data.is_new = 0;
        this.data.type = null;
        this.$store.dispatch("add_dots_patient", this.data);
        // var date1 = new Date(this.data.sputum_date);
        // var date2 = new Date(this.data.treatment_startdate);
        // var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
        // if (diffDays >= 0) {
        //   this.data.is_new = 0;
        //   this.data.type = 1;
        //   this.$store.dispatch("add_dots_patient", this.data);
        // } else {
        //   this.$notify({
        //     group: "noti",
        //     type: "error",
        //     text: "Treatment Start date should be greater than Sputum Date",
        //   });
        //}
      } else {
        this.$notify({
          group: "noti",
          type: "error",
          text: "DOTS Start date should be greater than Treatment Startdate",
        });
      }
    },
  },
  watch: {
    finish(val) {
      if (val) {
        this.$router.push("/patient/dots/list");
        //
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 30px;
  padding: 20px;
  background: var(--card-background-color) !important;
}
</style>
