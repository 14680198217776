import { sendData, getData } from "./../../misc/server.js";
export default {
  state: {
    loading: false,
    townships: [],
    finish: false,
    confirmLoading: false,
    transferedData: [],
    confirmFinish: false,
    unseen_counts: 0,
  },
  mutations: {
    setTownships(state, payload) {
      state.townships = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setFinish(state, payload) {
      state.finish = payload;
    },
    setTransferedData(state, payload) {
      state.transferedData = payload;
    },
    confirmLoading(state, payload) {
      state.confirmLoading = payload;
    },
    confirmFinish(state, payload) {
      state.confirmFinish = payload;
    },
    setUnseenCount(state, payload) {
      state.unseen_counts = payload;
    },
  },
  actions: {
    getTownships({ commit }, data) {
      var url = "/townships/get";

      getData(url, (response) => {
        commit("setTownships", response);
      });
    },
    getTownshipsByProject({ commit }, projectID) {
      var url = "/townships/get?group_id=" + projectID + "&";

      getData(url, (response) => {
        commit("setTownships", response);
      });
    },
    transferTownship({ commit }, data) {
      var url = "/townships/transfer";
      commit("setLoading", true);
      sendData(url, data, (response) => {
        commit("setLoading", false);
        if (response.success) {
          commit("setFinish", true);
        }
      });
    },
    getTransferedChecking({ commit }, data) {
      var url = "/townships/transfer/get";
      if (data.id) {
        url += "?township_id" + data.id;
      }

      commit("setLoading", true);
      getData(url, (response) => {
        commit("setLoading", false);
        commit("setTransferedData", response);
      });
    },
    confirmTransfer({ commit }, data) {
      var url = "/townships/transfer/confirm";

      commit("confirmLoading", true);
      sendData(url, { id: data.id }, (response) => {
        console.log(response);
        commit("confirmLoading", false);
        if (response.success) {
          commit("confirmFinish", true);
        }
      });
    },
    loadNewTransfer({ commit }) {
      var url = "/townships/transfer/unseen";

      commit("setLoading", true);
      getData(url, (response) => {
        commit("setLoading", false);
        commit("setUnseenCount", response);
      });
    },
  },
  getters: {
    townships(state) {
      return state.townships;
    },
    transferLoading(state) {
      return state.loading;
    },
    transferFinish(state) {
      return state.finish;
    },
    transferedData(state) {
      return state.transferedData;
    },
    confirmLoading(state) {
      return state.confirmLoading;
    },
    confirmFinish(state) {
      return state.confirmFinish;
    },
    unseen_counts(state) {
      return state.unseen_counts;
    },
  },
};
