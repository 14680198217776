<template>
  <div class="card card-content" style="margin-top:10px;">
    <div class="router-links">
      <router-link :to="'/dots/visitdate/' + data.id" class="btn blue darken-3 router-link"
        >DOTS Visit Date</router-link
      >
      <router-link
        :to="`/dots/visitdate/report/${data.id}`"
        class="btn blue darken-3 router-link"
        >Visit Reports</router-link
      >
      <router-link
        :to="`/dots/volunteer/change/${data.id}`"
        class="btn blue darken-3 router-link"
        >Change Volunteer</router-link
      >
      <router-link
        v-show="data.is_new == 0 && !data.link_township_id"
        :to="`/patient/fill/${data.patient_id}`"
        class="btn blue darken-3 router-link"
        >View Info</router-link
      >
      <router-link
        :to="`/patient/dots/month-info/${data.id}`"
        class="btn blue darken-3 router-link"
        >View Month 0,2/3,5 Info</router-link
      >
    </div>
    <div>
      
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    data() {
      return this.$store.getters.dots_initial_data;
    },
  },
};
</script>

<style scoped>
.router-link {
  margin-left: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .router-links {
    display: flex;
    flex-direction: column;
  }

  .router-link {
    margin-bottom: 10px;
    margin-left: 0;
  }
}
</style>
