<template>
  <div v-if="other_investigation" class="card card-content">
    <h6>Other Investigation</h6>
    <form>
      <div class="row">
        <div class="input-field col s12">
          <div class="switch">
            <label>
              Not Done
              <input
                type="checkbox"
                @click="switchChange"
                :checked="other_investigation.done == 1"
              />
              <span class="lever"></span>
              Done
            </label>
          </div>
        </div>
        <div class="input-field col s12">
          <span>Investigation Date</span>
          <input
            type="text"
            placeholder="YYYY-MM-DD"
            v-model.lazy="other_investigation.date"
            class="datepicker"
            :disabled="!other_investigation.done"
            readonly
          />
        </div>
        <div class="input-field col s12" v-show="other_investigation.done">
          <span>Investigation Name</span>
          <input
            type="text"
            v-model.lazy="other_investigation.name"
            :disabled="!other_investigation.done"
          />
        </div>
        <div class="input-field col s12" v-show="other_investigation.done">
          <span>Investigation Result</span>
          <input
            type="text"
            v-model.lazy="other_investigation.result"
            :disabled="!other_investigation.done"
          />
        </div>
        <div class="input-field col s12" v-show="other_investigation.done == 1">
          <span>AFB seen/not</span>
          <select v-model="other_investigation.seen_not_seen">
            <option value="" disabled selected>Choose your option</option>
            <option value="seen">Seen</option>
            <option value="not seen">Not Seen</option>
            <option value="not done">Not Done</option>
          </select>
        </div>
        <div class="row center" v-if="buttonpass">
          <button
            type="button"
            class="btn blue darken-3"
            @click="submit"
            :disabled="updateloading"
          >
            Edit
          </button>
        </div>
      </div>
    </form>
  </div>
  <new-other-investigation v-else></new-other-investigation>
</template>

<script>
import NewOtherInvestigationVue from "./NewOtherInvestigation.vue";
export default {
  name: "other_investigation",
  data() {
    return {
      done: false,
      date: "",
      result: "",
    };
  },
  components: {
    "new-other-investigation": NewOtherInvestigationVue,
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  watch: {},
  methods: {
    switchChange() {
      this.other_investigation.done =
        this.other_investigation.done == 0 ? 1 : 0;
    },

    submit() {
      var data = {
        done: this.other_investigation.done,
        date: null,
        name: null,
        result: null,
        seen_not_seen: null,
      };

      if (data.done) {
        data.date = this.other_investigation.date;
        data.result = this.other_investigation.result;
        data.name = this.other_investigation.name;
        data.seen_not_seen = this.other_investigation.seen_not_seen;
      }

      data.id = this.patient.id;

      this.$store.dispatch("submitOtherInvestigation", data);
    },
  },

  computed: {
    patient() {
      return this.$store.getters.patient;
    },

    other_investigation() {
      var item = localStorage.getItem("current_data");
      var data = this.$store.getters.patient.other_investigation;
      if (!item) {
        localStorage.setItem("current_data", JSON.stringify(data));
      }
      return data;
    },

    buttonpass() {
      if (this.other_investigation.done == 1) {
        if (this.other_investigation.result && this.other_investigation.date) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    updateloading() {
      return this.$store.getters.updateloading;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fill {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

.fill .card {
  padding: 20px;
}
</style>
