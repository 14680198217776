import { render, staticRenderFns } from "./NewChestXray.vue?vue&type=template&id=feaee268&scoped=true"
import script from "./NewChestXray.vue?vue&type=script&lang=js"
export * from "./NewChestXray.vue?vue&type=script&lang=js"
import style0 from "./NewChestXray.vue?vue&type=style&index=0&id=feaee268&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "feaee268",
  null
  
)

export default component.exports