import { sendData, getData } from "./../../misc/server.js";
export default {
  state: {
    data: [],

    loading: false,
  },
  mutations: {
    setMetaData(state, payload) {
      state.data = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setTownships(state, payload) {
      state.townships = payload;
    },
  },
  actions: {
    getPatientMetaData({ commit }, data) {
      var url = "/checkings/metadata/get";

      commit("setLoading", true);
      getData(url, (response) => {
        commit("setMetaData", response);
        commit("setLoading", false);
      });
    },
  },
  getters: {
    symptons(state) {
      return state.data.symptons;
    },

    submit_departments(state) {
      return state.data.submitdepartments;
    },
    submit_types(state) {
      return state.data.submittypes;
    },
    submit_types(state) {
      return state.data.submittypes;
    },
    all_townships(state) {
      return state.data.townships;
    },
    foundplaces(state) {
      return state.data.foundplaces;
    },
    risk_factors(state) {
      return state.data.risk_factors;
    },
    patient_sccs(state) {
      return state.data.sccs;
    },
    presumptive_number(state) {
      return state.data.presumptive_number;
    },
    tpt_presumptive_number(state) {
      return state.data.tpt_presumptive_number;
    },
    meta_volunteers(state) {
      return state.data.volunteers;
    },

    meta_data_loading(state) {
      return state.loading;
    },
  },
};
