<template>
 
       <fragment>             
                               
                                <th>Cxrtest</th>
                                <th>Cxrdate</th>
                                <th>Cxrres</th>
                            
                              
        </fragment>                     
                
                  
</template>

<script>



export default {
  name: 'Header',
  data () {
    return {
    }
  },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    
     
</style>
