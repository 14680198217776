<template>
  <div class="Activities">
    <div style="padding-left:10px;margin-bottom:5px;">
      <back-container link="/activity/choosebox"></back-container>
    </div>
    <div class="row center" v-if="loading">
      <loading></loading>
    </div>
    <div class="row" v-else>
      <div class="col m4 s6" v-for="item in activities" v-bind:key="item.id">
        <a @click="chooseActivity(item.id)" >
          <div class="card z-depth-3 white lighten-4">
            <div class="card-content center">
              <i class="material-icons icons">list</i>
              <h6>{{ item.name }}</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Partials/Loading.vue";
import BackContainer from "@/components/ReUse/BackContainer.vue";
export default {
  name: "Activities",
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("getActivities");
  },
  components: {
    loading: Loading,
    "back-container": BackContainer
  },
  computed: {
    activities() {
      return this.$store.getters.activities;
    },
    loading() {
      return this.$store.getters.activities_loading;
    }
  },
  methods: {
    chooseActivity(id) {
      var { year, month } = this.$route.params;
      this.$router.push({
        name: "EachActivity",
        params: { id: id, year: year, month: month }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Activities {
  padding-top: 30px;
  padding-left: 5%;
  padding-right: 5%;
}

.forward
{
    color:var(--card-background-color) !important;
    position: absolute;
    right:0px;
    bottom:2px;
    font-size:40px;
}
    
.icons 
{
    font-size:50px;
    color:var(--card-icon-color) !important;
}

 .card-content
{
    padding-top:35px;
    padding-bottom:30px;
}

 .card
 {
     border-radius: 20px;
     border-left:20px solid var(--card-icon-color) !important;
     height:200px;
     background:var(--card-background-color) !important;
 }

 .card h6 
 {
     color:var(--card-text-color);
 }


 .card:hover 
{
    border-right:20px solid var(--card-icon-color) !important;
    transition:2s;
}

.card:hover .forward
{
    color:var(--card-icon-color) !important;
    transition: 2s;
}




 .card-content h6 
{
    font-size:22px;
}
</style>
