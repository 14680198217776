<template>
  <div class="row fill">
    
    <div class="center" v-if="patient_loading">
        <loading></loading>
     </div>
    <div class="col m6 offset-m3">
       <div>
            <br/>
            <back-container link="/checkings/7/confirmed"></back-container>
    </div>
      <div class="card card-content z-depth-3" v-if="!meta_data_loading && !patient_loading">
        <h6>Presumptive Information</h6>
        <form>
          <div class="row">
            <div class="input-field col s12">
              <span>Name</span>
              <input type="text" v-model="patient.name" />
            </div>
            <div class="input-field col s12">
              <span>Address</span>
              <input type="text" v-model="patient.address" />
            </div>
            <div class="input-field col s12">
              <span>Phone</span>
              <input type="text" v-model="patient.phone" />
            </div>
            <div class="input-field col s12">
              <span>Age</span>
              <input type="number" v-model="patient.age" />
            </div>
            <div class="input-field col s12">
              <span>Gender</span>
              <p>
                <label>
                  <input
                    name="group1"
                    type="radio"
                    value="1"
                    :checked="patient.gender==1"
                    @click="patient.gender=1"
                  />
                  <span>ကၽ်ား</span>
                </label>
              </p>
              <p>
                <label>
                  <input
                    name="group1"
                    type="radio"
                    value="2"
                    :checked="patient.gender==2"
                    @click="patient.gender=2"
                  />
                  <span>မ</span>
                </label>
              </p>
            </div>

            <div class="input-field col s12">
              <span>Volunteers</span>
              <select v-model="patient.volunteer_id">
                <option value disabled selected>Choose your option</option>
                <option v-for="item in volunteers" v-bind:value="item.id">{{ item.code }} ({{ item.name }})</option>
              </select>
            </div>

            <div class="input-field col s12">
              <span>Referred To</span>

              <p v-for="item in submit_departments">
                <label>
                  <input
                    name="submit_department_id"
                    v-model="patient.metadata.patient_submit_department_id"
                    type="radio"
                    :value="item.id"
                  />
                  <span>{{ item.eng_name }}</span>
                </label>
              </p>

              <div v-show="patient.metadata.patient_submit_department_id==3">
                <select v-model="patient.metadata.psd_description">
                  <option value disabled selected>Choose your option</option>
                  <option v-for="item in sccs" v-bind:value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="input-field col s12">
              <span>Case Founded Activity</span>

              <p v-for="item in foundplaces">
                <label>
                  <input
                    name="patient_found_place_id"
                    type="radio"
                    v-model="patient.metadata.patient_found_place_id"
                    :value="item.id"
                  />
                  <span>{{ item.eng_name }}</span>
                </label>
              </p>
              <div
                class="row"
                v-show="patient.metadata.patient_found_place_id==2 || patient.metadata.patient_found_place_id==3"
              >
                <div class="col s6">
                  <input
                    type="number"
                    min="10000"
                    max="99999"
                    v-model="patient.metadata.pfp_description"
                  />
                </div>
                <div class="col s6">
                  <select v-model="patient.metadata.sub_pfp">
                    <option value disabled selected>Choose your option</option>
                    <option value="1">House Hold</option>
                    <option value="2">Non-household</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="input-field col s12">
              <span>Referred Type</span>

              <p v-for="item in submit_types">
                <label>
                  <input
                    name="submit_type_id"
                    type="radio"
                    v-model="patient.metadata.patient_submit_type_id"
                    :value="item.id"
                  />
                  <span>{{ item.eng_name }}</span>
                </label>
              </p>
            </div>
            <div class="input-field col s12" v-show="patient.age">
              <span>Symptoms</span>

              <p v-for="item in symptons">
                <label>
                  <input
                    type="checkbox"
                    name="symptons"
                    :value="item.id"
                    v-model="checked_symptons"
                  />
                  <span>{{ item.eng_name }}</span>
                </label>
              </p>
            </div>
            <div class="input-field col s12">
              <span>Referral Date</span>
              <input type="text" v-model.lazy="patient.submitted_date" class="datepicker" readonly />
            </div>

            <div class="row center" v-if="buttonpass && meta_validate">
              <button
                :disabled="patient_add_loading"
                type="button"
                @click="add"
                class="btn blue darken-3"
              >Edit</button>
              <button class="btn red darken-3" type="button"
                :disabled="delete_refer_loading" @click="deleteReferPatient"
              >
                  Delete
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import BackContainer from '@/components/ReUse/BackContainer.vue';
import Loading from '@/components/Partials/Loading.vue';
export default {
  name: "AddNewPatient",
  data() {
    return {
      checked_symptons: [],
      
    };
  },
  
  mounted() {

      var data = {};

    data.id = this.$route.params.patient_id;

    data.load = false;
    data.lastid = 0;
    this.$store.dispatch("getPatientandMetaData", data);
    

    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn:true,
    });
  },

  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn:true,
    });
  },

  methods: {
    add() {
      var data = {
        name: this.patient.name,
        address: this.patient.address,
        phone: this.patient.phone,
        age: this.patient.age,
        gender: this.patient.gender,
        submitted_date: this.patient.submitted_date,
        symptons: this.checked_symptons,
        id: this.patient.id
      };

      data.patient_submit_department_id = this.patient.metadata.patient_submit_department_id;
      data.patient_found_place_id = this.patient.metadata.patient_found_place_id;
      data.patient_submit_type_id = this.patient.metadata.patient_submit_type_id;
      data.pfp_description = null;
      data.psd_description = null;
      if (this.patient.metadata.patient_submit_department_id == 3) {
        data.psd_description = this.patient.metadata.psd_description;
      }

      if (
        this.patient.metadata.patient_found_place_id == 2 ||
        this.patient.metadata.patient_found_place_id == 3
      ) {
        data.pfp_description = this.patient.metadata.pfp_description;
        data.sub_pfp = this.patient.metadata.sub_pfp;
      }

      data.volunteer_id = this.patient.volunteer_id;

      this.$store.dispatch("editReferralPatient", data);
    },
    deleteReferPatient()
    {
      this.$store.dispatch("deleteReferralPatient", this.patient.id);
    }
  },

  components: {
     "loading":Loading,
     "back-container":BackContainer,
  },
  computed: {
    delete_refer_loading()
    {
      return this.$store.getters.refer_patient_delete_loading;
    },
    refer_patient_delete_finish()
    {
      return this.$store.getters.refer_patient_delete_finish;
    },
    
    volunteers() {
      return this.$store.getters.meta_volunteers;
    },
    symptons() {
      var symptons = this.$store.getters.symptons;
      if (symptons) {
        var patient_symptons = this.patient.symptons.map(value => {
          return value.id;
        });

        

        symptons = symptons.map(value => {
          if (patient_symptons.indexOf(value.id) != -1) {
            value.checked = true;
          } else {
            value.checked = false;
          }

          return value;
        });
      }

      return symptons;
    },

    meta_data_loading() {
      return this.$store.getters.meta_data_loading;
    },
    patient_loading()
    {
        return this.$store.getters.patient_loading;
    },
    patient_add_finish() {
      return this.$store.getters.patient_add_finish;
    },

    buttonpass() {
      if (
        this.patient.name &&
        this.patient.address &&
        this.patient.phone &&
        this.patient.age &&
        this.patient.gender &&
        this.patient.volunteer_id &&
        this.checked_symptons.length > 0 &&
        this.patient.submitted_date
      ) {
        return true;
      } else {
        return false;
      }
    },
    meta_validate() {
      var value = true;

      if (
        this.patient.metadata.patient_submit_department_id == 3 &&
        (this.patient.metadata.psd_description == null ||
          this.patient.metadata.psd_description.length == 0)
      ) {
        value = false;
      } else if (
        this.patient.metadata.patient_found_place_id == 2 &&
        (this.patient.metadata.pfp_description == null ||
          this.patient.metadata.pfp_description.length < 5)
      ) {
        value = false;
      } else if (!this.patient.volunteer_id) {
        value = false;
      } else {
        value = true;
      }

      return value;
    },
    submit_types() {
      var data = this.$store.getters.submit_types;

      return data;
    },
    foundplaces() {
      var data = this.$store.getters.foundplaces;

      return data;
    },
    sccs() {
      return this.$store.getters.patient_sccs;
    },
    submit_departments() {
      var data = this.$store.getters.submit_departments;
      return data;
    },

    patient_add_loading() {
      return this.$store.getters.patient_add_loading;
    },

    patient_add_finish() {
      return this.$store.getters.patient_add_finish;
    },
    patient() {
      var data = this.$store.getters.patient;
      return data;
    }
  },
  watch: {
    patient_add_finish(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "Inserted"
        });
        location.reload();
      }
    },
     refer_patient_delete_finish(val) {
      if (val) {
        this.$router.push("/checkings/7/confirmed");
      }
    },
    symptons(val) {
      
      this.checked_symptons = [];

      this.symptons = this.symptons.map(value => {
        if (value.checked) {
          this.checked_symptons.push(value.id);
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fill {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

.fill .card {
  padding: 20px;
}
</style>
