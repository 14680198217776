<template>
    <div>
      <span v-if="status==1">Waiting </span>
      <span v-if="status==2">Accepted</span>
      <span v-if="status==3">Submitted</span>
      <span v-if="status==4">Can't Contact</span>
      <span v-if="status==5">Fake</span>
      <span v-if="status==6">Wrong Township</span>
    </div>
</template>
<script>

export default {
  name: 'StatusBox',
  props:["status"],
  data () {
    return {
     
    }
  },
  computed: 
  {
      counts()
      {
          return this.$store.getters.counts;
      }
  }
  

  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 
</style>
