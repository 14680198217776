import { sendData, getData } from "./../../misc/server.js";

export default {
  state: {
    loading: false,
    action_loading: false,
    action_finish: false,
    data: null,
    checking_loading: false,
    generate_checking_error: false,
    edit_success: false,
    edit_error: false,
    dots_edit_history: [],
    high_side_effects: [],
    low_side_effects: [],
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_EDIT_HISTORY(state, payload) {
      state.dots_edit_history = payload;
    },
    SET_ACTION_LOADING(state, payload) {
      state.action_loading = payload;
    },
    SET_ACTION_FINISH(state, payload) {
      state.action_finish = payload;
    },
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_GENERATE_CHECKING_LOADING(state, payload) {
      state.checking_loading = payload;
    },
    SET_GENERATE_CHECKING_ERROR(state, payload) {
      state.generate_checking_error = payload;
    },
    REMOVE_DELETED_DATA(state, payload) {
      state.data.visit_dates = state.data.visit_dates.filter((val) => {
        return val.id != payload;
      });
    },
    SET_EDIT_SUCCESS(state, payload) {
      state.edit_success = payload;
    },
    SET_EDIT_ERROR(state, payload) {
      state.edit_error = payload;
    },
    SET_HIGH_SIDE_EFFECTS(state, payload) {
      state.high_side_effects = payload;
    },
    SET_LOW_SIDE_EFFECTS(state, payload) {
      state.low_side_effects = payload;
    },
  },
  actions: {
    saveDOTSVisitDate({ commit }, payload) {
      var url = "/dots/visitdate/save";
      commit("SET_ACTION_LOADING", true);
      commit("SET_ACTION_FINISH", false);
      sendData(url, payload, (data) => {
        if (data.success) {
          commit("SET_ACTION_FINISH", true);
        }
        commit("SET_ACTION_LOADING", false);
      });
    },
    getDOTSVisitDate({ commit }, payload) {
      var url = "/dots/visitdate/get?id=" + payload.patient_id + "&";
      if (payload.nowMonth) {
        url += "nowMonth=true&";
      }

      if (payload.start_date && payload.end_date) {
        url += `start_date=${payload.start_date}&end_date=${payload.end_date}&`;
      }

      commit("SET_LOADING", true);
      getData(url, (data) => {
        commit("SET_DATA", data);
        commit("SET_LOADING", false);
      });
    },
    getDOTSVisitDateEdit({ commit }, payload) {
      var url = "/dots/visitdate/edit-history/get?id=" + payload.id + "&";
      commit("SET_LOADING", true);
      getData(url, (data) => {
        commit("SET_EDIT_HISTORY", data.data);
        commit("SET_LOADING", false);
      });
    },
    deleteDOTSVisitDate({ commit }, payload) {
      var url = "/dots/visitdate/delete";
      commit("SET_ACTION_LOADING", true);
      commit("SET_ACTION_FINISH", false);
      sendData(url, payload, (data) => {
        if (data.success) {
          commit("SET_ACTION_FINISH", true); 
          commit("REMOVE_DELETED_DATA", payload.id);
        }
        commit("SET_ACTION_LOADING", false);
      });
    },
    checkDOTSVisitDate({ commit }, payload) {
      var url = "/dots/visitdate/check";
      commit("SET_GENERATE_CHECKING_LOADING", true);
      commit("SET_GENERATE_CHECKING_ERROR", false);
      sendData(url, payload, (data) => {
        if (!data) commit("SET_GENERATE_CHECKING_ERROR", true);
        commit("SET_GENERATE_CHECKING_LOADING", false);
      });
    },
    editVisitDate({ commit }, payload) {
      var url = "/dots/visitdate/edit";
      commit("SET_ACTION_LOADING", true);
      commit("SET_EDIT_SUCCESS", false);
      commit("SET_EDIT_ERROR", false);
      sendData(url, payload, (data) => {
        commit("SET_ACTION_LOADING", false);
        if (data.success) {
          commit("SET_EDIT_SUCCESS", true);
        } else {
          commit("SET_EDIT_ERROR", true);
        }
      });
    },
    editVisitDateInfo({ commit }, payload) {
      var url = "/dots/visitdate/editInfo";
      sendData(url, payload, (data) => {
        if (data.success) location.reload();
      });
    },
    getSideEffects({ commit }) {
      var url = "/side_effects/get";
      getData(url, (data) => {
        commit("SET_LOW_SIDE_EFFECTS", data.low_side_effects);
        commit("SET_HIGH_SIDE_EFFECTS", data.high_side_effects);
      });
    },
  },
  getters: {
    dots_visitdates(state) {
      return state.data;
    },
    dots_visitdates_loading(state) {
      return state.loading;
    },
    dots_visitdates_action_loading(state) {
      return state.action_loading;
    },
    dots_visitdates_action_finish(state) {
      return state.action_finish;
    },
    dots_checking_loading(state) {
      return state.checking_loading;
    },
    dots_generate_checking_error(state) {
      return state.generate_checking_error;
    },
    dots_visit_date_edit_success(state) {
      return state.edit_success;
    },
    dots_visit_date_edit_error(state) {
      return state.edit_error;
    },
    dots_edit_history(state) {
      return state.dots_edit_history;
    },
    high_side_effects(state) {
      return state.high_side_effects;
    },
    low_side_effects(state) {
      return state.low_side_effects;
    },
  },
};
