import { sendData,getData } from './../../misc/server.js';
export default {
  state: {
    action_loader:false,
    add_finish:false,
    data:[],
    loading:false,
  },
  mutations: {
  
   setActionLoader(state,payload)
    {
        state.action_loader=payload;
    },
  
  addFinish(state,payload)
  {
      state.add_finish=payload;
  },
  setData(state,payload)
  {
      state.data=payload;
      
  },
  setLoader(state,payload)
  {
      state.loading=payload;
  }
   
   
  },
   actions: {
   
    addSCC({commit},data)
    {
        var url="/scc/add";
        commit("setActionLoader",true);
        sendData(url,data,(response)=>{
            commit("setActionLoader",false);
            if (response.success)
            {
                commit("addFinish",true);
            }
            
        })
    },
    getSccs({commit})
    {
        var url="/scc/get";
        commit("setLoader",true);
        getData(url,(response)=>{
            commit("setLoader",false);
            
           commit("setData",response.sccs);
            
        })
    },
    deleteSCC({commit},data)
    {
          var url="/scc/delete";

          url+="?id="+data.id;

          url+="&";
        commit("setActionLoader",true);
        getData(url,(response)=>{
            commit("setActionLoader",false);
            if (response.success)
            {
                commit("addFinish",true);
            }
            
        })
    }

  },
  getters: {
    
    action_loader(state)
    {
        return state.action_loader;
    },
    add_finish(state)
    {
        return state.add_finish;
    },
    sccs(state)
    {
        return state.data;
    },
    scc_loading(state)
    {
        return state.loading;
    }
   
 
  }
}