<template>
  <transition name="modal" v-if="show">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h6>Detail About Visit Date</h6>
            <button @click="$emit('close')" class="btn blue darken-3">
              Close
            </button>
          </div>
          <div class="modal-body">
            <div>
              <table>
                <tr>
                  <td>Patient Name</td>
                  <td>{{ detailData.dots_patient.name }}</td>
                </tr>
                <tr v-if="detailData.volunteer">
                  <td>Volunteer Name</td>
                  <td>
                    {{ detailData.volunteer.code }} ({{
                      detailData.volunteer.name
                    }})
                  </td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td>{{ detailData.date }}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{{ getStatus(detailData.status) }}</td>
                </tr>
                <tr v-show="detailData.type">
                  <td>Type</td>
                  <td>
                    By {{ parseInt(detailData.type) === 1 ? "Phone" : "Going" }}
                  </td>
                </tr>
                <tr v-show="detailData.added_by">
                  <td>Added By</td>
                  <td>{{ detailData.added_by == 1 ? "Volunteer" : "PA" }}</td>
                </tr>
                <tr v-show="detailData.status == 1">
                  <td>Pill Count</td>
                  <td>{{ detailData.pill_count }}</td>
                </tr>
                <tr v-if="detailData.type == 1">
                  <td>Duration</td>
                  <td>{{ secondsToHms(detailData.duration) }}</td>
                </tr>
                <tr v-show="detailData.type == 2">
                  <td>Signature</td>
                  <td>
                    <img
                      class="materialboxed"
                      :src="'data:image/png;base64, ' + detailData.signature"
                      alt
                      style="width:200px;height:200px;"
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div
              class="row side_effect_container"
              v-show="detailData.status == 1"
            >
              <h6>Minor Side Effect</h6>
              <p
                v-for="(item, index) in detailData.low_side_effect"
                v-bind:key="item.id"
              >
                {{ index + 1 }} {{ item.side_effect }}
              </p>
            </div>
            <div
              class="row side_effect_container"
              v-show="detailData.status == 1"
            >
              <h6>Major Side Effect</h6>
              <p
                v-for="(item, index) in detailData.high_side_effect"
                v-bind:key="item.id"
              >
                {{ index + 1 }} {{ item.side_effect }}
              </p>
            </div>
          </div>

          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Detail",
  props: ["show", "detailData"],
  data() {
    return {};
  },
  mounted() {
    var elems = document.querySelectorAll(".materialboxed");
    var instances = M.Materialbox.init(elems, {});
  },
  updated() {
    var elems = document.querySelectorAll(".materialboxed");
    var instances = M.Materialbox.init(elems, {});
  },
  methods: {
    getStatus(id) {
      switch (parseInt(id)) {
        case 1:
          return "Viisted";
          break;
        case 2:
          return "Cannot Connect";
          break;
        case 0:
          return "Pending";
          break;
      }
    },
    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
      return hDisplay + mDisplay + sDisplay;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  margin: 0px auto;
  height: 700px;
  overflow-y: auto;
  padding: 20px 30px;
  background-color: var(--app-modal-background);
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-body {
  margin: 20px 0;
}

.modal-header {
  border-bottom: 0.2px solid black;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.side_effect_container {
  padding: 20px;
}
</style>
