<template>
  <fragment>
        
      <table class="myCustomFixedTable"><slot></slot></table>
         
   </fragment>
</template>

<script>
export default {
  name: 'ScrollTable',
  props:["height"],
  data () {
    return {
      
    }
  },
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.myCustomFixedTable
{
    width:100%;
}
.myCustomFixedTable tbody {
  display: block;
  max-height: 350px;
  overflow-y: scroll;
}

.myCustomFixedTable td ,.myCustomFixedTable th 
{
  border:none !important;
}

.myCustomFixedTable thead,.myCustomFixedTable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.myCustomFixedTable thead {
  width: calc( 100% - 1.1em );
}


</style>
