<template>
  <div class="row fill">
    <div class="row">
      <back-container :link="'/patients'"></back-container>
    </div>
    <div class="col m6 offset-m3">
      <div class="card card-content z-depth-3" v-if="!meta_data_loading">
        <h6>TPT Information</h6>
        <form>
          <div class="row">
            <div class="input-field col s12">
              <span>Sr. No : {{ presumptive_number }}</span>
              <input v-model="presumptive_tb_number" />
            </div>
            <div class="input-field col s12">
              <span>Registeration Date</span>
              <input
                type="text"
                v-model.lazy="registeration_date"
                class="datepicker"
                @change="reInitializeDatePicker"
                readonly
              />
            </div>
            <div class="input-field col s12">
              <span>Name</span>
              <input type="text" v-model="name" />
            </div>
            <div class="input-field col s12">
              <span>Age</span>
              <input type="number" v-model="age" />
            </div>
            <div class="input-field col s12">
              <span>Gender</span>
              <p>
                <label>
                  <input
                    name="group1"
                    type="radio"
                    value="1"
                    :checked="gender == 1"
                    @click="gender = 1"
                  />
                  <span>ကျား</span>
                </label>
              </p>
              <p>
                <label>
                  <input
                    name="group1"
                    type="radio"
                    value="2"
                    :checked="gender == 2"
                    @click="gender = 2"
                  />
                  <span>မ</span>
                </label>
              </p>
            </div>
            <div class="input-field col s12">
              <span>Address</span>
              <input type="text" v-model="address" />
            </div>
            <div class="input-field col s12">
              <span>Phone</span>
              <input type="text" v-model="phone" />
            </div>

            <div class="input-field col s12">
              <span>Refer From</span>

              <select v-model="refer_from">
                <option value disabled selected>Choose your option</option>
                <option value="7">PICTS LTBI</option>
              </select>
            </div>

            <fragment v-if="refer_from == 7">
              <div class="input-field col s12" v-show="is_volunteer">
                <span>Volunteers</span>
                <select v-model="volunteer_id">
                  <option value disabled selected>Choose your option</option>
                  <option v-for="item in volunteers" v-bind:value="item.id">
                    {{ item.code }} ({{ item.name }})
                  </option>
                </select>
              </div>
              <div class="input-field col s12" v-show="is_volunteer">
                <span>Referral Date</span>
                <input
                  type="text"
                  v-model.lazy="referral_date"
                  class="datepicker"
                  readonly
                />
              </div>

              <div class="input-field col s12">
                <span>Referred To</span>

                <p v-for="item in submit_departments">
                  <label>
                    <input
                      name="submit_department_id"
                      v-model="patient_submit_department_id"
                      type="radio"
                      :value="item.id"
                    />
                    <span>{{ item.eng_name }}</span>
                  </label>
                </p>

                <div v-show="patient_submit_department_id == 3">
                  <select v-model="psd_description">
                    <option value disabled selected>Choose your option</option>
                    <option v-for="item in sccs" v-bind:value="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div v-show="patient_submit_department_id == 5">
                  <span>Other</span>
                  <input type="text" v-model="psd_other" />
                </div>
              </div>

              <div class="input-field col s12">
                <span>Case Founded Activity</span>

                <p v-for="item in foundplaces">
                  <label>
                    <input
                      name="patient_found_place_id"
                      type="radio"
                      v-model="patient_found_place_id"
                      :value="item.id"
                    />
                    <span>{{ item.eng_name }}</span>
                  </label>
                </p>
                <div
                  class="row"
                  v-show="
                    patient_found_place_id == 2 || patient_found_place_id == 3
                  "
                >
                  <div class="col s6">
                    <input
                      type="number"
                      min="10000"
                      max="99999"
                      v-model="pfp_description"
                    />
                  </div>
                  <div class="col s6">
                    <select v-model="sub_pfp">
                      <option value disabled selected>
                        Choose your option
                      </option>
                      <option value="1">Household</option>
                      <option value="2">Non-household</option>
                    </select>
                  </div>
                  <div class="col s6">
                    <span>Type of Index Case</span>
                    <select v-model="type_index_case">
                      <option value disabled selected>
                        Choose your option
                      </option>
                      <option value="1">Bact_Confirmed</option>
                      <option value="2">Under_5yrs</option>
                      <option value="3">PLHIV</option>
                      <option value="4">TB_DM</option>
                      <option value="5">Other</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="input-field col s12" v-show="refer_from === 7">
                <span>Referred Type</span>

                <p v-for="item in submit_types">
                  <label>
                    <input
                      name="submit_type_id"
                      type="radio"
                      v-model="patient_submit_type_id"
                      :value="item.id"
                    />
                    <span>{{ item.eng_name }}</span>
                  </label>
                </p>
              </div>
              <div class="input-field col s12">
                <span>Weight (kg)</span>
                <input v-model="weight" />
              </div>

              <div class="input-field col s12">
                <span>Risk Factor</span>

                <p v-for="item in risk_factors">
                  <label v-show="item.id == 1 || !hideRiskFactor">
                    <input
                      type="checkbox"
                      name="risk_factor"
                      :value="item.id"
                      v-model="risk_factor_ids"
                    />
                    <span>{{ item.name }}</span>
                  </label>
                </p>
              </div>
            </fragment>
            <div
              class="row center"
              v-if="buttonpass && metaValidate && moreValidate"
            >
              <button
                :disabled="patient_add_loading"
                type="button"
                @click="add"
                class="btn blue darken-3"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from "./../../../misc/server";
export default {
  name: "AddNewPatient",
  data() {
    return {
      name: "",
      address: "",
      phone: "",
      age: "",
      gender: "",
      presumptive_tb_number: 0,
      registeration_date: "",
      referral_date: "",
      volunteer_id: "",
      weight: "",
      temperature: "",
      risk_factor: "",
      checked_symptons: [],
      patient_submit_department_id: 1,
      patient_submit_type_id: 1,
      psd_description: "",
      patient_found_place_id: null,
      pfp_description: "",
      psd_other: "",
      sub_pfp: 1,
      type_index_case: null,
      refer_from: null,
      is_volunteer: false,
      is_doctor: false,
      is_township: false,
      refer_description: "",
      risk_factor_ids: [],
      hideRiskFactor: false,
      volunteer_id: "",
      non_picts_description: "",
      referral_date: "",
      covid_result: "",
      covid_date: "",
    };
  },
  mounted() {
    this.$store.dispatch("getPatientMetaData");
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },

  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },

  methods: {
    submitData() {
      var data = {
        name: this.name,
        address: this.address,
        phone: this.phone,
        age: this.age,
        gender: this.gender,
        presumptive_tb_number: this.presumptive_tb_number,
        registeration_date: this.registeration_date,
        weight: this.weight,
        temperature: this.temperature,
        risk_factor_ids: this.risk_factor_ids,
        refer_from: this.refer_from,
        receive_type: 2,
        symptons: this.checked_symptons,
        submitted_date: this.referral_date,
        patient_found_place_id: this.patient_found_place_id,
        type_index_case: this.type_index_case,
        pfp_description: this.pfp_description,
        sub_pfp: this.sub_pfp,
        volunteer_id: this.volunteer_id,
      };
      if (this.hideRiskFactor) {
        data.risk_factor_ids = [];
        data.risk_factor_ids.push(1);
      }

      data.tpt_status = 1;

      this.$store.dispatch("saveNewPatient", data);
    },
    reInitializeDatePicker() {
      var elems = document.querySelectorAll(".datepicker");
      var instances = M.Datepicker.init(elems, {
        format: "yyyy-mm-dd",
        showClearBtn: true,
      });
    },
    add() {
      getData(`/presumptive/checkName?name=${this.name}&`, (response) => {
        if (!response.success) {
          if (
            confirm(
              "Are you sure you want to save this patient into the database?Because similar name is already existed"
            )
          ) {
            this.submitData();
          }
        } else {
          this.submitData();
        }
      });
    },
  },
  watch: {
    "$store.getters.tpt_presumptive_number"(newValue) {
      // Update the presumptive_tb_number data property
      this.presumptive_tb_number = newValue;
    },
    refer_from(val) {
      if (val == 7) {
        this.is_volunteer = true;
      }
    },

    insert_id(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "Inserted",
        });

        window.location = "/tpt-fill/" + val;
      } else if (val == false) {
        this.$notify({
          group: "noti",
          type: "danger",
          text: "Presumptive code already existed",
        });
      }
    },
    risk_factor_ids(val) {
      this.hideRiskFactor = false;
      val.map((val) => {
        if (val == 1) {
          this.hideRiskFactor = true;
        }
      });
    },
  },
  components: {},
  computed: {
    volunteers() {
      return this.$store.getters.meta_volunteers;
    },
    symptons() {
      var symptons = this.$store.getters.symptons;
      symptons = symptons.filter((value) => {
        if (this.age >= 15 && value.type == 2) {
          return value;
        } else if (this.age < 15 && value.type == 1) {
          return value;
        }
      });

      if (symptons) {
        symptons = symptons.map((value) => {
          value.checked = true;
          return value;
        });
      }

      return symptons;
    },

    meta_data_loading() {
      return this.$store.getters.meta_data_loading;
    },
    patient_add_finish() {
      return this.$store.getters.patient_add_finish;
    },

    buttonpass() {
      if (
        this.name &&
        this.address &&
        this.phone &&
        this.age &&
        this.gender &&
        this.registeration_date &&
        this.presumptive_tb_number &&
        this.weight &&
        this.risk_factor_ids.length > 0 &&
        this.refer_from
      ) {
        return true;
      } else {
        return false;
      }
    },
    moreValidate() {
      if (!this.volunteer_id || !this.referral_date) {
        return false;
      }
      return true;
    },
    metaValidate() {
      const {
        referFrom,
        patientSubmitDepartmentId,
        psdDescription,
        psdOther,
        referDescription,
        volunteerId,
        referralDate,
      } = this;
      const { id: patientSubmitDepartmentIdValue } =
        this.patient_submit_department_id;

      if (referFrom !== 5) {
        if (patientSubmitDepartmentIdValue === 3 && !psdDescription) {
          return false;
        }
      } else if ([2, 3].includes(referFrom) && !referDescription) {
        return false;
      } else if (referFrom === 1 && (!volunteerId || !referralDate)) {
        return false;
      } else if (
        patientSubmitDepartmentIdValue === 5 &&
        psdOther.length === 9
      ) {
        return false;
      }

      return true;
    },
    submit_types() {
      var data = this.$store.getters.submit_types;

      return data;
    },
    foundplaces() {
      var data = this.$store.getters.foundplaces;

      return data;
    },
    sccs() {
      return this.$store.getters.patient_sccs;
    },
    submit_departments() {
      var data = this.$store.getters.submit_departments;
      return data;
    },
    risk_factors() {
      var data = this.$store.getters.risk_factors;
      return data;
    },
    patient_add_loading() {
      return this.$store.getters.patient_add_loading;
    },
    presumptive_number() {
      return this.$store.getters.tpt_presumptive_number;
    },
    insert_id() {
      return this.$store.getters.patient_insert_id;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.fill {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

.fill .card {
  padding: 20px;
  background: var(--card-background-color);
}
</style>
