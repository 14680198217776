<template>
  <div class="row center" v-if="!loading">
    <a class='dropdown-trigger btn blue btn-large darken-3' href='#'   data-target='filterdropdown'>Status Filter</a>

  <!-- Dropdown Structure -->
  <ul id='filterdropdown' class='dropdown-content'>
    
    <li><a @click="filter(1)">Waiting <span class="right blue-text">({{ counts[0] }})</span></a></li>
    <li><a @click="filter(2)">Accept  <span class="right blue-text">({{ counts[1] }})</span></a></li>
    <li><a @click="filter(3)">Submitted <span class="right blue-text">({{ counts[2] }})</span></a></li>
    <li><a @click="filter(4)">Cannot Connect <span class="right blue-text">({{ counts[3] }})</span></a></li>
    <li><a @click="filter(5)">Fake <span class="right blue-text">({{ counts[4] }})</span></a></li>
    <li><a @click="filter(6)">Wrong Township <span class="right blue-text">({{ counts[5] }})</span></a></li>
  </ul>
  </div>
</template>
<script>

export default {
  name: 'FilterBox',
  
  data () {
    return {
     
    }
  },
  mounted()
  {
   var elems = document.querySelectorAll('.dropdown-trigger');
    var instances = M.Dropdown.init(elems, {});
  },
  updated()
  {
    var elems = document.querySelectorAll('.dropdown-trigger');
    var instances = M.Dropdown.init(elems, {});
  },
  methods: {
      filter(id)
      {
           
            var params=this.$route.query;
            params=Object.assign({}, this.$route.query, { status: id });
          //  params.status=id;
            
          //   this.$router.push({path:"checkings",query:params});
          this.$router.push({ path:"checkings",query:params});
      }
  },
  computed: 
  {
      counts()
      {
          
          return this.$store.getters.checking_status_counts;
      },
      loading()
      {
          return this.$store.getters.loading;
      },
  }

  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #filterdropdown
    {
        top:70px !important;
        width:350px !important;
    }

 
</style>
