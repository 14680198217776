<template>
  <fragment>
    <tr v-for="(item, index) in data" :key="index">
      <th class="fixed_header" :class="{ 'bg-colored': item?.tb_conclusion?.tb_status == 4 }"> 
        <presumptive-code v-bind:data="item"></presumptive-code>
      </th>
      <td>{{ index + 1 }}</td>
      <td>{{ item.name }}</td>

      <td>
        <router-link
          :to="{ name: 'PatientFill', params: { id: item.id } }"
          class="btn blue darken-3"
          >Fill</router-link
        >
      </td>
      <personal-info-body v-bind:data="item"></personal-info-body>
      <sputum-body v-bind:data="item"></sputum-body>
      <chest-xray v-bind:data="item"></chest-xray>
      <gene-xpert v-bind:data="item"></gene-xpert>
      <tuber-culin-skin v-bind:data="item"></tuber-culin-skin>
      <fnac-body v-bind:data="item"></fnac-body>
      <sputum-culture v-bind:data="item"></sputum-culture>
      <drug-suscept v-bind:data="item"></drug-suscept>
      <tb-conclusion v-bind:data="item"></tb-conclusion>
    </tr>
  </fragment>
</template>

<script>
import PersonalInfoBody from "@/components/Patient/TableData/PersonalInfo/Body.vue";
import SputumBody from "@/components/Patient/TableData/Sputum/Body.vue";
import ChestXrayBody from "@/components/Patient/TableData/ChestXRay/Body.vue";
import GeneXpertBody from "@/components/Patient/TableData/GeneXpert/Body.vue";
import TuberCulinSkinBody from "@/components/Patient/TableData/TuberCulinSkin/Body.vue";
import FNACBody from "@/components/Patient/TableData/FNAC/Body.vue";
import SputumCultureBody from "@/components/Patient/TableData/SputumCulture/Body.vue";
import DrugSusceptbilityBody from "@/components/Patient/TableData/DrugSusceptbility/Body.vue";
import TBConclusionBody from "@/components/Patient/TableData/TBConclusion/Body.vue";
import PresumptiveCode from "@/components/Patient/TableData/PresumptiveCode.vue";
export default {
  name: "TableBody",
  props: ["data"],
  data() {
    return {};
  },
  components: {
    "personal-info-body": PersonalInfoBody,
    "sputum-body": SputumBody,
    "chest-xray": ChestXrayBody,
    "gene-xpert": GeneXpertBody,
    "tuber-culin-skin": TuberCulinSkinBody,
    "fnac-body": FNACBody,
    "sputum-culture": SputumCultureBody,
    "drug-suscept": DrugSusceptbilityBody,
    "tb-conclusion": TBConclusionBody,
    "presumptive-code": PresumptiveCode,
  },
  methods: {
    goToFill(item) {
      this.$router.push({ name: "PatientFill", params: { id: item }  });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.patient_list .fixed-table-container thead .fixed_header {
  left: 0;
  z-index: 3;
  color: white;
}

.patient_list .fixed-table-container tbody .fixed_header {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  background: var(--card-background-color);
  color: var(--card-text-color);
  border-right: 1px solid #ccc;
  padding-left: 50px;
  padding-right: 50px;
}

.patient_list .fixed-table-container tbody td,
tbody th {
  border: 1px solid black;
  min-width: 150px !important;
  text-align: center;
  height: 90px;
}
.bg-colored {
  background-color: #657aff !important;
}
</style>
