<template>
  <div class="row mt-5">
    <div class=" col s12">
      <label>Note</label>
      <textarea
        style="height:100px;"
        id="textarea1"
        class="materialize-textarea"
        v-model="remark"
      ></textarea>
    </div>

    <div class="card-footer center">
      <br />
      <button
        :disabled="action_loading"
        @click="saveData"
        class="btn blue darken-3"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { sendData } from "../../../misc/server";
export default {
  props: ["patient"],
  data() {
    return {
      remark: "",
      action_loading: false,
    };
  },
  mounted() {
    if (this.patient.visit_date_remark) {
      this.remark = this.patient.visit_date_remark;
    }
  },

  methods: {
    saveData() {
      if (this.remark) {
        this.action_loading = true;
        sendData(
          "/dots/patient/updateRemark",
          {
            remark: this.remark,
            id: this.$route.params.id,
          },
          (response) => {
            this.action_loading = false;
            console.log(response);
          }
        );
      }
    },
  },
  computed: {},
  watch: {},
  components: {},
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 20px;
}
</style>
