<template>
  <div class="container" style="margin-top:20px;">
    <back-container :link="`/patient/dots/edit/${$route.params.id}`"></back-container>
    <div v-if="loading">
      <pre-loader></pre-loader>
    </div>
    <div class="row" v-else>
      <div class="card card-content" v-show="patient.finish!=1 && patient.finish!=2">
        <div class="row">
          <div class="col m6">
            <span>Current Volunteer</span>
            <select v-model="data.volunteer_id" disabled>
              <option
                v-for="item in volunteer"
                v-bind:key="item.id"
                :value="item.id"
              >{{ item.code }} ({{ item.name }})</option>
            </select>
          </div>
          <div class="col m6">
            <span>Current DOTS APP Status</span>
            <select v-model="data.dots_app_status" disabled>
              <option value="0">No</option>
              <option value="1" v-if="data.is_new==0">Yes</option>
            </select>
          </div>
          <div class="col m6" style="margin-top:15px;">
            <span>New Volunteer</span>
            <select v-model="volunteer_id">
              <option
                v-for="item in volunteer"
                v-bind:key="item.id"
                :value="item.id"
              >{{ item.code }} ({{ item.name }})</option>
            </select>
          </div>
          <div class="col m6" style="margin-top:15px;">
            <span>New DOTS APP Status</span>
            <select v-model="dots_app_status">
              <option value="0">No</option>
              <option value="1" v-if="data.is_new==0">Yes</option>
            </select>
          </div>
          <div class="col m6" style="margin-top:15px;">
            <span>Date</span>
            <input v-model.lazy="date" type="text" class="datepicker" />
          </div>
        </div>

        <div class="row center">
          <br />
          <button :disabled="action_loading" @click="submit" class="btn blue darken-3">Change</button>
        </div>
      </div>
      <activity></activity>
    </div>
  </div>
</template>

<script>
import Activiy from "./Activity.vue";
export default {
  data() {
    return {
      volunteer_id: "",
      dots_app_status: "",
      date: ""
    };
  },
  mounted() {
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true
    });
    this.$store
      .dispatch("getDOTSPatientEditData", {
        id: this.$route.params.id
      })
      .then(() => {
        this.$store
          .dispatch("getVolunteer", {
            all: true
          })
          .then(() => {
            this.$store.dispatch("getDOTSPatientEditData", {
              id: this.$route.params.id
            });
          });
      });

    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
  },
  updated() {
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true
    });
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
  },
  computed: {
    loading() {
      return this.$store.getters.dots_initial_data_loading;
    },
    data() {
      return this.$store.getters.dots_initial_data;
    },
    volunteer() {
      return this.$store.getters.volunteers;
    },
    action_finish() {
      return this.$store.getters.dots_action_finish;
    },
    action_loading() {
      return this.$store.getters.dots_action_loading;
    },
    patient() {
      return this.$store.getters.dots_initial_data;
    }
  },
  methods: {
    submit() {
      if (this.volunteer_id && this.dots_app_status) {
        this.$store.dispatch("changeDOTSVolunteer", {
          volunteer_id: this.volunteer_id,
          dots_app_status: this.dots_app_status,
          patient_id: this.$route.params.id,
          date: this.date
        });
      } else {
        alert("Some fields are required");
      }
    }
  },
  watch: {
    action_finish(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "Updated"
        });
        location.reload();
      }
    }
  },
  components: {
    activity: Activiy
  }
};
</script>

<style>
</style>
