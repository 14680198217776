 <template>
  <div class="row fill">
   <div>
        <br/>
        <back-container link="/checkings/7/confirmed"></back-container>
    </div>
    <div class="col m6 offset-m3">
      
      <div class="card card-content z-depth-3" v-if="!meta_data_loading">
        <h6>Presumptive Information</h6>
        <form>
          <div class="row">
            <div class="input-field col s12">
              <span>Name</span>
              <input type="text" v-model="name" />
            </div>
            <div class="input-field col s12">
              <span>Address</span>
              <input type="text" v-model="address" />
            </div>
            <div class="input-field col s12">
              <span>Phone</span>
              <input type="text" v-model="phone" />
            </div>
            <div class="input-field col s12">
              <span>Age</span>
              <input type="number" v-model="age" />
            </div>
            <div class="input-field col s12">
              <span>Gender</span>
              <p>
                <label>
                  <input
                    name="group1"
                    type="radio"
                    value="1"
                    :checked="gender==1"
                    @click="gender=1"
                  />
                  <span>ကၽ်ား</span>
                </label>
              </p>
              <p>
                <label>
                  <input
                    name="group1"
                    type="radio"
                    value="2"
                    :checked="gender==2"
                    @click="gender=2"
                  />
                  <span>မ</span>
                </label>
              </p>
            </div>

            <div class="input-field col s12">
              <span>Volunteers</span>
              <select v-model="volunteer_id">
                <option value disabled selected>Choose your option</option>
                <option v-for="item in volunteers" v-bind:value="item.id">{{ item.code }} ({{ item.name }})</option>
              </select>
            </div>

            <div class="input-field col s12">
              <span>Referred To</span>

              <p v-for="item in submit_departments">
                <label>
                  <input
                    name="submit_department_id"
                    v-model=" patient_submit_department_id"
                    type="radio"
                    :value="item.id"
                  />
                  <span>{{ item.eng_name }}</span>
                </label>
              </p>

              <div v-show="patient_submit_department_id==3">
                <select v-model="psd_description">
                  <option value disabled selected>Choose your option</option>
                  <option v-for="item in sccs" v-bind:value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="input-field col s12">
              <span>Case Founded Activity</span>

              <p v-for="item in foundplaces">
                <label>
                  <input
                    name="patient_found_place_id"
                    type="radio"
                    v-model="patient_found_place_id"
                    :value="item.id"
                  />
                  <span>{{ item.eng_name }}</span>
                </label>
              </p>
              <div class="row" v-show="patient_found_place_id==2 || patient_found_place_id==3">
                <div class="col s6">
                  <input type="number" min="10000" max="99999" v-model="pfp_description" />
                </div>
                <div class="col s6">
                  <select v-model="sub_pfp">
                    <option value disabled selected>Choose your option</option>
                    <option value="1">House Hold</option>
                    <option value="2">Non-household</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="input-field col s12">
              <span>Referred Type</span>

              <p v-for="item in submit_types">
                <label>
                  <input
                    name="submit_type_id"
                    type="radio"
                    v-model="patient_submit_type_id"
                    :value="item.id"
                  />
                  <span>{{ item.eng_name }}</span>
                </label>
              </p>
            </div>
            <div class="input-field col s12" v-show="age">
              <span>Symptoms</span>

              <p v-for="item in symptons">
                <label>
                  <input
                    type="checkbox"
                    name="symptons"
                    :value="item.id"
                    v-model="checked_symptons"
                  />
                  <span>{{ item.eng_name }}</span>
                </label>
              </p>
            </div>
             <div class="input-field col s12">
              <span>Referral Date</span>
              <input type="text" v-model.lazy="submitted_date" class="datepicker" readonly />
            </div>

            <div class="row center" v-if="buttonpass && meta_validate">
              <button
                :disabled="patient_add_loading"
                type="button"
                @click="add"
                class="btn blue darken-3"
              >Add</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import BackContainer from '@/components/ReUse/BackContainer.vue';
export default {
  name: "AddNewPatient",
  data() {
    return {
      name: "",
      address: "",
      phone: "",
      age: "",
      gender: 1,
      checked_symptons: [],
      patient_submit_department_id: 1,
      patient_submit_type_id: 1,
      psd_description: "",
      patient_found_place_id: 1,
      pfp_description: "",
      sub_pfp: 1,
      volunteer_id: "",
      submitted_date:"",
    };
  },
  mounted() {
    this.$store.dispatch("getPatientMetaData");
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn:true,
    });
  },

  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn:true,
    });
  },

  methods: {
    add() {
      var data = {
        name: this.name,
        address: this.address,
        phone: this.phone,
        age: this.age,
        gender: this.gender,
        submitted_date:this.submitted_date,
        symptons: this.checked_symptons
      };

      data.patient_submit_department_id = this.patient_submit_department_id;
      data.patient_found_place_id = this.patient_found_place_id;
      data.patient_submit_type_id = this.patient_submit_type_id;
      data.pfp_description = null;
      data.psd_description = null;
      if (this.patient_submit_department_id == 3) {
        data.psd_description = this.psd_description;
      }

      if (
        this.patient_found_place_id == 2 ||
        this.patient_found_place_id == 3
      ) {
        data.pfp_description = this.pfp_description;
        data.sub_pfp = this.sub_pfp;
      }

      data.volunteer_id = this.volunteer_id;
      this.$store.dispatch("saveReferralPatient", data);

    }
  },
  watch: {
    patient_add_finish(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "Inserted"
        });
        location.reload();
      }
    }
  },
  components: {
    "back-container":BackContainer,
  },
  computed: {
    volunteers() {
      return this.$store.getters.meta_volunteers;
    },
    symptons() {
      var symptons = this.$store.getters.symptons;
      if (symptons) {
        symptons = symptons.map(value => {
          value.checked = true;
          return value;
        });
      }

      return symptons.slice().sort((a, b) => a.serial - b.serial);
    },

    meta_data_loading() {
      return this.$store.getters.meta_data_loading;
    },
    patient_add_finish() {
      return this.$store.getters.patient_add_finish;
    },

    buttonpass() {
      if (
        this.name &&
        this.address &&
        this.phone &&
        this.age &&
        this.gender &&
        this.volunteer_id && this.checked_symptons.length>0
        && this.submitted_date
      ) {
        return true;
      } else {
        return false;
      }
    },
    meta_validate() {
      var value = true;

      if (
        this.patient_submit_department_id == 3 &&
        this.psd_description.length == 0
      ) {
        value = false;
      } else if (
        this.patient_found_place_id == 2 &&
        this.pfp_description.length < 5
      ) {
        value = false;
      } else if (!this.volunteer_id) {
        value = false;
      } else {
        value = true;
      }

      return value;
    },
    submit_types() {
      var data = this.$store.getters.submit_types;

      return data;
    },
    foundplaces() {
      var data = this.$store.getters.foundplaces;

      return data;
    },
    sccs() {
      return this.$store.getters.patient_sccs;
    },
    submit_departments() {
      var data = this.$store.getters.submit_departments;
      return data;
    },

    patient_add_loading() {
      return this.$store.getters.patient_add_loading;
    },

    patient_add_finish() {
      return this.$store.getters.patient_add_finish;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fill {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

.fill .card {
  padding: 20px;
}
</style>
