<template>
  <div class="EachActivity">
    <div>
      <div class="row">
        <div class="col s3">
          <back-container
            v-bind:link="
              '/activities/' + $route.params.year + '/' + $route.params.month
            "
          ></back-container>
          <button class="btn green daken-2" @click="excelExport">
            Export as excel
          </button>
        </div>
        <div class="col s6"></div>
        <div class="col s3">
          <div class="right">
            <button @click="reloadPage" class="btn blue darken-1">
              Reload
            </button>
            <button
              :disabled="current_sort == 1"
              @click="sortData(1)"
              class="btn yellow darken-3 black-text"
            >
              Asc
            </button>
            <button
              :disabled="current_sort == 2"
              @click="sortData(2)"
              class="btn yellow darken-3 black-text"
            >
              Desc
            </button>
            <button
              v-if="isLarger"
              @click="btnLinkAddNew"
              class="btn blue darken-1"
            >
              Add New
            </button>
          </div>
        </div>
      </div>
      <div v-if="loading" class="center">
        <loading></loading>
      </div>
      <div v-else>
        <filter-box></filter-box>
        <div class="table-container">
          <div class="center">
            <h6>
              Month : {{ $route.params.month }} - Year :
              {{ $route.params.year }}
            </h6>
          </div>
          <div class="center orange  warning-box" v-if="!isValidDate">
            <h6 class="white-text">
              Your selected date is later than current date. Please make sure.
            </h6>
          </div>
          <table
            class="app-table table"
            v-if="activity_headers && activity_headers.length > 0"
          >
            <thead>
              <tr>
                <th>Actions</th>
                <th>No</th>
                <th v-for="item in activity_headers" v-bind:key="item.id">
                  {{ item.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in activity_values" v-bind:key="item.id">
                <td>
                  <a
                    v-show="!add_loading"
                    class="red-text darken-1"
                    style="cursor:pointer;"
                    @click="deleteActivity(item.id)"
                    ><i class="material-icons">delete</i></a
                  >
                  <a v-show="add_loading" class="grey-text darken-1"
                    ><i class="material-icons">delete</i></a
                  >
                  <a
                    class="green-text darken-1"
                    style="cursor:pointer;"
                    @click="editActivity(item.id)"
                    ><i class="material-icons">edit</i></a
                  >
                </td>
                <td v-if="current_sort == 1">{{ index + 1 }}</td>
                <td v-if="current_sort == 2">
                  {{ activity_values.length - index }}
                </td>
                <td v-for="vitem in item.columns" v-bind:key="vitem.id">
                  <span
                    v-if="vitem.type != 6 && vitem.type != 7 && vitem.type != 3"
                    >{{ vitem.pivot.value }}</span
                  >
                  <span v-if="vitem.type == 3">{{ vitem.pivot.value }}</span>
                  <span v-if="vitem.type == 6">{{
                    getPresumptiveCode(vitem.pivot.value)
                  }}</span>
                  <span v-if="vitem.type == 7">{{
                    getMultipleVaue(vitem.pivot.multiple_values)
                  }}</span>
                  <!-- <span v-if="vitem.type == ">{{ vitem.pivot.value }}</span> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Partials/Loading.vue";
import BackContainer from "@/components/ReUse/BackContainer.vue";
import { generatePresumptiveCode } from "./../../misc/presumptiveCode.js";
import FilterBox from "@/components/Activities/FilterBox.vue";

export default {
  name: "EachActivity",
  data() {
    return {
      form: {
        data_id: [],
      },
      current_sort: 1,
    };
  },
  mounted() {
    this.getData();
  },
  updated() {},
  components: {
    loading: Loading,
    "back-container": BackContainer,
    "filter-box": FilterBox,
  },
  watch: {
    activity_add_finish(val) {
      if (val) {
        location.reload();
      }
    },
  },
  computed: {
    activity_headers() {
      return this.$store.getters.activity_headers;
    },
    loading() {
      return this.$store.getters.activities_loading;
    },
    activity_values() {
      return this.$store.getters.activity_values;
    },
    activity_add_finish() {
      return this.$store.getters.activity_add_finish;
    },
    add_loading() {
      return this.$store.getters.activity_add_loading;
    },
    isValidDate() {
      var date = new Date();
      var istrue = true;
      if (this.$route.params.year >= date.getFullYear()) {
        if (this.$route.params.month >= date.getMonth() + 1) {
          istrue = true;
        } else {
          istrue = false;
        }
      } else {
        istrue = false;
      }

      return istrue;
    },
    isLarger() {
      var date = new Date();
      var istrue = true;
      if (this.$route.params.year <= date.getFullYear()) {
        if (this.$route.params.month <= date.getMonth() + 1) {
          istrue = true;
        } else if (this.$route.params.year < date.getFullYear()) {
          istrue = true;
        } else {
          istrue = false;
        }
      } else {
        istrue = false;
      }

      return istrue;
    },
    activity_township() {
      return this.$store.getters.activity_township;
    },
  },
  methods: {
    btnLinkAddNew() {
      var { year, month, id } = this.$route.params;
      this.$router.push({
        name: "NewActivity",
        params: { id: id, year: year, month: month },
      });
    },
    deleteActivity(id) {
      if (confirm("Are you sure you want to delete this activity?")) {
        this.$store.dispatch("deleteActivity", { id: id });
      } else {
        // If cancelled, log a message (optional)
        console.log("Deletion cancelled.");
      }
    },
    // deleteActivity(id) {
    //   this.$store.dispatch("deleteActivity", { id: id });
    // },
    editActivity(id) {
      this.$router.push({ name: "EditActivity", params: { id: id } });
    },
    getData() {
      var data = {
        id: this.$route.params.id,
        month: this.$route.params.month,
        year: this.$route.params.year,
      };

      this.$store.dispatch("getEachActivity", data);
    },
    getPresumptiveCode(data) {
      return generatePresumptiveCode(
        data,
        this.$route.params.year,
        this.activity_township
      );
    },
    getMultipleVaue(data) {
      var returnString = "";
      for (var i in data) {
        returnString += data[i].option_value.name + ", ";
      }

      return returnString;
    },

    sortData(sortType) {
      this.current_sort = sortType;
      this.activity_values.reverse();
    },
    excelExport() {
      var data = {
        id: this.$route.params.id,
        month: this.$route.params.month,
        year: this.$route.params.year,
      };

      this.$store.dispatch("exportActivityReportExcel", data);
    },
    reloadPage() {
      location.reload();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.EachActivity {
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
.EachActivity .table-container {
  width: 100%;
  overflow-x: auto;
  padding: 20px;
}

.EachActivity table {
  white-space: nowrap;
  max-width: 100% !important;
}

.EachActivity table td {
  min-width: 140px;
  max-width: 140px;
  overflow: auto;

  padding-left: 10px;
  padding-right: 10px;
}

.EachActivity .warning-box {
  padding: 10px;
  margin-bottom: 20px;
}
</style>
