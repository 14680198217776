export function generatePresumptiveCode(data,year,township)
{
            var code=data;
            if (code)
            {
                if (code<10)
                {
                    code='000'+code;
                }

                else if (code<100)
                {
                    code='00'+code;
                }
                else if (code<1000)
                {
                    code='0'+code;
                }

                
                year=year.trim();
                
                
    
                code=township.short_name+"/"+code+"/"+year[2]+year[3];
            }
            
            return code;
}