<template>
  <div class="row fill">
    <div class="row">
      <back-container :link="'/patients'"></back-container>
    </div>
    <div class="col m6 offset-m3">
      <div class="card card-content z-depth-3" v-if="!meta_data_loading">
        <h6>Presumptive Information</h6>
        <form>
          <div class="row">
            <div class="input-field col s12">
              <span
                >Presumptive TB Number (Latest presumptive_number :
                {{ presumptive_number }} )</span
              >
              <input v-model="presumptive_tb_number" />
            </div>
            <div class="input-field col s12">
              <span>Registeration Date</span>
              <input
                type="text"
                v-model.lazy="registeration_date"
                class="datepicker"
                @change="reInitializeDatePicker"
                readonly
              />
            </div>
            <div class="input-field col s12">
              <span>Name</span>
              <input type="text" v-model="name" />
            </div>
            <div class="input-field col s12">
              <span>Age</span>
              <input v-model="age" />
            </div>
            <div class="input-field col s12">
              <span>Gender</span>
              <p>
                <label>
                  <input
                    name="group1"
                    type="radio"
                    value="1"
                    :checked="gender == 1"
                    @click="gender = 1"
                  />
                  <span>ကျား</span>
                </label>
              </p>
              <p>
                <label>
                  <input
                    name="group1"
                    type="radio"
                    value="2"
                    :checked="gender == 2"
                    @click="gender = 2"
                  />
                  <span>မ</span>
                </label>
              </p>
            </div>
            <div class="input-field col s12">
              <span>Address</span>
              <input type="text" v-model="address" />
            </div>
            <div class="input-field col s12">
              <span>Phone</span>
              <input type="text" v-model="phone" />
            </div>

            <div class="input-field col s12">
              <span>Refer From</span>
              <select v-model="refer_from">
                <option value disabled selected>Choose your option</option>
                <option value="1">PICTS volunteer</option>
                <option value="2">PICTS GP</option>
                <!-- <option value="3">PICTS pharmacy</option>
                <option value="4">Others</option> -->

                <option value="5">Township</option>
                <option value="6">Non-PICTS</option>
              </select>
            </div>
            <div class="input-field col s12" v-if="refer_from == 6">
              <span>Non PICTS Code</span>
              <input type="number" v-model="non_picts_code" />
            </div>
            <fragment v-if="refer_from != 5">
              <div class="input-field col s12" v-show="is_volunteer">
                <span>Volunteers</span>
                <select v-model="volunteer_id">
                  <option value disabled selected>Choose your option</option>
                  <option v-for="item in volunteers" v-bind:value="item.id">
                    {{ item.code }} ({{ item.name }})
                  </option>
                </select>
              </div>
              <div class="input-field col s12" v-show="is_volunteer">
                <span>Referral Date</span>
                <input
                  type="text"
                  v-model.lazy="referral_date"
                  class="datepicker"
                  readonly
                />
              </div>

              <div class="col s12" v-show="is_doctor">
                <span>Name</span>
                <input type="text" v-model="refer_description" />
              </div>

              <div class="col s12" v-show="refer_from == 6">
                <span>Non-PICTS</span>
                <select v-model="non_picts_description">
                  <option value disabled selected>Choose your option</option>
                  <option value="HS">HS</option>
                  <option value="C">C</option>
                  <option value="PP">PP</option>
                  <option value="UnionDR">UnionDR</option>
                  <option value="FC">FC</option>
                  <option value="IHC">IHC</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div class="input-field col s12" v-show="refer_from !== 5">
                <span>Referred To</span>

                <p v-for="item in submit_departments">
                  <label>
                    <input
                      name="submit_department_id"
                      v-model="patient_submit_department_id"
                      type="radio"
                      :value="item.id"
                    />
                    <span>{{ item.eng_name }}</span>
                  </label>
                </p>

                <div v-show="patient_submit_department_id == 3">
                  <select v-model="psd_description">
                    <option value disabled selected>Choose your option</option>
                    <option v-for="item in sccs" v-bind:value="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div v-show="patient_submit_department_id == 5">
                  <span>Other</span>
                  <input type="text" v-model="psd_other" />
                </div>
              </div>

              <div class="input-field col s12" v-show="refer_from !== 5">
                <span>Case Founded Activity</span>

                <p v-for="item in foundplaces">
                  <label>
                    <input
                      name="patient_found_place_id"
                      type="radio"
                      v-model="patient_found_place_id"
                      :value="item.id"
                    />
                    <span>{{ item.eng_name }}</span>
                  </label>
                </p>
                <div
                  class="row"
                  v-show="
                    patient_found_place_id == 2 || patient_found_place_id == 3
                  "
                >
                  <div class="col s6">
                    <input
                      type="number"
                      min="10000"
                      max="99999"
                      v-model="pfp_description"
                    />
                  </div>
                  <div class="col s6">
                    <select v-model="sub_pfp">
                      <option value disabled selected>
                        Choose your option
                      </option>
                      <option value="1">Household</option>
                      <option value="2">Non-household</option>
                      <option value="3">Township</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="input-field col s12" v-show="refer_from !== 5">
                <span>Referred Type</span>

                <p v-for="item in submit_types">
                  <label>
                    <input
                      name="submit_type_id"
                      type="radio"
                      v-model="patient_submit_type_id"
                      :value="item.id"
                    />
                    <span>{{ item.eng_name }}</span>
                  </label>
                </p>
              </div>
              <div class="input-field col s12">
                <span>Weight (kg)</span>
                <input v-model="weight" />
              </div>
              <!-- <div class="input-field col s12">
                <span>Temperature (Fahrenheit)</span>
                <input type="number" v-model="temperature" />
              </div> -->
              <div class="input-field col s12" v-show="age">
                <span>Symptoms</span>

                <p v-for="item in symptons">
                  <label>
                    <input
                      type="checkbox"
                      name="symptons"
                      :value="item.id"
                      v-model="checked_symptons"
                    />
                    <span>{{ item.eng_name }}</span>
                  </label>
                </p>
              </div>

              <div class="input-field col s12">
                <span>Risk Factor</span>

                <p v-for="item in risk_factors">
                  <label v-show="item.id == 1 || !hideRiskFactor">
                    <input
                      type="checkbox"
                      name="risk_factor"
                      :value="item.id"
                      v-model="risk_factor_ids"
                    />
                    <span>{{ item.name }}</span>
                  </label>
                </p>
              </div>
            </fragment>
            <!-- <div class="row">
             <div class="input-field col s12">
               <span>Covid Testing</span>
               <select v-model="covid_status">
                 <option value selected>Choose your option</option>
                 <option value="1">Not Done</option>
                 <option value="2">Done</option>
               </select>
             </div>
             <div class="input-field col s12" v-show="covid_status == 2">
               <span>Date</span>
               <input
                 type="text"
                 v-model.lazy="covid_date"
                 class="datepicker"
                 readonly
               />
             </div>
             <div class="input-field col s12" v-show="covid_status == 2">
               <span>Coivd Result</span>
               <select v-model="covid_result">
                 <option value selected>Choose your option</option>
                 <option value="1">Negative</option>
                 <option value="2">Positive</option>
               </select>
             </div>
           </div> -->
            <div
              class="row center"
              v-if="buttonpass && meta_validate && moreValidate"
            >
              <button
                :disabled="patient_add_loading"
                type="button"
                @click="add"
                class="btn blue darken-3"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from "./../../../misc/server";
export default {
  name: "AddNewPatient",
  data() {
    return {
      name: "",
      address: "",
      phone: "",
      age: "",
      gender: "",
      presumptive_tb_number: 0,
      non_picts_code: "",
      registeration_date: "",
      referral_date: "",
      volunteer_id: "",
      temperature: "",
      risk_factor: "",
      checked_symptons: [],
      patient_submit_department_id: 1,
      patient_submit_type_id: 1,
      psd_description: "",
      patient_found_place_id: 1,
      pfp_description: "",
      psd_other: "",
      sub_pfp: 1,
      refer_from: "",
      is_volunteer: false,
      is_doctor: false,
      is_township: false,
      refer_description: "",
      risk_factor_ids: [],
      hideRiskFactor: false,
      volunteer_id: "",
      non_picts_description: "",
      referral_date: "",
      covid_result: "",
      covid_date: "",
    };
  },
  mounted() {
    this.$store.dispatch("getPatientMetaData");
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },

  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },

  methods: {
    submitData() {
      var data = {
        name: this.name,
        address: this.address,
        phone: this.phone,
        age: this.age,
        gender: this.gender,
        presumptive_tb_number: this.presumptive_tb_number,
        registeration_date: this.registeration_date,
        temperature: this.temperature,
        risk_factor_ids: this.risk_factor_ids,
        refer_from: this.refer_from,
        receive_type: 2,
        symptons: this.checked_symptons,
        submitted_date: this.referral_date,
        weight: this.weight,
      };

      if (this.hideRiskFactor) {
        data.risk_factor_ids = [];
        data.risk_factor_ids.push(1);
      }
      if (data.refer_from !== 5) {
        data.patient_submit_department_id = this.patient_submit_department_id;
        data.patient_found_place_id = this.patient_found_place_id;
        data.patient_submit_type_id = this.patient_submit_type_id;
        data.pfp_description = null;
        data.psd_description = null;
        if (this.patient_submit_department_id == 3) {
          data.psd_description = this.psd_description;
        }
        if (this.patient_submit_department_id == 5) {
          data.psd_other = this.psd_other;
        }
        if (
          this.patient_found_place_id == 2 ||
          this.patient_found_place_id == 3
        ) {
          data.pfp_description = this.pfp_description;
          data.sub_pfp = this.sub_pfp;
        }
        data.volunteer_id = this.volunteer_id;
      } else if (data.refer_from == 2 || data.refer_from == 3) {
        data.refer_description = this.refer_description;
      }
      if (data.refer_from == 6) {
        data.non_picts_description = this.non_picts_description;
        data.presumptive_tb_number = null;
        data.non_picts_code = this.non_picts_code;
      }
      if (data.refer_from == 2 || data.refer_from == 3) {
        data.refer_description = this.refer_description;
      }
      this.$store.dispatch("saveNewPatient", data);
    },
    reInitializeDatePicker() {
      var elems = document.querySelectorAll(".datepicker");
      var instances = M.Datepicker.init(elems, {
        format: "yyyy-mm-dd",
        showClearBtn: true,
      });
    },
    add() {
      getData(`/presumptive/checkName?name=${this.name}&`, (response) => {
        if (!response.success) {
          if (
            confirm(
              "Are you sure you want to save this patient into the database?Because similar name is already existed"
            )
          ) {
            this.submitData();
          }
        } else {
          this.submitData();
        }
      });
    },
  },
  watch: {
    "$store.getters.presumptive_number"(newValue) {
      // Update the presumptive_tb_number data property
      this.presumptive_tb_number = newValue;
    },
    refer_from(val) {
      if (val == 1 || val == 7) {
        this.is_doctor = false;
        this.is_township = false;
        this.is_volunteer = true;
      } else if (val == 2 || val == 3) {
        this.is_volunteer = false;
        this.is_township = false;
        this.is_doctor = true;
      } else if (val == 5) {
        this.is_volunteer = false;
        this.is_doctor = false;
        this.is_township = true;
      } else {
        this.is_volunteer = false;
        this.is_doctor = false;
        this.is_township = false;
      }
    },

    insert_id(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "Inserted",
        });

        window.location = "/patient/fill/" + val;
      } else if (val == false) {
        this.$notify({
          group: "noti",
          type: "danger",
          text: "Presumptive code already existed",
        });
      }
    },
    risk_factor_ids(val) {
      this.hideRiskFactor = false;
      val.map((val) => {
        if (val == 1) {
          this.hideRiskFactor = true;
        }
      });
    },
  },
  components: {},
  computed: {
    volunteers() {
      return this.$store.getters.meta_volunteers;
    },
    symptons() {
      var symptons = this.$store.getters.symptons;
      if (symptons) {
        symptons = symptons.map((value) => {
          value.checked = true;
          return value;
        });
      }

      return symptons.slice().sort((a, b) => a.serial - b.serial);
    },

    meta_data_loading() {
      return this.$store.getters.meta_data_loading;
    },
    patient_add_finish() {
      return this.$store.getters.patient_add_finish;
    },

    buttonpass() {
      if (
        this.name &&
        this.address &&
        this.phone &&
        this.age &&
        this.gender &&
        this.registeration_date &&
        (this.is_township ||
          (this.presumptive_tb_number &&
            this.presumptive_tb_number.length < 5 &&
            this.checked_symptons.length > 0 &&
            this.risk_factor_ids.length > 0 &&
            this.refer_from))
      ) {
        return true;
      } else {
        return false;
      }
    },
    moreValidate() {
      var value = true;
      if (this.refer_from == 1 && (!this.volunteer_id || !this.referral_date)) {
        value = false;
      }
      return value;
    },
    meta_validate() {
      var value = true;
      if (this.refer_from != 5) {
        if (
          this.patient_submit_department_id == 3 &&
          this.psd_description.length == 0
        ) {
          value = false;
        } else {
          value = true;
        }
      } else if (this.refer_from == 2 || this.refer_from == 3) {
        if (this.refer_description.length == 0) {
          value = false;
        }
      } else if (
        this.refer_from == 1 &&
        (!this.volunteer_id || !this.referral_date)
      ) {
        value = false;
      } else if (
        this.patient_submit_department_id == 5 &&
        this.psd_other.length == 9
      ) {
        value = false;
      }

      return value;
    },
    submit_types() {
      var data = this.$store.getters.submit_types;

      return data;
    },
    foundplaces() {
      var data = this.$store.getters.foundplaces;

      return data;
    },
    sccs() {
      return this.$store.getters.patient_sccs;
    },
    submit_departments() {
      var data = this.$store.getters.submit_departments;
      return data;
    },
    risk_factors() {
      var data = this.$store.getters.risk_factors;
      return data;
    },
    patient_add_loading() {
      return this.$store.getters.patient_add_loading;
    },
    presumptive_number() {
      return this.$store.getters.presumptive_number;
    },
    insert_id() {
      return this.$store.getters.patient_insert_id;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.fill {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

.fill .card {
  padding: 20px;
  background: var(--card-background-color);
}
</style>
