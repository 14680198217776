<template>
  <div class="row">
    <div class="col m8 offset-m2">
      <back-container link="/patient/dots/list"></back-container>
      <div class="loading" v-if="loading">
        <pre-loader></pre-loader>
      </div>
      <div class="card card-content" v-else>
        <h6>Presumptive Information</h6>

        <div class="input-field">
          <span>Name</span>
          <input type="text" v-model="data.name" />
        </div>
        <div class="input-field">
          <span>Phone</span>
          <input type="text" v-model="data.phone" />
        </div>
        <div class="input-field">
          <span>Age</span>
          <input type="number" v-model="data.age" />
        </div>
        <div class="input-field">
          <span>Gender</span>
          <select v-model="data.gender">
            <option value="1">Male</option>
            <option value="2">Female</option>
          </select>
        </div>
        <div class="input-field">
          <span>Address</span>
          <input type="text" v-model="data.address" />
        </div>
        <div class="input-field">
          <span>Weight</span>
          <input type="number" v-model="data.weight" />
        </div>
        <div class="input-field">
          <span>Volunteer</span>
          <select v-model="data.volunteer_id">
            <option
              v-for="item in initial_data.volunteers"
              :value="item.id"
              :key="item.id"
              >{{ item.name }} ({{ item.code }})</option
            >
          </select>
        </div>
        <div class="input-field">
          <span>Township TB Code</span>
          <input type="text" v-model="data.tb_code" />
        </div>
        <div class="input-field">
          <span>Treatment Start Date</span>
          <input
            type="text"
            class="datepicker"
            v-model.lazy="data.treatment_startdate"
            readonly="true"
          />
        </div>
         <div class="input-field">
          <span>Actual Treatment Start Date</span>
          <input
            type="text"
            v-model.lazy="data.actual_treatment_startdate"
            class="datepicker"
          />
            </div>

        <div class="input-field">
          <span>DOTS Start Date</span>
          <input
            type="text"
            class="datepicker"
            v-model.lazy="data.dots_startdate"
            readonly="true"
          />
        </div>
        <div class="input-field">
          <span>Type</span>
          <select v-model="data.type">
            <option
              v-for="item in [
                { id: 1, type: 'TPT' },
                { id: 2, type: 'Other Township' },
              ]"
              :value="item.id"
              :key="item.id"
              >{{ item.type }}</option
            >
          </select>
        </div>
        <div class="input-field" v-show="data.type == 2">
          <span>Project</span>
          <select v-model="data.project_id" @change="getTownshipByProject">
            <option
              v-for="item in [
                { id: 1, name: 'PICTS 1' },
                { id: 2, name: 'PICTS 2' },
              ]"
              :value="item.id"
              :key="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
        <div class="input-field" v-show="data.project_id">
          <span>Township</span>
          <select v-model="data.other_township_id">
            <option v-for="item in townships" :value="item.id" :key="item.id">{{
              item.name
            }}</option>
          </select>
        </div>
        <div class="input-field" v-show="data.type == 2">
          <span>Union TB Code</span>
          <input type="text" v-model="data.temporary_code" />
        </div>
        <div class="input-field">
          <span>Using DOTS APP</span>
          <select v-model="data.dots_app_status">
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
        <div class="input-field center" v-if="validate">
          <button
            :disabled="action_loading"
            type="button"
            class="btn blue darken-3"
            @click="submitData"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackContainer from "@/components/ReUse/BackContainer.vue";
export default {
  data() {
    return {
      data: {
        name: "",
        age: "",
        gender: "",
        address: "",
        phone: "",
        weight: "",
        volunteer_id: "",
        tb_code: "",
        tb_year: "",
        defined_tb_type_id: "",
        treatment_reg_id: "",
        sputum_date: "",
        treatment_startdate: "",
        dots_startdate: "",
        dots_enddate: "",
        type: "",
        project_id: "",
        other_township_id: "",
        temporary_code: "",
        dots_app_status: "",
      },
    };
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
    this.$store.dispatch("getDOTSPatientAddInitData");
  },
  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  computed: {
    loading() {
      return this.$store.getters.dots_initial_data_loading;
    },
    initial_data() {
      return this.$store.getters.dots_initial_data;
    },
    action_loading() {
      return this.$store.getters.dots_action_loading;
    },
    finish() {
      return this.$store.getters.dots_action_finish;
    },
    delete_finish() {
      return this.$store.getters.dots_delete_finish;
    },
    townships() {
      return this.$store.getters.townships;
    },
    validate() {
      if (
        this.data.name &&
        this.data.age &&
        this.data.gender &&
        this.data.address &&
        this.data.phone &&
        this.data.weight &&
        this.data.treatment_startdate &&
        this.data.dots_startdate &&
        this.data.type
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    submitData() {
      var date1 = new Date(this.data.treatment_startdate);
      var date2 = new Date(this.data.dots_startdate);
      var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
      if (diffDays >= 0) {
        this.data.is_new = 1;
        this.$store.dispatch("add_dots_patient", this.data);
      } else {
        this.$notify({
          group: "noti",
          type: "danger",
          text: "DOTS Start date should be greater than Treatment Startdate",
        });
      }
    },
    getTownshipByProject() {
      this.$store.dispatch("getTownshipsByProject", this.data.project_id);
    },
  },
  watch: {
    finish(val) {
      if (val) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "Saved",
        });

        location.reload();
      }
    },
  },
  components: {
    "back-container": BackContainer,
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 30px;
  padding: 20px;
  background: var(--card-background-color) !important;
}
</style>
