<template>
    <ul
      class="collapsible"
      style="margin: 0px; margin-top: 20px; margin-bottom: 5px"
    >
      <li>
        <div class="collapsible-header">
          <i class="material-icons">settings</i>Setting
        </div>
        <div class="collapsible-body">
          <div class="row">
            <div class="input-field col m4 s12">
              <input
                placeholder="Search here by phone,code,name"
                type="text"
                class="validate"
                @keydown.enter="searchPatient"
                v-model="search"
              />
            </div>
            <div class="input-field col m1 s12 center">
              <a class="btn blue darken-3" href="/tpt-patient/new">New</a>
            </div>
            <div class="input-field col m4 s12 center">
              <router-link to="/patients/trash" class="btn green darken-3"
                >Trash</router-link
              >
  
              <button class="btn green darken-3" @click="excelExport">
                Export
              </button>
              <button class="btn blue darken-3" @click="datefilterShow = true">
                Filter
              </button>
              <button
                class="btn orange lighten-3 black-text"
                @click="$emit('sort')"
              >
                <span v-if="type == 1">DESC</span>
                <span v-else>ASC</span>
                <i class="material-icons right">swap_vert</i>
              </button>
            </div>
            <patient-filter
              @close="datefilterShow = false"
              type="patient"
              v-bind:show="datefilterShow"
            ></patient-filter>
          </div>
        </div>
      </li>
    </ul>
  </template>
  
  <script>
  import PatientFilterBox from "@/components/FilterBox/TptPatientFilterBox.vue";
  
  export default {
    name: "PatientFilter",
    props: ["type"],
    data() {
      return {
        search: "",
        datefilterShow: false,
      };
    },
  
    mounted() {
      var elems = document.querySelectorAll(".collapsible");
      var instances = M.Collapsible.init(elems, {});
    },
    updated() {
      var elems = document.querySelectorAll(".collapsible");
      var instances = M.Collapsible.init(elems, {});
    },
  
    methods: {
      searchPatient() {
        this.$router.push({
          name: "TPTPatientSearch",
          params: {
            search: this.search,
          },
        });
      },
      excelExport() {
        var data = {
          query: this.$route.query,
          tpt_status:1
        };
  
        if (this.$route.params.search) {
          data.search = this.$route.params.search;
        } 
  
        this.$store.dispatch("excelExport", data);
      },
    },
    components: {
      "patient-filter": PatientFilterBox,
    },
    computed: {},
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  
  <style scoped></style>
  