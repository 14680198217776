<template>
  <nav class="app-navbar ">
    <div class="nav-wrapper">
      <a href="/" class="brand-logo left">TB The Union</a>
      <ul id="nav-mobile" class="right">
        <li>
          <a @click="dark = true" v-if="login && !dark">Change Dark Mode</a>
          <a @click="dark = false" v-if="login && dark">Change Light Mode</a>
        </li>

        <li><a v-if="login" @click="logout">Logout</a></li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      login: false,
      dark: localStorage.getItem("theme") == "dark" ? true : false,
    };
  },
  watch: {
    user(newVal) {
      if (newVal) {
        this.login = newVal.success;
      } else {
        this.login = false;
      }
    },
    dark: function() {
      // add/remove class to/from html tag
      let htmlElement = document.documentElement;

      if (this.dark) {
        localStorage.setItem("theme", "dark");
        htmlElement.setAttribute("theme", "dark");
      } else {
        localStorage.setItem("theme", "light");
        htmlElement.setAttribute("theme", "light");
      }
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
  computed: {
    user() {
      return this.$store.getters.auth;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Courier+Prime&display=swap");
nav {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: 767px) {
  nav .brand-logo {
    font-size: 14px;
  }
}
nav ul li a {
  font-family: "Courier Prime", monospace;
}

.switch {
  padding-right: 30px;
}
</style>
