<template>
  <transition name="modal" v-if="show">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h6>Fill Visit Date Info</h6>
            <button @click="$emit('close')" class="btn blue darken-3">
              Close
            </button>
          </div>
          <div class="modal-body">
            <div class="row" style="margin-top:50px;">
              <div class="col m8 offset-m2">
                <div class="col m12" style="margin-bottom:20px;">
                  <span>Minor Side Effect</span>
                  <input type="text" v-model="detailData.raw_low_side_effect" />
                </div>
                <div class="col m12" style="margin-bottom:20px;">
                  <span>Major Side Effect</span>
                  <input
                    type="text"
                    v-model="detailData.raw_high_side_effect"
                  />
                </div>
                <div class="col m12" style="margin-bottom:20px;">
                  <span>Pill Count</span>
                  <input type="number" v-model="detailData.pill_count" />
                </div>
                <div class="col m12 center">
                  <br />
                  <button
                    :disabled="action_loading"
                    class="btn blue darken-3"
                    @click="editButton"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["show", "detailData"],
  mounted() {},
  updated() {},
  data() {
    return {
      low_side_effect: "",
      high_side_effect: "",
      pill_count: "",
    };
  },
  methods: {
    editButton() {
      if (this.validate()) {
        this.$store.dispatch("editVisitDateInfo", {
          low_side_effect: this.detailData.raw_low_side_effect,
          high_side_effect: this.detailData.raw_high_side_effect,
          pill_count: this.detailData.pill_count,
          dots_patient_id: this.detailData.dots_patient_id,
          id: this.detailData.id,
        });
      } else {
        alert("Some are invalid");
      }
    },
    closeBtn() {},
    validate() {
      var result = true;
      var lowSideEffectString = this.detailData.raw_low_side_effect.split(",");
      for (var i in lowSideEffectString) {
        if (!this.isExisted(lowSideEffectString[i], this.low_side_effects)) {
          result = false;
        }
      }

      var highSideEffectString = this.detailData.raw_high_side_effect.split(
        ","
      );
      for (var i in highSideEffectString) {
        if (!this.isExisted(highSideEffectString[i], this.high_side_effects)) {
          result = false;
        }
      }

      return result;
    },
    isExisted(value, arr) {
      var find = false;
      arr.map((val) => {
        if (parseInt(val.code) == parseInt(value)) {
          find = true;
        }
      });
      return find;
    },
  },
  computed: {
    action_loading() {
      return this.$store.getters.dots_visitdates_action_loading;
    },
    high_side_effects() {
      return this.$store.getters.high_side_effects;
    },
    low_side_effects() {
      return this.$store.getters.low_side_effects;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 40%;
  margin: 0px auto;
  height: 600px;
  overflow-y: auto;
  padding: 20px 30px;
  background-color: var(--app-modal-background);
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-body {
  margin: 20px 0;
}

.modal-header {
  border-bottom: 0.2px solid black;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.side_effect_container {
  padding: 20px;
}
</style>
