<template>
    <div class="row">
        <div class="col m8 offset-m2">
            <div class="card card-content">
                <table class="app-table">
                    <thead>
                        <tr>
                            <th>No</th><th>Name</th><th>Phone</th><th>Voluneer</th><th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in data">
                            <td>{{ index+1 }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.phone }}</td>
                            <td>{{ item.volunteer.name }} ({{  item.volunteer.code }}) </td>
                            <td>
                                <button :disabled="action_loading"  class="btn blue darken-3" @click="recoverPatient(item.id)">Recover</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted()
    {
        this.$store.dispatch("getDOTSPatientTrash");
    },
    computed: 
    {
        data()
        {
            return this.$store.getters.dots_data;
        },
        loading()
        {
            return this.$store.getters.dots_initial_data_loading;
        },
        action_loading()
        {
            return this.$store.getters.dots_action_loading;
        },
        finish()
        {
            return this.$store.getters.dots_action_finish;
        }
    },
    methods:
    {
        recoverPatient(id)
        {
            this.$store.dispatch("recoverDotsPatient",{id:id});
        }
    },
    watch: 
    {
        finish(val)
        {
             this.$notify({
                group: "noti",
                type: "success",
                text: "Recovered"
                });

            location.reload();
        }
    }
}
</script>
<style lang="scss">
        .card {
            margin-top: 30px;
            padding: 20px;
            background: var(--card-background-color) !important;
            }
</style>