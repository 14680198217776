import { render, staticRenderFns } from "./TPTPatient.vue?vue&type=template&id=d7a38500&scoped=true"
import script from "./TPTPatient.vue?vue&type=script&lang=js"
export * from "./TPTPatient.vue?vue&type=script&lang=js"
import style0 from "./TPTPatient.vue?vue&type=style&index=0&id=d7a38500&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7a38500",
  null
  
)

export default component.exports