<template>
  <div class="card card-content">
    <h6>TB Conslusion</h6>
    <form>
      <div class="row">
        <div class="input-field col s12">
          <span>Referred Diagnostic / Treatment Entity</span>
          <select v-model="referred_diagnostic">
            <option value selected>Choose your option</option>
            <option>Township Heath Department</option>
            <option>Station Health Department</option>
            <option>PSI</option>
            <option>MMA</option>
            <option>62 OPD (UMTBC)</option>
            <option>AHRN</option>
            <option>TBH</option>
            <option>Private for profit</option>
            <option>Other</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <span>TB Status</span>
          <select v-model="tb_status">
            <option value selected>Choose your option</option>
            <option value="1">TB</option>
            <option value="2">No TB</option>
            <option value="3">MDRTB</option>
            <option value="4">Waiting for TB Conclusion</option>
          </select>
        </div>
        <div class="input-field col s12" v-show="tb_status == 2">
          <span>TPT Status</span>
          <select v-model="tpt_status">
            <option value selected>Choose your option</option>
            <option value="0">No TPT</option>
            <option value="1">TPT</option>
          </select>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <span>Union Temporary Code</span>
            <input type="text" v-model="union_temporary_code" />
          </div>
        </div>
        <div class="row">
        <div class="input-field col s12">
          <span>TB Code (Actual) - Treatment Entity</span>
          <select v-model="new_tb_code_type">
            <option value selected>Choose your option</option>
            <option>PSI</option>
            <option>MMA</option>
            <option>Tsp</option>
            <option>UMTBC</option>
            <option>AHRN</option>
          </select>
        </div>
        <div
          class="input-field col s12"
          v-show="new_tb_code_type && new_tb_code_type !== 'UMTBC'"
        >
          <span>TB Code (Actual) - Treatment Township</span>

          <select v-model="new_tb_code_township">
            <option value selected>Choose your option</option>
            <option v-for="item in townships">{{ item.short_name }}</option>
          </select>
        </div>
        <div
          class="input-field col s12"
          v-show="new_tb_code_type === 'UMTBC' || new_tb_code_township"
        >
          <span>TB Code (Actual) - Code</span>
          <input type="text" v-model="new_tb_code" />
        </div>
      </div>
        <div class="row">
          <div class="input-field col s12">
            <span>Temporary Code (Other)</span>
            <input type="text" v-model="other_temporary_code" />
          </div>
        </div>

        <div class="row">
          <div class="input-field col s12">
            <span>MDR TB Code / TPT Code</span>
            <input type="text" v-model="mdr_tb_code" />
          </div>
        </div>
        <div class="input-field col s12" v-show="show1 && showUnknown">
          <span>Smoking status </span>

          <select v-model="smoking_status">
            <option value selected>Choose your option</option>
            <option value="3">Past</option>
            <option value="2">Never</option>
            <option value="1">Current</option>
            <option value="4">Unknown</option>
          </select>
        </div>
        <div
          class="input-field col s12"
          v-show="show1 && showUnknown === false"
        >
          <span>Smoking status </span>

          <select v-model="smoking_status">
            <option value selected>Choose your option</option>
            <option value="3">Past</option>
            <option value="2">Never</option>
            <option value="1">Current</option>
          </select>
        </div>
        <div class="input-field col s12" v-show="show1">
          <span>Urban or Rural</span>
          <select v-model="urban_rural">
            <option value selected>Choose your option</option>
            <option value="1">Urban</option>
            <option value="2">Rural</option>
          </select>
        </div>
        <div class="input-field col s12" v-show="show1">
          <span>Treatment Status</span>
          <select v-model="treatment_status">
            <option value selected>Choose your option</option>
            <option value="1">Received TB Treatment</option>
            <option value="2">Refer to other township</option>
            <option value="3">Initial Loss</option>
            <option value="4">Died before Treatment</option>
          </select>
        </div>
        <div class="input-field col s12" v-show="show2">
          <span>Type of TB Patients</span>
          <select v-model="tb_type">
            <option value selected>Choose your option</option>
            <option value="1">New</option>
            <option value="2">Relapse</option>
            <option value="3">Treatment after failure</option>
            <option value="4">Treatment after loss to follow up</option>
            <option value="5">Other previously treated</option>
            <option value="6">Previous treatment history unknown</option>
          </select>
        </div>
        <div class="input-field col s12" v-show="show1">
          <span>Defined TB Type</span>
          <select v-model="tb_site">
            <option value selected>Choose your option</option>
            <option value="1">Pulmonary BC</option>
            <option value="2">Extra Pulmonary BC</option>
            <option value="3">Pulmonary CD</option>
            <option value="4">Extra Pulmonary CD</option>
          </select>
        </div>
        <div class="input-field col s12" v-show="show2">
          <span>Treatment Regimen</span>
          <select v-model="treatment_reg">
            <option value selected>Choose your option</option>
            <option value="1">Initial Regimen</option>
            <option value="2">Retreatment Regiment</option>
            <option value="3">Childhood Regiment</option>
            <option value="4">MDRTB Regiment</option>
            <option value="5">Modified Regimen</option>
          </select>
        </div>
        <div class="input-field col s12">
          <span>Treatment Start Date</span>
          <input
            type="text"
            placeholder="YYYY-MM-DD"
            v-model.lazy="start_date"
            class="datepicker"
            readonly
          />
        </div>

        <div class="input-field col s12" v-show="show1">
          <span>DM Status</span>
          <select v-model="dm_status">
            <option value selected>Choose your option</option>
            <option value="1">Yes</option>
            <option value="2">No</option>
            <option value="3">Unknown</option>
          </select>
        </div>
        <div class="input-field col s12" v-show="show1">
          <span>HIV status</span>
          <select v-model="hiv_status">
            <option value selected>Choose your option</option>
            <option value="1">Positive</option>
            <option value="2">Negative</option>
            <option value="3">Unknown</option>
          </select>
        </div>
        <div class="input-field col s12" v-show="show1 && showHIV">
          <span>CPT Date</span>
          <input
            type="text"
            placeholder="YYYY-MM-DD"
            v-model.lazy="cpt_date"
            class="datepicker"
            readonly
          />
        </div>
        <div class="input-field col s12" v-show="show1 && showHIV">
          <span>ART Date</span>
          <input
            type="text"
            placeholder="YYYY-MM-DD"
            v-model.lazy="art_date"
            class="datepicker"
            readonly
          />
        </div>
        <div class="input-field col s12" v-show="show1 && showHIV">
          <span>HIV Care</span>
          <select v-model="hiv_care">
            <option value selected>Choose your option</option>
            <option value="1">IHC</option>
            <option value="2">NAP</option>
            <option value="3">Other</option>
          </select>
        </div>

        <div class="input-field col s12" v-show="show1 && showHIV">
          <span>HIV Code</span>
          <input type="number" v-model="hiv_code" />
        </div>
        <div class="input-field col s12" v-show="show2">
          <span>Treatment outcome</span>
          <select v-model="treatment_outcome">
            <option value selected>Choose your option</option>
            <option value="1">Cured</option>
            <option value="2">Treatment complete</option>
            <option value="3">Treatment failure</option>
            <option value="4">Died</option>
            <option value="5">Lost to follow up</option>
            <option value="6">Not evaluated</option>
            <option value="7">Moved to second line treatment</option>
          </select>
        </div>
        <div class="input-field col s12" v-show="show2 && treatment_outcome">
          <span>Outcome Date</span>
          <input
            type="text"
            placeholder="YYYY-MM-DD"
            v-model.lazy="outcome_date"
            class="datepicker"
            readonly
          />
        </div>

        <div class="input-field col s12">
          <span>Remark</span>
          <textarea class="materialize-textarea" v-model="remark"></textarea>
        </div>

        <div class="row center">
          <button
            type="button"
            class="btn blue darken-3"
            @click="submit"
            :disabled="updateloading"
            v-if="buttonpass"
          >
            Edit
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "NewTBConclusion",
  data() {
    return {
      tb_status: "",
      treatment_status: "",
      code: "",
      referred_diagnostic: "",
      temporary_code: "",
      union_temporary_code: "",
      other_temporary_code: "",
      new_tb_code_type: "",
      new_tb_code_township: "",
      new_tb_code: "",
      mdr_tb_code: "",
      tb_type: "",
      tb_site: "",
      treatment_reg: "",
      start_date: "",
      treatment_outcome: "",
      hiv_care: "",
      hiv_code: "",
      hiv_status: "",
      cpt_date: "",
      art_date: "",
      outcome_date: "",
      smoking_status: "",
      urban_rural: "",
      dm_status: "",
      remark: "",
      tpt_status: "",
    };
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  watch: {
    updateloading(newVal) {
      if (!newVal) {
        location.reload();
      }
    },
  },
  methods: {
    submit() {
      var data = {
        tb_status: this.tb_status,
        treatment_status: this.treatment_status,
        union_temporary_code: this.union_temporary_code,
        mdr_tb_code: this.mdr_tb_code,
        new_tb_code_type: this.new_tb_code_type,
        new_tb_code_township: this.new_tb_code_township,
        new_tb_code: this.new_tb_code,
        other_temporary_code: this.other_temporary_code,
        referred_diagnostic: this.referred_diagnostic,
        tb_type: this.tb_type,
        tb_site: this.tb_site,
        treatment_reg: this.treatment_reg,
        start_date: this.start_date,
        treatment_outcome: this.treatment_outcome,
        smoking_status: this.smoking_status,
        urban_rural: this.urban_rural,
        dm_status: this.dm_status,
        hiv_status: this.hiv_status,
        remark: this.remark,
      };

      if (this.hiv_status == 1) {
        data.hiv_care = this.hiv_care;
        data.hiv_code = this.hiv_code;
        data.cpt_date = this.cpt_date;
        data.art_date = this.art_date;
      }

      if (this.tb_status == 2) {
        data.tpt_status = this.tpt_status;
      }

      if (data.treatment_outcome) {
        data.outcome_date = this.outcome_date;
      }

      data.id = this.patient.id;

      this.$store.dispatch("submitTBConclusion", data);
    },
  },
  components: {},
  watch: {
    tb_status() {
      localStorage.setItem("current_data", "changed");
    },
  },
  computed: {
    townships() {
      return this.$store.getters.all_townships;
    },
    patient() {
      return this.$store.getters.patient;
    },
    // is_add_done() {
    //   if (this.patient.metadata.refer_from == 5) {
    //     return true;
    //   } else {
    //     if (
    //       this.patient.chest_xray.done == 1 ||
    //       this.patient.sputum.done == 1 ||
    //       this.patient.gene_xpert.done == 1 ||
    //       this.patient.fnac.done == 1
    //     ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // },
    // not_no_tb() {
    //   if (this.patient.metadata.refer_from == 5) {
    //     return false;
    //   } else {
    //     if (
    //       this.patient.sputum.result == 2 ||
    //       this.patient.gene_xpert.result == 2 ||
    //       this.patient.fnac.result == 2
    //     ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // },

    buttonpass() {
      if (!this.tb_status) {
        return false;
      } else if (
        (this.tb_status == 1 || this.tb_status == 3) &&
        (this.smoking_status.length === 0 ||
          this.dm_status.length === 0 ||
          this.hiv_status.length === 0 ||
          this.urban_rural.length === 0 ||
          (this.new_tb_code.length && this.new_tb_code.length < 6) ||
          (this.union_temporary_code && this.union_temporary_code.length != 6)
        )
      ) {
        return false;
      } else {
        return true;
      }
    },
    updateloading() {
      return this.$store.getters.updateloading;
    },
    show1() {
      if (
        this.tb_status != 2 &&
        this.tb_status != 4 &&
        this.tb_status.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    show2() {
      if (this.treatment_status.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    showUnknown() {
      if (parseInt(this.treatment_status) === 1) return false;
      return true;
    },
    showHIV() {
      if (this.hiv_status == 1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.fill {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

.fill .card {
  padding: 20px;
}
</style>
