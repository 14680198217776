import { sendData, getData, getExcel } from "./../../misc/server.js";
export default {
  state: {
    data: [],
    patient_counts: 0,
    loading: false,
    count: 0,
    confirmCheckingLoading: false,
    confirmCheckingFinish: false,
    patient: {},
  },
  mutations: {
    clearData(state) {
      state.data = [];
    },
    makeloader(state, payload) {
      state.loading = payload;
    },
    confirmCheckingLoading(state, payload) {
      state.confirmCheckingLoading = payload;
    },
    confirmCheckingFinish(state, payload) {
      state.confirmCheckingFinish = payload;
    },
    setPatientCount(state, payload) {
      state.patient_counts = payload;
    },
    setCheckings(state, payload) {
      if (payload.load) {
        for (var i in payload.data) {
          state.data.push(payload.data[i]);
        }
      } else {
        if (payload.data) {
          state.data = payload.data;
        }
      }
    },

    setCheckingsCount(state, payload) {
      state.count = payload;
    },

    setCheckingPatient(state, payload) {
      state.patient = payload;
    },
  },
  actions: {
    getCheckings({ commit }, data) {
      var url = "/checkings/get";

      if (data.lastid == 0) {
        commit("clearData");
      }
      if (data.lastid != null) {
        url += "?lastid=" + data.lastid;
      }

      if (data.search != null) {
        url += "&search=" + data.search;
      }

      if (data.startdate != null && data.enddate != null) {
        url += "&startdate=" + data.startdate + "&enddate=" + data.enddate;
      }

      if (data.code != null && data.year != null) {
        url += "&code=" + data.code + "&year=" + data.year;
      }

      if (data.gender) {
        url += "&gender=" + data.gender;
      }

      if (data.age) {
        url += "&age=" + data.age;
      }

      if (data.status != null) {
        url += "&status=" + data.status;
      }

      if (data.confirmed) {
        url += "&confirmed=1";
      }

      if (data.tptStatus) {
        url += "&tpt_status=" + data.tptStatus;
      }

      url += "&";

      commit("makeloader", true);
      getData(url, (response) => {
        console.log(response);
        commit("makeloader", false);

        commit("setCheckings", { data: response.data, load: data.load });
        commit("setCheckingsCount", response.count);
        commit("setPatientCount", response.patient_counts);
      });
    },
    getCheckingPatient({ commit }, data) {
      var url = "/checkings/get-patient?";

      if (data.id != null) {
        url += "&id=" + data.id;
      }

      if (data.status != null) {
        url += "&status=" + data.status;
      }

      if (data.confirmed) {
        url += "&confirmed=1";
      }

      if (data.tptStatus) {
        url += "&tpt_status=" + data.tptStatus;
      }

      url += "&";

      commit("makeloader", true);
      getData(url, (response) => {
        console.log(response);
        commit("makeloader", false);

        commit("setCheckingPatient", { data: response.data, load: data.load });
      });
    },
    confirmCheck({ commit }, data) {
      var url = "/checkings/confirm";
      commit("confirmCheckingLoading", true);
      sendData(url, data, (data) => {
        commit("confirmCheckingLoading", false);
        commit("confirmCheckingFinish", true);

        console.log(data);
      });
    },
    editCheckingData({ commit }, data) {
      var url = "/checkings/edit-checking-patient";
      commit("setLoader", true);
      sendData(url, data, (response) => {
        commit("setLoader", false);
        console.log(response);
      });
    },
    deleteCheck({ commit }, data) {
      var url = "/checkings/delete";
      commit("confirmCheckingLoading", true);
      sendData(url, data, (data) => {
        commit("confirmCheckingLoading", false);
        commit("confirmCheckingFinish", true);

        console.log(data);
      });
    },

    deleteAllCheck({ commit }, data) {
      var url = "/checkings/delete-all";
      commit("confirmCheckingLoading", true);
      sendData(url, data, (data) => {
        commit("confirmCheckingLoading", false);
        commit("confirmCheckingFinish", true);

        console.log(data);
      });
    },

    clearCheckings({ commit }) {
      commit("setCheckings", { data: {}, load: false });
      commit("setCheckingsCount", 0);
    },

    recoverChecking({ commit }, data) {
      var url = "/checkings/recover";

      commit("confirmCheckingLoading", true);
      sendData(url, { id: data }, (data) => {
        commit("confirmCheckingLoading", false);
        commit("confirmCheckingFinish", true);
      });
    },
    excelExportRawData({ commit }, data) {
      var url = "/checkings/export";

      url += "?type=raw";

      if (data.query.status) {
        url += "&status=" + data.query.status;
      }

      if (data.query.age) {
        url += "&age=" + data.query.age;
      }

      if (data.query.gender) {
        url += "&gender=" + data.query.gender;
      }

      if (data.query.startdate && data.query.enddate) {
        url +=
          "&startdate=" +
          data.query.startdate +
          "&enddate=" +
          data.query.enddate;
      }

      if (data.search) {
        url += "&search=" + data.search;
      }
      url += "&tpt_status=" + data.tpt_status;

      url += "&";

      getExcel(url);
    },
  },
  getters: {
    checkings(state) {
      return state.data;
    },
    checking_status_counts(state) {
      var value = state.patient_counts;

      var counter = 0;
      var values = [];
      if (value.length > 0) {
        for (var i = 1; i <= 6; i++) {
          for (var j in value) {
            if (value[j].status == i) {
              values[i - 1] = value[j].total;
            }
          }

          if (!values[i - 1]) {
            values[i - 1] = 0;
          }
        }
        // while (counter<6)
        // {
        //     counter++;
        //     value.map((val)=>{

        //         if (counter==val.status)
        //         {
        //             values.push(val.total);
        //         }
        //         else
        //         {
        //             values.push(0);
        //         }
        //     });

        // }
      } else {
        values = [0, 0, 0, 0, 0, 0];
      }

      return values;
    },
    loading(state) {
      return state.loading;
    },
    checking_count(state) {
      return state.count;
    },
    checkingPatient(state) {
        return state.patient;
      },
    confirmCheckingLoading(state) {
      return state.confirmCheckingLoading;
    },

    confirmCheckingFinish(state) {
      return state.confirmCheckingFinish;
    },
  },
};
