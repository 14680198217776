import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth.js'
import volunteer from './modules/volunteer.js'
import checkings from './modules/checkings.js'
import patients from './modules/patients.js'
import transfertownships from './modules/transfertownships.js'
import patient_meta_data from './modules/patient_meta_data.js'
import presumptive from './modules/presumptive.js'
import scc from './modules/scc.js'
import activities from './modules/activities.js'
import patient_fill from './modules/patient_fill.js'
import pa_report from './modules/pa_report.js'
import dots from './modules/dots.js';
import dots_visit_date from './modules/dots_visit_date.js';

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    auth: auth,
    volunteer:volunteer,
    checkings:checkings,
    patients:patients,
    transfertownships:transfertownships,
    patient_meta_data:patient_meta_data,
    presumptive:presumptive,
    scc:scc,
    activities:activities,
    patient_fill:patient_fill,
    pa_report:pa_report,
    dots:dots,
    dots_visit_date:dots_visit_date,
  }
})