import { sendData, getData, getExcel } from "./../../misc/server.js";
export default {
  state: {
    patients: [],
    patient_loading: false,
    patient_count: 0,
    patient_add_loading: false,
    patient_add_finish: null,
    volunteers: null,
    delete_loading: false,
    delete_finish: false,
    insert_id: null,
  },
  mutations: {
    makeloader(state, payload) {
      state.patient_loading = payload;
    },
    setPatientAddLoader(state, payload) {
      state.patient_add_loading = payload;
    },
    setVolunteer(state, payload) {
      state.volunteers = payload;
    },
    setPatientAddFinish(state, payload) {
      state.patient_add_finish = payload;
    },
    setPatients(state, payload) {
      if (payload.load) {
        for (var i in payload.data) {
          state.patients.push(payload.data[i]);
        }
      } else {
        if (payload.data) {
          state.patients = payload.data;
        }
      }
    },
    setPatientsCount(state, payload) {
      state.patient_count = payload;
    },
    clearData(state, payload) {
      state.patients = [];
      state.patient_count = 0;
    },
    setDeleteLoading(state, payload) {
      state.delete_loading = payload;
    },
    setPatientDeleteFinish(state, payload) {
      state.delete_finish = payload;
    },
    setInsertedID(state, payload) {
      state.insert_id = payload;
    },
  },
  actions: {
    getPatients({ commit }, data) {
      var url = "/checkings/get";

      if (data.lastid != null) {
        url += "?lastid=" + data.lastid;
      }

      if (data.search != null) {
        url += "&search=" + data.search;
      }

      if (data.startdate != null && data.enddate != null) {
        url += "&startdate=" + data.startdate + "&enddate=" + data.enddate;
      }

      if (data.register_startdate != null && data.register_enddate != null) {
        url +=
          "&register_startdate=" +
          data.register_startdate +
          "&register_enddate=" +
          data.register_enddate;
      }

      if (data.code != null && data.year != null) {
        url += "&code=" + data.code + "&year=" + data.year;
      }

      if (data.volunteer_id) {
        url += "&volunteer_id=" + data.volunteer_id;
      }

      if (data.refer_from) {
        url += "&refer_from=" + data.refer_from;
      }

      if (data.gender) {
        url += "&gender=" + data.gender;
      }

      if (data.age) {
        url += "&age=" + data.age;
      }

      if (data.status != null) {
        url += "&status=" + data.status;
      }

      if (data.tptStatus == 1) {
        url += "&tpt_status=" + data.tptStatus;
      }

      if (data.sort != null) {
        url += "&sort=" + data.sort;
      }

      if (data.id != null) {
        url += "&id=" + data.id;
      }

      if (data.tb) {
        url += "&tb=" + data.tb;
      }

      if (data.nopresumptive) {
        url += "&nopresumptive=" + data.nopresumptive;
      }

      url += "&confirmed=1";
      url += "&";

      commit("makeloader", true);
      getData(url, (response) => {
        commit("makeloader", false);
        commit("setPatients", { data: response.data, load: data.load });
        commit("setVolunteer", response.volunteers);
        commit("setPatientsCount", response.count);
        console.log(response);
      });
    },
    getPatientandMetaData({ commit }, data) {
      var url = "/checkings_and_metadata/get";

      if (data.lastid != null) {
        url += "?lastid=" + data.lastid;
      }

      if (data.id != null) {
        url += "&id=" + data.id;
      }

      url += "&confirmed=1";
      url += "&";

      commit("makeloader", true);

      getData(url, (response) => {
        commit("makeloader", false);

        commit("setPatients", { data: response.data, load: data.load });
        commit("setMetaData", response.metadata, { root: true });
        commit("setVolunteer", response.volunteers);
        commit("setPatientsCount", response.count);
      });
    },
    excelExport({ commit }, data) {
      var url = "/presumptive/export";
      url += "?type=raw";

      if (data.query.age) {
        url += "&age=" + data.query.age;
      }

      if (data.query.refer_from) {
        url += "&refer_from=" + data.query.refer_from;
      }

      if (data.query.gender) {
        url += "&gender=" + data.query.gender;
      }

      if (data.query.startdate && data.query.enddate) {
        url +=
          "&startdate=" +
          data.query.startdate +
          "&enddate=" +
          data.query.enddate;
      }

      if (data.query.register_startdate && data.query.register_enddate) {
        url +=
          "&register_startdate=" +
          data.query.register_startdate +
          "&register_enddate=" +
          data.query.register_enddate;
      }

      if (data.search) {
        url += "&search=" + data.search;
      }

      url += `&tpt_status=${data.tpt_status}`;

      if (data.query.code != null && data.query.year != null) {
        url += "&code=" + data.query.code + "&year=" + data.query.year;
      }

      url += "&";

      getExcel(url);
    },

    clearPatientData({ commit }) {
      commit("clearData");
    },

    saveNewPatient({ commit }, data) {
      var url = "/presumptive/new/save";
      commit("setPatientAddFinish", null);
      commit("setPatientAddLoader", true);
      sendData(url, data, (response) => {
        commit("setPatientAddLoader", false);
        if (response.success) {
          if (response.unique) {
            commit("setPatientAddFinish", false);
            commit("setInsertedID", false);
          } else {
            commit("setInsertedID", response.insert_id);
            commit("setPatientAddFinish", true);
          }
        }
      });
    },
    saveReferralPatient({ commit }, data) {
      var url = "/presumptive/referral/save";
      commit("setPatientAddFinish", null);
      commit("setPatientAddLoader", true);
      sendData(url, data, (response) => {
        commit("setPatientAddLoader", false);
        if (response.success) {
          commit("setPatientAddFinish", true);
        }
      });
    },
    editReferralPatient({ commit }, data) {
      var url = "/presumptive/referral/update";
      commit("setPatientAddFinish", null);
      commit("setPatientAddLoader", true);
      sendData(url, data, (response) => {
        commit("setPatientAddLoader", false);
        if (response.success) {
          commit("setPatientAddFinish", true);
        }
      });
    },
    deleteReferralPatient({ commit }, payload) {
      var url = "/presumptive/referral/delete";
      commit("setDeleteLoading", true);
      commit("setPatientDeleteFinish", false);
      sendData(url, { id: payload }, (data) => {
        commit("setDeleteLoading", false);
        if (data.success) {
          commit("setPatientDeleteFinish", true);
        }
      });
    },
    getPatientTrash({ commit }) {
      var url = "/presumptive/trash";
      commit("makeloader", true);
      getData(url, (data) => {
        commit("setPatients", { data: data, load: false });
        commit("makeloader", false);
      });
    },
    getReferPatientTrash({ commit }, data) {
      var url = `/presumptive/referral/trash?tpt_status=${data.tptStatus}&`;
      
      commit("makeloader", true);
      getData(url, (data) => {
        commit("setPatients", { data: data, load: false });
        commit("makeloader", false);
      });
    },
    recoverPatient({ commit }, payload) {
      var url = "/presumptive/recover";
      url += "?id=" + payload.id;
      url += "&";

      commit("setPatientAddLoader", true);
      getData(url, (data) => {
        commit("setPatientAddLoader", false);
        if (data.success) {
          commit("setPatientAddFinish", true);
        }
      });
    },
    recoverReferPatient({ commit }, payload) {
      var url = "/presumptive/referral/recover";
      url += "?id=" + payload.id;
      url += "&";

      commit("setPatientAddLoader", true);
      getData(url, (data) => {
        commit("setPatientAddLoader", false);
        if (data.success) {
          commit("setPatientAddFinish", true);
        }
      });
    },
    exportDeletedPatient(_, tpt_status) {
      var url = `/presumptive/referral/export?type=raw&tpt_status=${tpt_status}&`;
      getExcel(url);
    },
    submitSputumInfo({}, payload) {
      sendData("/presumptive/sputum/update", payload, (res) => {
        window.location.reload();
      });
    },
  },
  getters: {
    patients(state) {
      return state.patients;
    },
    patient_loading(state) {
      return state.patient_loading;
    },
    patient_count(state) {
      return state.patient_count;
    },
    patient(state) {
      return state.patients[0];
    },

    chest_xray(state) {
      return state.patient[0].chest_xray;
    },

    sputum(state) {
      return state.patient[0].sputum;
    },

    gene_xpert(state) {
      return state.patient[0].gene_xpert;
    },

    tuberculinskin(state) {
      return state.patient[0].tuberculinskin;
    },
    fnac(state) {
      return state.patient[0].fnac;
    },
    sputum_culture(state) {
      return state.patient[0].sputum_culture;
    },
    drug_suscept(state) {
      return state.patient[0].drug_suscept;
    },
    tb_conclusion(state) {
      return state.patient[0].tb_conclusion;
    },
    patient_add_loading(state) {
      return state.patient_add_loading;
    },
    patient_add_finish(state) {
      return state.patient_add_finish;
    },
    filter_volunteers(state) {
      return state.volunteers;
    },
    refer_patient_delete_loading(state) {
      return state.delete_loading;
    },
    refer_patient_delete_finish(state) {
      return state.delete_finish;
    },
    patient_insert_id(state) {
      return state.insert_id;
    },
  },
};
