<template>
  <div id="app">
    <navbar></navbar>
    <notifications group="noti" class="notification" />
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/Partials/Navbar.vue";

export default {
  name: "App",
  components: {
    navbar: Navbar
  },
  mounted() {
    let htmlElement = document.documentElement;
    let theme = localStorage.getItem("theme");

    if (theme === 'dark') {
        htmlElement.setAttribute('theme', 'dark')
        this.darkMode = true
    } else {
        htmlElement.setAttribute('theme', 'light');
        this.darkMode = false
    }
    
    let bodyElement = document.body;
    bodyElement.classList.add("app-background");
    this.$store.dispatch("checkLogin");
  },
  watch: {
    user(newVal) {
      if (newVal != null) {
          //empty
      } else {
        this.$router.push("/");
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.auth;
    }
  }
};
</script>

<style>

.notification {
  margin-top: 80px;
}

.notification .vue-notification {
  font-size: 20px !important;
  padding: 20px;
  margin-right: 30px;
}
</style>
