import { sendData, getData, getExcel } from "./../../misc/server.js";
export default {
  state: {
    education_status: [],
    training_type: [],
    volunteer_training_types: [],
    occupation: [],
    addloader: false,
    add_finished: false,
    data: null,
    count: 0,
    loading: false,
    actionloader: false,
    actionreturn: null,
    error: null,
  },
  mutations: {
    makeloader(state, payload) {
      state.loading = payload;
    },
    makeaddFinish(state, payload) {
      state.add_finished = payload;
    },
    makeaddloader(state, payload) {
      state.addloader = payload;
    },
    setData(state, payload) {
      if (payload.load) {
        for (var i in payload.data) {
          state.data.push(payload.data[i]);
        }
      } else {
        if (payload.data) {
          state.data = payload.data;
        }
      }
    },
    clearData(state) {
      state.data = [];
    },
    setBasicData(state, payload) {
      state.education_status = payload.education_status;
      state.training_type = payload.training_type;
      state.occupation = payload.occupation;
    },
    setDataCount(state, payload) {
      state.count = payload;
    },
    actionloader(state, payload) {
      state.actionloader = payload;
    },
    setActionReturn(state, payload) {
      state.actionreturn = payload;
    },
    unblockVolunteer(state, payload) {
      state.count--;
      state.data = state.data.filter((value) => {
        return value.id != payload;
      });
    },
    permanentDeleteVolunteer(state, payload) {
      state.count--;
      state.data = state.data.filter((value) => {
        return value.id != payload;
      });
    },

    setError(state, payload) {
      state.error = payload;
    },
    setTrainingData(state, payload) {
      state.training_type = payload.training_types;
      state.volunteer_training_types = payload.volunteer_trainings;
    },
  },
  actions: {
    getBasicData({ commit }, data) {
      var url = "/educationstatus/get";
      commit("makeloader", true);
      getData(url, (response) => {
        commit("setBasicData", response);
        commit("makeloader", false);
      });
    },
    addVolunteer({ commit }, data) {
      var url = "/volunteer/add";
      commit("makeaddFinish", false);
      commit("makeaddloader", true);
      sendData(url, data, (response) => {
        if (response.success) {
          commit("makeaddFinish", true);
        } else {
          if (response.message) {
            commit("setError", "Phone number has been used");
          }
        }

        commit("makeaddloader", false);
      });
    },
    editVolunteer({ commit }, data) {
      var url = "/volunteer/edit";
      commit("makeaddFinish", false);
      commit("makeaddloader", true);
      sendData(url, data, (response) => {
        if (!response.success) {
          commit("setError", response.msg);
        } else {
          commit("makeaddFinish", true);
        }

        commit("makeaddloader", false);
      });
    },
    clearVolunteer({ commit }) {
      commit("setData", { data: {}, load: false });
      commit("setDataCount", 0);
    },
    getEachVolunteer({ commit }, data) {
      var url = "/volunteer/get/each";

      if (data.id) {
        url += "?id=" + data.id;
      }

      url += "&";
      commit("makeloader", true);
      getData(url, (response) => {
        commit("makeloader", false);
        commit("setData", { data: response.data, load: false });
      });
    },
    getVolunteer({ commit }, data) {
      var url = "/volunteer/get";
      if (data.lastid == 0) {
        commit("clearData");
      }
      if (data.lastid != null) {
        url += "?lastid=" + data.lastid;
      }

      if (data.search) {
        data.search = data.search.trim();
        url += "&search=" + data.search;
      }

      if (data.id) {
        url += "&id=" + data.id;
      }

      if (data.showAll) {
        url += "&showAll=" + data.showAll;
      }

      if (data.block) {
        url += "&block=true";
      }

      if (data.trash) {
        url += "&trash=true";
      }

      if (data.is_in_picts) {
        url += "&is_in_picts=1";
      }

      if (!data.all) {
        url += "&";
      }





      commit("makeloader", true);
      getData(url, (response) => {
        commit("makeloader", false);

        commit("setData", { data: response.volunteers, load: data.load });
        commit("setDataCount", response.count);
      });
    },

    blockVolunteer({ commit }, data) {
      var url = "/volunteer/block?id=" + data.id + "&";
      commit("actionloader", true);

      getData(url, (response) => {
        commit("actionloader", false);
        if (response.success) {
          commit("setActionReturn", { type: "block" });
        }
      });
    },
    unblockVolunteer({ commit }, data) {
      var url = "/volunteer/unblock?id=" + data.id + "&";
      commit("actionloader", true);

      getData(url, (response) => {
        commit("actionloader", false);
        if (response.success) {
          commit("unblockVolunteer", data.id);
        }
      });
    },
    deleteVolunteerPermanent({ commit }, data) {
      var url = "/volunteer/permanentDelete?id=" + data.id + "&";
      commit("actionloader", true);

      getData(url, (response) => {
        commit("actionloader", false);
        if (response.success) {
          window.location = "/volunteer/list";
        }
      });
    },
    recoverPw({ commit }, data) {
      var url = "/volunteer/recoverpw?id=" + data.id + "&";
      commit("actionloader", true);

      getData(url, (response) => {
        commit("actionloader", false);
        if (response.success) {
          commit("setActionReturn", { type: "recoverpw" });
        }
      });
    },

    deleteVolunteer({ commit }, data) {
      var url = "/volunteer/delete?id=" + data.id + "&";
      commit("actionloader", true);

      getData(url, (response) => {
        commit("actionloader", false);
        if (response.success) {
          commit("setActionReturn", { type: "delete" });
        }
      });
    },
    addTrainingType({ commit }, data) {
      var url = "/volunteer/trainingtype/add";
      commit("actionloader", true);

      sendData(url, data, (response) => {
        commit("actionloader", false);
        if (response.success) {
          commit("makeaddFinish", true);
        }
      });
    },

    getVolunteerTrainingType({ commit }, data) {
      var url = "/volunteer/trainingtype/get";
      url += "?id=" + data.id;
      url += "&";
      commit("makeloader", true);

      getData(url, (response) => {
        commit("setTrainingData", response);
        commit("makeloader", false);
      });
    },
    deleteTrainingType({ commit }, data) {
      var url = "/volunteer/trainingtype/delete";
      url += "?id=" + data.id;
      url += "&";
      commit("actionloader", true);

      getData(url, (response) => {
        commit("makeaddFinish", true);
        commit("actionloader", false);
      });
    },
    exportVolunteerExcel({ commit }, data) {
      var url = "/volunteer/export";
      getExcel(url);
    },

  },
  getters: {
    education_status(state) {
      return state.education_status;
    },
    volunteers(state) {
      return state.data;
    },
    training_type(state) {
      return state.training_type;
    },
    occupation(state) {
      return state.occupation;
    },
    addloader(state) {
      return state.addloader;
    },
    volunteer_count(state) {
      return state.count;
    },
    addFinish(state) {
      return state.add_finished;
    },
    volunteer_loading(state) {
      return state.loading;
    },
    eachvolunteer(state) {
      return state.data;
    },
    actionloader(state) {
      return state.actionloader;
    },
    actionReturn(state) {
      return state.actionreturn;
    },
    volunteerError(state) {
      return state.error;
    },
    volunteer_training_types(state) {
      return state.volunteer_training_types;
    },
  },
};
