import axios from "axios";

//var host = "http://localhost:8000/api/v1/pa";
var host = "http://150.95.26.210//api/v1/pa";
export function sendData(url, data, callback) {
  var url = host + url;
  var user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    data.token = user.user._token;
    data.township_id = user.user.township["id"];
  }

  axios
    .post(url, data)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      alert("Connection Error");
    });
}

export function getData(url, callback) {
  var url = host + url;

  var user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    if (url[url.length - 1] != "&") {
      url += "?";
    }
    url += "township_id=" + user.user.township["id"];
    url += "&token=" + user.user._token;
  }

  axios
    .get(url)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      alert("Connection Error");
    });
}

export function getExcel(url) {
  var url = host + url;

  var user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    if (url[url.length - 1] != "&") {
      url += "?";
    }
    url += "township_id=" + user.user.township["id"];
    url += "&token=" + user.user._token;
  }

  window.location = url;
}

//export let host_storage = "http://localhost:8000/storage/";
//export let host_storage = "http://dashboard.tbtheunion.com/storage/";
export let host_storage = "http://150.95.26.210/storage/";
