<template>
  <transition name="modal" v-if="show">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h6>Detail About Patient</h6>
            <button @click="$emit('close')" class="btn blue darken-3">
              Close
            </button>
          </div>

          <div class="modal-body">
            <div>
              <table>
                <tr>
                  <td>Name</td>
                  <td>{{ detailData.name }}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{{ detailData.phone }}</td>
                </tr>
                <tr>
                  <td>Age</td>
                  <td>{{ detailData.age }}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>{{ detailData.address }}</td>
                </tr>
                <tr>
                  <td>Gender</td>
                  <td>{{ gender }}</td>
                </tr>
                <tr>
                  <td>Phone ID</td>
                  <td>{{ detailData.phoneID }}</td>
                </tr>
                <tr>
                  <td>Community Submitted Date</td>
                  <td>
                    {{
                      moment(detailData.created_at, "YYYY-MM-DD").format(
                        "YYYY-MM-DD"
                      )
                    }}
                  </td>
                </tr>
                <tr v-if="detailData.status == 2">
                  <td>Volunteer Accepted Date</td>
                  <td>{{ detailData.accepted_date }}</td>
                </tr>
                <tr v-if="detailData.status == 3 || detailData.status == 7">
                  <td>Referal Date</td>
                  <td>{{ detailData.submitted_date }}</td>
                </tr>

                <tr>
                  <td>Status</td>
                  <td>
                    {{ status }}
                    <button class="btn blue darken-3 statusButton" v-if="detailData.status==3"
                                  @click="confirm(detailData.id)"
                                    :disabled="confirmCheckingLoading"
                            >Continue</button>
                    <!-- <button
                      class="btn blue darken-3 statusButton"
                      v-if="detailData.status == 3"
                      @click="openStatusDialog()"
                    >
                      Continue
                    </button> -->
                    <button
                      class="btn red darken-3 statusButton"
                      v-if="detailData.status == 3"
                      @click="deletePatient(detailData.id)"
                      :disabled="confirmCheckingLoading"
                    >
                      Delete
                    </button>

                    <button
                      class="btn blue darken-3 statusButton"
                      v-if="
                        (detailData.status == 4 ||
                          detailData.status == 5 ||
                          detailData.status == 6) &&
                          detailData.confirm == 0
                      "
                      @click="confirm(detailData.id)"
                      :disabled="confirmCheckingLoading"
                    >
                      Confirm
                    </button>
                  </td>
                </tr>
                <tr v-if="detailData.volunteer_id">
                  <td>Volunteer</td>
                  <td>
                    <a @click="searchVolunteer(detailData.volunteer.name)">{{
                      detailData.volunteer.name
                    }}</a>
                  </td>
                </tr>
                <tr v-if="detailData.status == 3 || detailData.status == 7">
                  <td>Refer Type</td>
                  <td>{{ detailData.metadata.submit_type.eng_name }}</td>
                </tr>
                <tr v-if="detailData.status == 3 || detailData.status == 7">
                  <td>Refer To</td>
                  <td>
                    {{ detailData.metadata.submit_department.eng_name }}
                    <span
                      v-if="
                        detailData.metadata.patient_submit_department_id == 3
                      "
                      >({{ detailData.metadata.submit_s_c_c.name }})</span
                    >
                  </td>
                </tr>
                <tr v-if="detailData.status == 3 || detailData.status == 7">
                  <td>Case Found Place</td>
                  <td>
                    {{ detailData.metadata.found_place.eng_name }}
                    <span v-if="detailData.metadata.pfp_description"
                      >({{ detailData.metadata.pfp_description }}) (
                      {{ sub_pfp }} )
                    </span>
                  </td>
                </tr>
                <tr v-if="detailData.status == 3 || detailData.status == 7">
                  <td>Refer From</td>
                  <td>PICTS Volunteer</td>
                </tr>
              </table>
            </div>
            <div class="row symptons">
              <div class="header">
                <h6>Symptoms</h6>
              </div>
              <div class="body">
                <p v-for="item in detailData.symptons">{{ item.sympton }}</p>
                <p v-show="isSymptonInvalid" class="red-text">( Invalid )</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <status-dialog
      @close="isStatusDialog"
      v-bind:open="isStatusDialog"
      v-bind:detailData="detailData"
    /> -->
    </div>
  </transition>
</template>
<script>
import StatusDialog from "./StatusDialog.vue";

export default {
  name: "Detail",
  props: ["show", "detailData"],
  components: {
    "status-dialog": StatusDialog,
  },
  data() {
    return {
      isStatusDialog: false,
    };
  },

  watch: {
    confirmCheckingFinish(value) {
      if (value) {
        this.$emit("close");
        location.reload();
      }
    },
  },

  computed: {
    confirmCheckingLoading() {
      return this.$store.getters.confirmCheckingLoading;
    },
    gender() {
      var gender = "ကျား";
      if (this.detailData.gender == 2) {
        gender = "မ";
      }

      return gender;
    },
    confirmCheckingFinish() {
      return this.$store.getters.confirmCheckingFinish;
    },
    sub_pfp() {
      var value = "";
      if (this.detailData.metadata.sub_pfp == 1) {
        value = "Household";
      } else if (this.detailData.metadata.sub_pfp == 2) {
        value = "Non-Household";
      }
      return value;
    },

    status() {
      var status = "";
      if (this.detailData.status == 1) {
        status = "Waiting for volunteer";
      } else if (this.detailData.status == 2) {
        status = "Volunteer Accepted";
      } else if (this.detailData.status == 3) {
        status = "Volunteer Submitted";
      } else if (this.detailData.status == 4) {
        status = "Can't Contact";
      } else if (this.detailData.status == 5) {
        status = "Fake";
      } else if (this.detailData.status == 6) {
        status = "Wrong Township";
      } else if (this.detailData.status == 7) {
        status = "PA Deleted";
      }

      return status;
    },
    isSymptonInvalid() {
      var istrue = false;

      if (this.detailData.age < 15) {
        var data = this.detailData.symptons;
        console.log(data);
        var value = true;
        var keys = [];
        var results = [];

        var total = 0;

        data.map((val) => {
          if (keys.indexOf(val.joinkey) == -1) {
            keys.push(val.joinkey);
            results.push({ key: val.joinkey, count: 1, point: val.joinkey });
          } else {
            var dex = keys.indexOf(val.joinkey);
            results[dex].count++;
            results[dex].point += val.joinkey;
          }
        });

        results.map((val) => {
          total += val.point;
        });

        if (total >= 2) {
          istrue = true;
        }
      }

      return istrue;
    },
  },

  methods: {
    confirm(id) {
      var data = {
        id: id,
      };

      this.$store.dispatch("confirmCheck", data);
    },
    openStatusDialog() {
      this.isStatusDialog = true;
    },
    deletePatient(id) {
      var data = {
        id: id,
      };

      this.$store.dispatch("deleteCheck", data);
    },
    searchVolunteer(name) {
      this.$router.push("/volunteer/list/" + name + "/search");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  margin: 0px auto;
  height: 700px;
  overflow-y: auto;
  padding: 20px 30px;
  background-color: var(--app-modal-background);
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-body {
  margin: 20px 0;
}

.modal-header {
  border-bottom: 0.2px solid black;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.symptons {
  padding: 20px;
}

.symptons .header {
  padding-top: 10px;
  border-bottom: 0.2px solid black;
}

.symptons .body {
  display: flex;
  flex-wrap: wrap;
}

.symptons .body p {
  padding: 7px;
  background: rgba(220, 190, 190, 0.3);
  margin-left: 10px;
}

.statusButton {
  margin-left: 10px;
}
</style>
