var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill"},[(_vm.patient_loading)?_c('div',{staticClass:"center"},[_c('loading')],1):_c('section',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"btn-box"},[_c('div',[_c('router-link',{staticClass:"btn green darken-2",attrs:{"to":'/tpt-patient/new'}},[_vm._v("New Patient")]),_c('router-link',{staticClass:"btn green darken-2",attrs:{"to":'/tpt-patients/'}},[_vm._v("All Patient")])],1),_c('button',{staticClass:"btn blue darken-3",attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(1)}}},[_vm._v(" TPT Info ")]),_c('fragment',[_c('button',{staticClass:"btn blue darken-3",class:{
              blue: _vm.patient?.sputum,
              'required-box': _vm.patient?.sputum
                ? _vm.patient?.sputum.result == 4
                : false,
              'yellow black-text': !_vm.patient.sputum,
            },attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(3)}}},[_vm._v(" Sputum ")]),_c('button',{staticClass:"btn darken-3",class:{
              blue: _vm.patient?.chest_xray,
              'required-box': _vm.patient?.chest_xray
                ? _vm.patient?.chest_xray.result == 4
                : false,
              'yellow black-text': !_vm.patient?.chest_xray,
            },attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(2)}}},[_vm._v(" Chest X-ray ")]),_c('button',{staticClass:"btn blue darken-3",class:{
              blue: _vm.patient?.gene_xpert,
              'required-box': _vm.patient?.gene_xpert
                ? _vm.patient?.gene_xpert.result == 7
                : false,
              'yellow black-text': !_vm.patient?.gene_xpert,
            },attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(4)}}},[_vm._v(" Gene Xpert ")]),_c('button',{staticClass:"btn blue darken-3",class:{
              blue: _vm.patient?.tuberculinskin,
              'required-box': _vm.patient?.tuberculinskin
                ? _vm.patient?.tuberculinskin.result == 4
                : false,
              'yellow black-text': !_vm.patient?.tuberculinskin,
            },attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(5)}}},[_vm._v(" Tuberculin Skin ")])]),_c('button',{staticClass:"btn blue darken-3",attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(10)}}},[_vm._v(" Other Investigation ")]),_c('button',{staticClass:"btn blue darken-3",attrs:{"disabled":_vm.updateloading},on:{"click":function($event){return _vm.changeTab(9)}}},[_vm._v(" TB Conclusion ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col m6 offset-m3"},[(_vm.active == 1)?_c('personal-info'):_vm._e(),(_vm.active == 2)?_c('chest-xray'):_vm._e(),(_vm.active == 3)?_c('sputum'):_vm._e(),(_vm.active == 4)?_c('gene-xpert'):_vm._e(),(_vm.active == 5)?_c('tuber-culin-skin'):_vm._e(),(_vm.active == 9)?_c('tb-conclusion'):_vm._e(),(_vm.active == 10)?_c('other-investigation'):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }