<template>
  <transition name="modal" v-if="open">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h6>Choose TB Status for Patient</h6>
            <button @click="$emit('close')" class="btn blue darken-3">
              Close
            </button>
          </div>

          <div class="modal-body">
            <button @click="showDetail(checking)" class="btn blue darken-3">
              Presumptive
            </button>
            <button @click="showDetail(checking)" class="btn blue darken-3">
              TPT
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "StatusDialog",
  props: ["open"],
  data() {
    return {};
  },

  watch: {
    confirmCheckingFinish(value) {
      if (value) {
        this.$emit("close");
        location.reload();
      }
    },
  },

  computed: {
    confirmCheckingLoading() {
      return this.$store.getters.confirmCheckingLoading;
    },
   
    confirmCheckingFinish() {
      return this.$store.getters.confirmCheckingFinish;
    },
   
    status() {
      var status = "";
      if (this.detailData.status == 1) {
        status = "Waiting for volunteer";
      } else if (this.detailData.status == 2) {
        status = "Volunteer Accepted";
      } else if (this.detailData.status == 3) {
        status = "Volunteer Submitted";
      } else if (this.detailData.status == 4) {
        status = "Can't Contact";
      } else if (this.detailData.status == 5) {
        status = "Fake";
      } else if (this.detailData.status == 6) {
        status = "Wrong Township";
      } else if (this.detailData.status == 7) {
        status = "PA Deleted";
      }

      return status;
    },
  },

  methods: {
    confirm(id) {
      var data = {
        id: id,
      };

      this.$store.dispatch("confirmCheck", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  margin: 0px auto;
  height: 700px;
  overflow-y: auto;
  padding: 20px 30px;
  background-color: var(--app-modal-background);
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-body {
  margin: 20px 0;
}

.modal-header {
  border-bottom: 0.2px solid black;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.statusButton {
  margin-left: 10px;
}
</style>
