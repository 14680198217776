<template>
  <div class="EditVolunteer">
      <div class="container">
         
            <div class="card z-depth-3">
                         
                        <div class="card-content">
                            <div class="center">
                            <h5>Edit Volunteer</h5>
                            </div>
                            <div class="row">
                                <div class=" col s12">
                                    <label class="black-text">Name</label>
                                    <input type="text"  v-model="volunteer.name"/>
                                    
                                </div>
                            </div>
                             <div class="row">
                                <div class=" col s12">
                                    <label class="black-text">Age</label>
                                    <input type="number"  v-model="volunteer.age"/>
                                    
                                </div>
                            </div>
                             <div class="row">
                                <div class="col s12 ">
                                    <label class="black-text">Gender</label>
                                    <select v-model="volunteer.gender">
                                        
                                        <option value="1">Male</option>
                                        <option value="2">Female</option>
                                    </select>
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <label class="black-text">Village</label>
                                    <input type="text" class="validate"  v-model="volunteer.village" />
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <label class="black-text">Ward</label>
                                    <input type="text" class="validate"  v-model="volunteer.ward" />
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <label class="black-text">Phone</label>
                                    <input type="text" class="validate"  v-model="volunteer.phone" />
                                    
                                </div>
                            </div>
                              <div class="row">
                                <div class="col s12 ">
                                    <label class="black-text">Part History of TB</label>
                                    <select v-model="volunteer.tb_history">
                                        
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                    </select>
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12 ">
                                    <label class="black-text">Previous Volunteer Experience</label>
                                    <select v-model="volunteer.experience">
                                        
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                    </select>
                                    
                                </div>
                            </div>
                             <div class="row">
                                <div class="col s12">
                                    <label >Occupation</label>
                                     <select v-model="volunteer.occupation_id" >
                                        <option value="" disabled selected>Choose Occupation</option>
                                        <option v-bind:value=" item.id "  v-for="item in occupations">{{ item.name }}</option>
                                        
                                    </select>
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12 ">
                                    <label class="black-text">Education Status</label>
                                    <select v-model="volunteer.education_status_id">
                                        <option value="" disabled selected>Choose education status</option>
                                        <option v-bind:value=" item.id "  v-for="item in education_statuses">{{ item.name }}</option>
                                        
                                    </select>
                                    
                                </div>
                            </div>
                             <div class="row" v-show="user.user.township.picts_only==2">
                                <div class="col s12 ">
                                    <label >PICTS Only</label>
                                    <select v-model="volunteer.picts_only">
                                        
                                        <option value="1">No</option>
                                        <option value="2">Yes</option>
                                    </select>
                                    
                                </div>
                            </div>
                               <div class="row">
                                <div class="col s12 ">
                                    <label >Phone Supported</label>
                                    <select v-model="volunteer.phone_provided">
                                        
                                       <option value="Not Supported" selected>Not Supported</option>
                                        <option value="AIS">AIS</option>
                                        <option value="GF">GF</option>
                                    </select>
                                    
                                </div>
                            </div>
                             <div class="row" v-show="user.user.township.short_name==='TGI' || user.user.township.short_name==='LSO'">
                                <div class="col s12 ">
                                    <label >Township 1 or 2</label>
                                    <select v-model="volunteer.seperated_side">
                                        <option value="1">Township 1</option>
                                        <option value="2">Township 2</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <label class="black-text">Remark</label>
                                    <textarea class="materialize-textarea" v-model="volunteer.remark"></textarea>
                                    
                                </div>
                            </div>
                             <div class="row" v-if="completeAll">
                                <div class="input-field col s12 center">
                                        <button  :disabled="addloader" class="btn waves-effect btn-large blue darken-3" @click="edit">Edit</button>
                                </div>
                            </div>


                        </div>
                    </div>
          </div>
      </div>
         
    </div>
</template>

<script>
export default {
  name: "EditVolunteer",
  data() {
    return {};
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var id = this.$route.params.id;
    this.$store.dispatch("getEachVolunteer", { id: id });
    this.$store.dispatch("getBasicData");
  },
  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
  },
  watch: {
    addFinish(newVal) {
      if (newVal) {
        this.$notify({
          group: "noti",
          type: "success",
          text: "Volunteer is edited successfully",
        });
      }
    },
    error(newVal) {
      this.$notify({
        group: "noti",
        type: "error",
        text: newVal,
      });
    },
  },
  methods: {
    edit() {
      this.$store.dispatch("editVolunteer", this.volunteer);
    },
  },
  computed: {
    education_statuses() {
      return this.$store.getters.education_status;
    },

    occupations() {
      return this.$store.getters.occupation;
    },
    volunteer() {
      return this.$store.getters.eachvolunteer;
    },
    completeAll() {
      return (
        this.volunteer.name &&
        this.volunteer.village &&
        this.volunteer.ward &&
        this.volunteer.phone &&
        this.volunteer.education_status_id &&
        this.volunteer.occupation_id &&
        this.volunteer.remark &&
        this.volunteer.age
      );
    },
    addFinish() {
      return this.$store.getters.addFinish;
    },
     user()
      {
          return this.$store.getters.auth;
      },
    addloader() {
      return this.$store.getters.addloader;
    },
    error() {
      return this.$store.getters.volunteerError;
    },
    user() {
      return this.$store.getters.auth;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Volunteer {
  padding-top: 20px;
}
</style>
