<template>
    <div>
   <table class="app-table">
                        <thead>
                            <tr>
                                <th>No</th><th>Name</th><th>Phone</th>
                               <th>#</th>
                                
                            </tr>
                            </thead>
                         <tbody>
                            <tr v-for="(item,index) in data">
                                <td>{{ index+1 }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.phone }}</td>
                                <td>
                                    <button v-if="item.transfered==0"  class="btn blue darken-3" @click="showManage(item)">Manage</button>
                                    <span v-else>Transfered</span>
                                </td>

                                

                            </tr>
                         </tbody>
   </table>
    <wrongtownship-manage 
    @close="detailShow=false" v-bind:show="detailShow"  
    v-bind:data="transferData" ></wrongtownship-manage>
   </div>
</template>
<script>
import WrongTownshipManage from '@/components/Checkings/WrongTownshipManage/WrongTownshipManage.vue';
export default {
  name: 'WrongTownship',
  props:["data"],
  data () {
    return {
     detailShow:false,
     transferData:null,
    }
  },
  components:{
    "wrongtownship-manage":WrongTownshipManage
  },
  mounted()
  {
      var elems = document.querySelectorAll('select');
      var instances = M.FormSelect.init(elems, {});
      this.$store.dispatch("getTownships");
  },

  updated()
  {
    var elems = document.querySelectorAll('select');
      var instances = M.FormSelect.init(elems, {});
  },

  computed: {
      townships()
      {
          return this.$store.getters.townships;
      },
      
      transferFinish()
      {
        return this.$store.getters.transferFinish;
      }
  },

  watch:{
    transferFinish(val)
    {
        if (val)
        {
        this.show=false;
        location.reload();
        }
    }
    },

  methods: 
  {
      showManage(item)
      {
        
        this.detailShow=true
        this.transferData=item;
      }
      
  }
  

  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 
</style>
