<template>
  <div class="fill">
    <div class="center" v-if="patient_loading">
      <loading></loading>
    
    </div>
  
    <section v-else>
      <div class="row">
        <div class="col m6 offset-m3">
          <patient-edit-form :patient="patient.data"></patient-edit-form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PatientEditForm from "./PatientEditForm.vue";
import Loading from "@/components/Partials/Loading.vue";

export default {
  name: "PatientEdit",
  data() {
    return {};
  },
  mounted() {
    if (this.$route.params.id) {
      var data = {};
      data.status = 3;
      data.id = this.$route.params.id;
      data.load = false;
      data.tptStatus = this.$route.params.tptStatus;
      this.$store.dispatch("getCheckingPatient", data);
    }
  },
  methods: {},
  components: {
    "patient-edit-form": PatientEditForm,
    loading: Loading,
  },
  computed: {
    patient_loading() {
      return this.$store.getters.loading;
    },
    updateloading() {
      return this.$store.getters.updateloading;
    },
    patient() {
      var data = this.$store.getters.checkingPatient;
      return data ? data : {};
    },
    patient_update_success() {
      return this.$store.getters.patient_update_success;
    },
  },
};
</script>
