<template>
  <div v-if="tuberculinskin" class="card card-content">
    <h6>Tuberculin Skin</h6>
    <form>
      <div class="row">
        <div class="input-field col s12">
          <div class="switch">
            <label>
              Not Done
              <input
                type="checkbox"
                @click="switchChange"
                :checked="tuberculinskin.done == 1"
              />
              <span class="lever"></span>
              Done
            </label>
          </div>
        </div>
        <div class="input-field col s12">
          <span>Date of Tuberculin Skin Test</span>
          <input
            :disabled="tuberculinskin.done != 1"
            class="datepicker"
            type="text"
            placeholder="YYYY-MM-DD"
            v-model.lazy="tuberculinskin.date"
            readonly
          />
        </div>
        <div class="input-field col s12">
          <span>Size of induration in millmeter</span>
          <input
            type="number"
            placeholder="..m"
            v-model="tuberculinskin.size"
            :disabled="tuberculinskin.done != 1"
          />
        </div>
        <div class="input-field col s12" v-show="tuberculinskin.done == 1">
          <span>Conclusion of TST result</span>
          <select v-model="tuberculinskin.result">
            <option value="" disabled selected>Choose your option</option>
            <option value="1">Negative</option>
            <option value="2">Positive</option>
            <option value="3">Missing</option>
            <option value="4">Pending</option>
          </select>
        </div>

        <div class="row center" v-if="buttonpass">
          <button
            type="button"
            class="btn blue darken-3"
            @click="submit"
            :disabled="updateloading"
          >
            Edit
          </button>
        </div>
      </div>
    </form>
  </div>
  <new-tuberculin-skin v-else></new-tuberculin-skin>
</template>

<script>
import NewTuberCulinSkin from "@/components/Patient/Fill/TuberCulinSkin/NewTuberCulinSkin.vue";
export default {
  name: "TuberCulinSkin",
  data() {
    return {
      done: false,
      date: "",
      result: "",
    };
  },
  components: {
    "new-tuberculin-skin": NewTuberCulinSkin,
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  watch: {},
  methods: {
    switchChange() {
      this.tuberculinskin.done = this.tuberculinskin.done == 0 ? 1 : 0;
    },

    submit() {
      var data = {
        done: this.tuberculinskin.done,
        date: null,
        result: null,
      };

      if (data.done) {
        data.date = this.tuberculinskin.date;
        data.result = this.tuberculinskin.result;
        data.size = this.tuberculinskin.size;
      }

      data.id = this.patient.id;

      this.$store.dispatch("submitTuberCulinSkin", data);
    },
  },

  computed: {
    patient() {
      return this.$store.getters.patient;
    },

    tuberculinskin() {
      var item = localStorage.getItem("current_data");
      var data = this.$store.getters.patient.tuberculinskin;
      if (!item) {
        localStorage.setItem("current_data", JSON.stringify(data));
      }

      return data;
    },

    buttonpass() {
      if (this.tuberculinskin.done == 1) {
        if (this.tuberculinskin.result && this.tuberculinskin.date) {
          return true;
        } else if (this.tuberculinskin.result == 4) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    updateloading() {
      return this.$store.getters.updateloading;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fill {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

.fill .card {
  padding: 20px;
}
</style>
