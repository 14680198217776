<template>
  <div class="container">
    <div class="card z-depth-3">
      <div class="center">
        <h5>Edit History</h5>
      </div>
      <table class="app-table responsive-table mt-5">
        <thead>
          <tr>
            <th>No</th>
            <th>From</th>
            <th>To</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" v-bind:key="item.id">
            <td>{{ index + 1 }}</td>
            <td>
              Low Side Effect - {{ item.from_data.raw_low_side_effect }} <br />
              High Side Effect - {{ item.from_data.raw_high_side_effect }}
              <br />
              Pill Count - {{ item.from_data.pill_count }} <br />
            </td>
            <td>
              Low Side Effect - {{ item.to_data.raw_low_side_effect }} <br />
              High Side Effect - {{ item.to_data.raw_high_side_effect }} <br />
              Pill Count - {{ item.to_data.pill_count }} <br />
            </td>
            <td>{{ item.created_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "DOTS Patient List",
  mounted() {
    this.getData();
  },

  methods: {
    loadMore() {
      this.getData(true);
    },

    getData() {
      this.$store.dispatch("getDOTSVisitDateEdit", {
        id: this.$route.params.id,
      });
    },
  },
  computed: {
    data() {
      var data = this.$store.getters.dots_edit_history;

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 30px;
  padding: 20px;
  background: var(--card-background-color) !important;
}
</style>
