<template>
  <tr class="tableheader">
    <th class="fixed_header">Presumptive Code</th>
    <th>No</th>
    <th>Name</th>
    <th>Actions</th>
    <personal-info-header></personal-info-header>
    <sputum-header></sputum-header>
    <chest-xray-header></chest-xray-header>
    <gene-xpert-header></gene-xpert-header>
    <tuber-culin-skin-header></tuber-culin-skin-header>
    <fnac></fnac>
    <sputum-culture-header></sputum-culture-header>
    <tb-conclusion-header></tb-conclusion-header>
  </tr>
</template>

<script>
import PersonalInfoHeader from "@/components/Patient/TableData/PersonalInfo/Header.vue";
import SputumHeader from "@/components/Patient/TableData/Sputum/Header.vue";
import ChestXRayHeader from "@/components/Patient/TableData/ChestXRay/Header.vue";
import GeneXpertHeader from "@/components/Patient/TableData/GeneXpert/Header.vue";
import TuberCulinSkinHeader from "@/components/Patient/TableData/TuberCulinSkin/Header.vue";
import FNACHeader from "@/components/Patient/TableData/FNAC/Header.vue";
import SputumCultureHeader from "@/components/Patient/TableData/SputumCulture/Header.vue";
import DrugSusceptbilityHeader from "@/components/Patient/TableData/DrugSusceptbility/Header.vue";
import TBConclusionHeader from "@/components/Patient/TableData/TBConclusion/Header.vue";

export default {
  name: "TableHeader",
  data() {
    return {};
  },
  components: {
    "personal-info-header": PersonalInfoHeader,
    "sputum-header": SputumHeader,
    "chest-xray-header": ChestXRayHeader,
    "gene-xpert-header": GeneXpertHeader,
    "tuber-culin-skin-header": TuberCulinSkinHeader,
    fnac: FNACHeader,
    "sputum-culture-header": SputumCultureHeader,
    "drug-susceptibility": DrugSusceptbilityHeader,
    "tb-conclusion-header": TBConclusionHeader,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.fixed-table-container .tableheader th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  background: var(--app-navbar-background-color);
  color: var(--table-text-color);
  text-align: center;
  height: 50px;
  min-width: 150px !important;
}

.fixed-table-container thead .fixed_header {
  left: 0;
  z-index: 3;
  color: white;
}
</style>
