<template>
  <div class="row">
    <ul class="collapsible">
      <li>
        <div class="collapsible-header">
          <i class="material-icons">settings</i>Setting
        </div>
        <div class="collapsible-body">
          <div class="row">
            <div class="col m4" v-for="(item,index) in filterables">
              <div class="row">
                <div class="col m3 center">
                  <br />
                  <label>{{ item.name }}</label>
                </div>
                <div class="col m6">
                  <input  v-model="form.data_id[index]" type="number" v-if="item.type==1" />
                  <input  v-model="form.data_id[index]" type="text" v-if="item.type==2" />
                  <select  v-model="form.data_id[index]" v-if="item.type==3">
                    <option value disabled selected>Choose your volunteer</option>
                    <option
                      v-for="item in volunteers"
                      :value="item.code"
                    >{{ item.name }} ({{ item.code }})</option>
                  </select>
                  <input
                    v-model.lazy="form.data_id[index]"
                    type="text"
                    v-if="item.type==4"
                    class="datepicker"
                    readonly
                  />
                  <select  v-model="form.data_id[index]"  v-if="item.type==8">
                    <option disabled selected>Choose your scc</option>
                    <option v-for="item in sccs" :value="item.name">{{ item.name }}</option>
                  </select>
                </div>
                  <div class="col m3 center">
                    <br />
                    <button @click="searchData(index)" class="btn blue darken-3">Search</button>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        data_id: []
      }
    };
  },
  mounted() {
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true
    });
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".collapsible");
    var instances = M.Collapsible.init(elems, {});
  },
  updated() {
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true
    });
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".collapsible");
    var instances = M.Collapsible.init(elems, {});
  },
  watch: {
    // filterables(val)
    // {
    //     this.select_type=val[0];
    // }
  },
  computed: {
    filterables() {
      return this.$store.getters.filterables;
    },
    volunteers() {
      return this.$store.getters.activity_volunteers;
    },
    sccs() {
      return this.$store.getters.activity_sccs;
    }
  },
  methods: {
    searchData(index)
    {
      var data ={
        column_id:this.filterables[index]["id"],
        data:this.form.data_id[index],
        id:this.$route.params.id,
        month:this.$route.params.month,
        year:this.$route.params.year
     };
      
      this.$store.dispatch("filterActivityData",data);
        
    }
  }
};
</script>
<style scoped>
label {
  color: black;
  font-size: 16px;
}
</style>