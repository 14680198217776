<template>
  <div class="row" style="margin-top: 20px">
    <ul class="collapsible">
      <li>
        <div class="collapsible-header">
          <i class="material-icons">settings</i>Setting
        </div>
        <div class="collapsible-body">
          <div class="row card card-content">
            <div class="input-field col m6 s12">
              <input
                placeholder="Search here by phone,code,name"
                type="text"
                class="validate"
                @keydown.enter="searchCheckings"
                v-model="search"
              />
            </div>
            <div class="input-field col m6 s12">
              <div class="row">
                <div class="col s4">
                  <filterbox></filterbox>
                </div>
                <div class="col s4">
                  <button
                    @click="exportExcel"
                    class="btn btn-large green darken-2"
                  >
                    Export
                  </button>
                </div>
                <div class="col s4">
                  <button
                    class="btn btn-large blue darken-3"
                    @click="datefilterShow = true"
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <patient-filterbox
      type="checking"
      @close="datefilterShow = false"
      v-bind:show="datefilterShow"
    ></patient-filterbox>
  </div>
</template>

<script>
import FilterBox from "@/components/Checkings/FilterBox.vue";
import PatientFilterBox from "@/components/FilterBox/PatientFilterBox.vue";

export default {
  name: "Checkings",
  data() {
    return {
      search: "",
      datefilterShow: false,
    };
  },
  mounted() {
    var elems = document.querySelectorAll(".collapsible");
    var instances = M.Collapsible.init(elems, {});
  },
  methods: {
    searchCheckings() {
      this.$router.push({
        name: "CheckingsSearch",
        params: { search: this.search },
      });
    },
    exportExcel() {
      var data = { query: this.$route.query, tpt_status: 0 };

      if (this.$route.params.search) {
        data.search = this.$route.params.search;
      }

      this.$store.dispatch("excelExportRawData", data);
    },
  },
  components: {
    filterbox: FilterBox,

    "patient-filterbox": PatientFilterBox,
  },
  computed: {
    checking_count() {
      return this.$store.getters.checking_count;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.checking_list {
  padding-left: 20px;
  padding-right: 20px;
}

.checking_list .card-content {
  padding: 10px;
}

.card {
  background: var(--card-background-color);
}
</style>
