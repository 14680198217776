<template>
  <div class="Volunteer">
     <div class="row">
        <back-container link="/home"></back-container>
    </div>
    <div class="center">
      <h5>Volunteers</h5>
    </div>
    <div class="row">
      <div class="col m4 s6">
        <router-link to="/volunteer/list">
          <div class="card z-depth-3 white lighten-4">
            <div class="card-content center">
              <i class="material-icons icons">list</i>
              <h6>List</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col m4 s6">
        <router-link to="/volunteer/all-list">
          <div class="card z-depth-3 white lighten-4">
            <div class="card-content center">
              <i class="material-icons icons">list</i>
              <h6>Combined List</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col m4 s6">
        <router-link to="/volunteer/addnew">
          <div class="card z-depth-3 white lighten-4">
            <div class="card-content center">
              <i class="material-icons icons">add</i>
              <h6>New</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col m4 s6">
        <router-link to="/volunteer/blocklist">
          <div class="card z-depth-3 white lighten-4">
            <div class="card-content center">
              <i class="material-icons icons">block</i>
              <h6>Block List</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col m4 s6">
        <router-link to="/volunteer/trashlist">
          <div class="card z-depth-3 white lighten-4">
            <div class="card-content center">
              <i class="material-icons icons">delete</i>
              <h6>Trash</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Volunteer",
  data() {
    return {};
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>




.Volunteer {
  padding-top: 30px;
  padding-left: 5%;
  padding-right: 5%;
}

.forward
{
    color:var(--card-background-color) !important;
    position: absolute;
    right:0px;
    bottom:2px;
    font-size:40px;
}

.Home 
{
    padding-top:10px;
    padding-left:5%;
    padding-right:5%;
}

 .icons 
{
    font-size:50px;
    color:var(--card-icon-color) !important;
}

 .card-content
{
    padding-top:35px;
    padding-bottom:30px;
}

 .card
 {
     border-radius: 20px;
     border-left:20px solid var(--card-icon-color) !important;
     height:200px;
     background:var(--card-background-color) !important;
 }

 .card h6 
 {
     color:var(--card-text-color);
 }


 .card:hover 
{
    border-right:20px solid var(--card-icon-color) !important;
    transition:2s;
}

.card:hover .forward
{
    color:var(--card-icon-color) !important;
    transition: 2s;
}




 .card-content h6 
{
    font-size:22px;
}

</style>
