<template>
  <fragment>
    <th>Tbstatus</th>
    <th>Trtstatus</th>
    <th>Tbcode</th>
    <th>Tbtype</th>
    <th>Tbsite</th>
    <th>Tbreg</th>
    <th>Trtdate</th>
    <th>Trtoc</th>
    <th>Ocdate</th>
    <th>SmkStatus</th>
    <th>Urban/Rural</th>
    <th>DmStatus</th>
    <th>Hivstatus</th>
    <th>CPTDate</th>
    <th>ARTDate</th>
    <th>Hivcare</th>
    <th>Hivcode</th>
    <th>Remark</th>
  </fragment>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
