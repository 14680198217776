<template>
  <div class="eachvolunteer">
    <div class="row">
      <div class="col l8 offset-l2 m10 offset-m1 s12">
        <div class="row">
          <back-container link="/volunteer/all-list"></back-container>
        </div>
        <div class="card card-content z-depth-3 ">
          <div class="center" v-if="loading">
            <loading></loading>
          </div>
          <section v-else>
            <table class="app-table" v-if="volunteer">
              <tr>
                <td>Name</td>
                <td>{{ volunteer.name }}</td>
              </tr>
              <tr v-if="volunteer.unconfirmed_pw != null">
                <td>Password</td>
                <td>{{ volunteer.unconfirmed_pw }}</td>
              </tr>
              <tr>
                <td>Code</td>
                <td>{{ volunteer.code }}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>{{ gender }}</td>
              </tr>
              <tr>
                <td>Age</td>
                <td>{{ volunteer.age }}</td>
              </tr>

              <tr>
                <td>Village</td>
                <td>{{ volunteer.village }}</td>
              </tr>
              <tr>
                <td>Ward</td>
                <td>{{ volunteer.ward }}</td>
              </tr>
              <tr>
                <td>Part History of TB</td>
                <td>{{ history }}</td>
              </tr>
              <tr>
                <td>Previous Volunteer Experience</td>
                <td>{{ experience }}</td>
              </tr>

              <tr>
                <td>Education Status</td>
                <td>{{ volunteer.education_status && volunteer.education_status.name }}</td>
              </tr>
              <tr>
                <td>Occupation</td>
                <td>{{ volunteer.occupation && volunteer.occupation.name }}</td>
              </tr>
                <tr>
                <td>Recruited by</td>
                <td>{{ volunteer.recruited_by  }}</td>
              </tr>
              <tr>
                <td>PICTS and MDR</td>
                <td>
                  <span v-if="volunteer.is_in_picts== 1 && volunteer.is_in_mdr== 1">PICT AND MDR</span>
                <span v-else-if="volunteer.is_in_mdr==1">MDR</span>
                <span v-else-if="volunteer.is_in_picts==1">PICTS</span>
                </td>
              </tr>
             
              <tr>
                <td>Phone Supported</td>
                <td>
                  {{ volunteer.phone_provided }}
                </td>
              </tr>
              <tr v-show="volunteer.is_in_picts!=1 && volunteer.status==1">
                <td colspan="2">
                  <button
                    class="btn blue darken-2"
                    @click="useAsPicts"
                    :disabled="actionloader"
                  >
                    Use as PICTS  
                  </button>
                </td>
              </tr>
               <tr v-show="(user.user.township.short_name==='TGI' || user.user.township.short_name==='LSO' || user.user.township.short_name==='LS2' || user.user.township.short_name==='TG2') && volunteer.is_in_picts!=1 && volunteer.status==1">
                <td>Township 1-2 Switch</td>
                <td>
                   <button
                    v-if="user.user.township.short_name==='LSO' && user.user.township.id==16"
                    class="btn blue darken-2"
                    @click="moveTownship(66)"
                    :disabled="actionloader"
                  >
                    Move to Township 2
                  </button>
                     <button
                      v-if="user.user.township.short_name=='LS2' && user.user.township.id==66"
                      class="btn blue darken-2"
                      @click="moveTownship(16)"
                      :disabled="actionloader"
                  >
                    Move to Township 1
                  </button>
                   <button
                    v-if="user.user.township.short_name==='TGI' && user.user.township.id==18"
                    class="btn blue darken-2"
                    @click="moveTownship(65)"
                    :disabled="actionloader"
                  >
                    Move to Township 2
                  </button>
                     <button
                      v-if="user.user.township.short_name==='TG2' && user.user.township.id==65"
                      class="btn blue darken-2"
                      @click="moveTownship(18)"
                      :disabled="actionloader"
                  >
                    Move to Township 1
                  </button>
                </td>
              </tr>
            </table>

            <div class="center" v-else>
              <h5>Volunteer Not found</h5>
            </div>
            <div class="remark" v-if="volunteer">
              <h6>Remark</h6>
              <p class="text-justify">
                {{ volunteer.remark }}
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loadng from "@/components/Partials/Loading.vue";
import { sendData } from '../../../misc/server';
export default {
  name: "EachVolunteer",
  data() {
    return {};
  },
  mounted() {
    var id = this.$route.params.id;
    this.$store.dispatch("getEachVolunteer", { id: id });
  },
  methods: {
    block() {
      var data = {
        id: this.volunteer.id,
      };

      this.$store.dispatch("blockVolunteer", data);
    },
    trash() {
      var data = {
        id: this.volunteer.id,
      };

      this.$store.dispatch("deleteVolunteer", data);
    },
    moveTownship(township_id){
      sendData("/volunteer/update-township",{id:this.volunteer.id,update_township_id:township_id},()=>{
        window.location="/volunteer/all-list";
      })
    },
    permenantDelete() {
      var data = {
        id: this.volunteer.id,
      };

      if (
        confirm(
          "Are you sure to delete?You cannot see the deleted data again"
        ) == true
      ) {
        this.$store.dispatch("deleteVolunteerPermanent", data);
      }
    },
    recoverpw() {
      var data = {
        id: this.volunteer.id,
      };

      this.$store.dispatch("recoverPw", data);
    },
    edit() {
      this.$router.push({
        name: "EditVolunteer",
        params: { id: this.volunteer.id },
      });
    },
    useAsPicts(){
      sendData("/volunteer/use-as-picts",{id:this.volunteer.id},()=>{
      window.location.reload();
      })
    },
    addtraining() {
      this.$router.push({
        name: "VolunteerAddTraining",
        params: { id: this.volunteer.id },
      });
    },
  },
  watch: {
    actionReturn(newVal) {
      if (newVal.type == "block" || newVal.type == "delete") {
        this.$router.push("/volunteer/list");
      } else if (newVal.type == "recoverpw") {
        location.reload();
      }
    },
  },
  components: {
    loading: Loadng,
  },
  computed: {
    volunteer() {
      return this.$store.getters.eachvolunteer;
    },
    loading() {
      return this.$store.getters.loading;
    },
    actionloader() {
      return this.$store.getters.actionloader;
    },

    actionReturn() {
      return this.$store.getters.actionReturn;
    },
      user() {
      return this.$store.getters.auth;
    },
    trainings() {
      var trainings = this.volunteer.training_types;
      var value = "";

      trainings.map((val) => {
        value += val.name + " (" + val.pivot.date + "), ";
      });

      return value;
    },
    gender() {
      var value = "";
      if (this.volunteer.gender == 1) {
        value = "Male";
      } else if (this.volunteer.gender == 2) {
        value = "Female";
      }
      return value;
    },
    history() {
      var value = "";

      if (this.volunteer.tb_history == 1) {
        value = "Yes";
      } else {
        value = "No";
      }

      return value;
    },
    experience() {
      var value = "";

      if (this.volunteer.experience == 1) {
        value = "Yes";
      } else {
        value = "No";
      }

      return value;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.eachvolunteer {
  padding-top: 30px;
}

.eachvolunteer .card-content {
  padding: 10px;
}

.eachvolunteer .remark {
  padding: 10px;
}
</style>
