<template>
 
       <fragment v-if="data.tuberculinskin">             
                               
                                <td >{{ done }}</td>
                                <td >{{ data.tuberculinskin.date }}</td>
                                <td >{{ data.tuberculinskin.size }}</td>
                                <td  >{{ result }}</td>
                               
                                
                              
        </fragment>                     
        <fragment v-else>             
                               
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                               <td>-</td>
                                
                              
        </fragment>                     
                    
                  
</template>

<script>



export default {
  name: 'Body',
  props:["data"],
  data () {
    return {
    }
  },

  computed: 
  {
     done()
     {
         if (this.data.tuberculinskin.done==1)
         {
             return "Done";
         }
         else 
         {
             return "Not Done";
         }
     },
     result()
     {
         var result=null;
          var value=parseInt(this.data.tuberculinskin.result);
          
         switch (value)
         {
             case 1: result="Negative";break;
             case 2: result="Positive";break;
             case 3: result="Missing";break;
         }

         return result;
     }
  }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



    
     
</style>
