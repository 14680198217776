<template>
  <div class="volunteer_list">
    <div>
      <br />
      <back-container link="/volunteers"></back-container>
    </div>
    <div class="center">
      <h5>Volunteer List</h5>
    </div>
    <div class="row">
      <div class="row">
        <div class="col s6 offset-s2 input-field">
          <input
            placeholder="Search here by phone,code,name"
            type="text"
            class="validate"
            @keydown.enter="searchVolunteer"
            v-model="search"
          />
        </div>
        <div class="col s2">
          <br />
          <button @click="excelExport" class="btn green darken-3">
            Excel Export
          </button>
        </div>
      </div>
    </div>
    <div class="row center">
      <div class="card card-content">
        <table class="app-table responsive-table">
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Code</th>
              <th>Phone</th>
              <th>Village</th>
              <th>Ward</th>
              <th>Education</th>
              <th>Occupation</th>
              <th>PICTS and MDR</th>
              <th>Phone Provided</th>
              <th>Actions</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(volunteer, index) in volunteers">
              <td>{{ index + 1 }}</td>
              <td>{{ volunteer.name }}</td>
              <td>{{ volunteer.code }}</td>
              <td>{{ volunteer.phone }}</td>
              <td>{{ volunteer.village }}</td>
              <td>{{ volunteer.ward }}</td>
              <td>{{ volunteer.education_status && volunteer.education_status.name }}</td>
              <td>{{ volunteer.occupation && volunteer.occupation.name }}</td>
              <td>
                <span v-if="volunteer.is_in_picts== 1 && volunteer.is_in_mdr== 1">Yes</span>
                <span v-else>No</span>
              </td>
              <td>
                <span v-if="volunteer.phone_provided == 0">No</span>
                <span v-else-if="volunteer.phone_provided == 1">Yes</span>
              </td>

              <td>
                <router-link
                  :to="{ name: 'VolunteerView', params: { id: volunteer.id } }"
                >
                  <i class="material-icons">navigate_next</i>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row center" style="margin-top:10px;">
          <loading v-if="loading"></loading>
        </div>
      </div>
      <div
        class="row center"
        v-if="!loading && volunteers.length < volunteer_count"
      >
        <button class="btn blue darken-2" @click="loadMore">Load More</button>
      </div>
    </div>
  </div>
</template>

<script>
import BackContainer from "@/components/ReUse/BackContainer.vue";
import Loading from "@/components/Partials/Loading.vue";
export default {
  name: "VolunteerList",
  data() {
    return {
      search: "",
    };
  },
  mounted() {
    this.$store.dispatch("clearVolunteer");
    this.getData();
  },
  methods: {
    loadMore() {
      this.getData(true);
    },
    searchVolunteer() {
      this.$router.push({
        name: "VolunteerListSearch",
        params: { search: this.search },
      });
    },
    getData(load = false) {
      var data = {
        lastid: 0,
        load: load,
      };

      if (this.volunteers.length > 0) {
        data.lastid = this.volunteers.length;
      }

      if (this.$route.params.search) {
        data.search = this.$route.params.search;
      }

      if (this.$route.query.id) {
        data.id = this.$route.query.id;
      }

      if (!data.load) {
        data.lastid = 0;
      }

    

      data.is_in_picts=1;

      this.$store.dispatch("getVolunteer", data);
    },
    excelExport() {
      this.$store.dispatch("exportVolunteerExcel");
    },
  },
  components: {
    loading: Loading,
    "back-container": BackContainer,
  },
  computed: {
    volunteers() {
      return this.$store.getters.volunteers;
    },
    loading() {
      return this.$store.getters.volunteer_loading;
    },
    volunteer_count() {
      return this.$store.getters.volunteer_count;
    },
  },
  watch: {
    $route(to, from) {
      this.getData();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.volunteer_list {
  padding-left: 20px;
  padding-right: 20px;
}

.volunteer_list .card-content {
  padding: 10px;
}
</style>
