<template>
    <div class="fill">
      <div class="center" v-if="patient_loading">
        <loading></loading>
      </div>
      <section v-else>
        <div class="row">
          <div class="btn-box">
            <div>
              <router-link :to="'/tpt-patient/new'" class="btn green darken-2"
                >New Patient</router-link
              >
              <router-link :to="'/tpt-patients/'" class="btn green darken-2"
                >All Patient</router-link
              >
            </div>
            <button
              class="btn blue darken-3"
              :disabled="updateloading"
              @click="changeTab(1)"
            >
              TPT Info
            </button>
            <fragment>
              <button
                v-bind:class="{
                  blue: patient?.sputum,
                  'required-box': patient?.sputum
                    ? patient?.sputum.result == 4
                    : false,
                  'yellow black-text': !patient.sputum,
                }"
                class="btn blue darken-3"
                :disabled="updateloading"
                @click="changeTab(3)"
              >
                Sputum
              </button>
              <button
                v-bind:class="{
                  blue: patient?.chest_xray,
                  'required-box': patient?.chest_xray
                    ? patient?.chest_xray.result == 4
                    : false,
                  'yellow black-text': !patient?.chest_xray,
                }"
                class="btn darken-3"
                :disabled="updateloading"
                @click="changeTab(2)"
              >
                Chest X-ray
              </button>
             
              <button
                v-bind:class="{
                  blue: patient?.gene_xpert,
                  'required-box': patient?.gene_xpert
                    ? patient?.gene_xpert.result == 7
                    : false,
                  'yellow black-text': !patient?.gene_xpert,
                }"
                class="btn blue darken-3"
                :disabled="updateloading"
                @click="changeTab(4)"
              >
                Gene Xpert
              </button>
              <button
                class="btn blue darken-3"
                :disabled="updateloading"
                @click="changeTab(5)"
                v-bind:class="{
                  blue: patient?.tuberculinskin,
                  'required-box': patient?.tuberculinskin
                    ? patient?.tuberculinskin.result == 4
                    : false,
                  'yellow black-text': !patient?.tuberculinskin,
                }"
              >
                Tuberculin Skin
              </button>
              
            </fragment>
            <button
            class="btn blue darken-3"
            :disabled="updateloading"
            @click="changeTab(10)"
          >
            Other Investigation
          </button>
            <button
              class="btn blue darken-3"
              :disabled="updateloading"
              @click="changeTab(9)"
            >
              TB Conclusion
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col m6 offset-m3">
            <personal-info v-if="active == 1"></personal-info>
            <chest-xray v-if="active == 2"></chest-xray>
            <sputum v-if="active == 3"></sputum>
            <gene-xpert v-if="active == 4"></gene-xpert>
            <tuber-culin-skin v-if="active == 5"></tuber-culin-skin>
            <tb-conclusion v-if="active == 9"></tb-conclusion>
            <other-investigation v-if="active == 10"></other-investigation>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import TptPersonalInfo from "@/components/Patient/Fill/TptPersonalInfo.vue";
  import Loading from "@/components/Partials/Loading.vue";
  import ChestXray from "@/components/Patient/Fill/ChestXray/ChestXray.vue";
  import Sputum from "@/components/Patient/Fill/Sputum/Sputum.vue";
  import SputumCulture from "@/components/Patient/Fill/SputumCulture/SputumCulture.vue";
  import GeneXpert from "@/components/Patient/Fill/GeneXpert/GeneXpert.vue";
  import TuberCulinSkin from "@/components/Patient/Fill/TuberCulinSkin/TuberCulinSkin.vue";
  import FNAC from "@/components/Patient/Fill/FNAC/FNAC.vue";
  import DrugSusceptibility from "@/components/Patient/Fill/DrugSusceptibility/DrugSusceptibility.vue";
  import TBConclusion from "@/components/Patient/Fill/TBConclusion/TBConclusion.vue";
  import OtherInvestigation from "@/components/Patient/Fill/OtherInvestigation/OtherInvestigation.vue";
  
  export default {
    name: "TptPatientFill",
    data() {
      return {
        active: 1,
      };
    },
    mounted() {
      if (this.$route.params.id) {
        var data = {};
        data.status = 3;
        data.id = this.$route.params.id;
        data.load = false;
        data.lastid = 0;
        data.tptStatus = 1
        localStorage.setItem("current_tag", 1);
        localStorage.removeItem("current_data");
        this.$store.dispatch("getPatients", data);
      }
    },
    beforeDestroy() {
      localStorage.removeItem("current_data");
      localStorage.removeItem("current_tag");
    },
    methods: {},
    components: {
      "personal-info": TptPersonalInfo,
      "chest-xray": ChestXray,
      loading: Loading,
      sputum: Sputum,
      "gene-xpert": GeneXpert,
      "tuber-culin-skin": TuberCulinSkin,
      "tb-conclusion": TBConclusion,
      "other-investigation": OtherInvestigation,
    },
    methods: {
      changeTab(id) {
        var current_data = localStorage.getItem("current_data");
        if (current_data != "changed") {
          var data = this.getDataByTab(
            parseInt(localStorage.getItem("current_tag"))
          );
          this.$store.dispatch("checkPatientFill", { data: data });
          if (this.fill_warning) {
            alert("You have changed");
          } else {
            if (localStorage.getItem("current_tag") != id) {
              this.active = id;
              localStorage.setItem("current_tag", this.active);
              localStorage.removeItem("current_data");
            }
          }
        } else {
          alert("You have changed");
        }
      },
      getDataByTab(tab) {
        if (tab == 1) {
          return this.patient;
        } else if (tab == 2) {
          return this.patient.chest_xray;
        } else if (tab == 3) {
          return this.patient.sputum;
        } else if (tab == 4) {
          return this.patient.gene_xpert;
        } else if (tab == 5) {
          return this.patient.tuberculinskin;
        } else if (tab == 6) {
          return this.patient.fnac;
        } else if (tab == 7) {
          return this.patient.sputum_culture;
        } else if (tab == 8) {
          return this.patient.drug_suscept;
        } else if (tab == 9) {
          return this.patient.tb_conclusion;
        } else if (tab == 10) {
          return this.patient.other_investigation;
        }
      },
    },
    computed: {
      patient_loading() {
        return this.$store.getters.loading;
      },
      updateloading() {
        return this.$store.getters.updateloading;
      },
      patient() {
        var item = localStorage.getItem("current_data");
        var data = this.$store.getters.patient;
        if (!item) {
          localStorage.setItem("current_data", JSON.stringify(data));
        }
        console.log(data);
        return data;
      },
      fill_warning() {
        return this.$store.getters.fill_warning;
      },
      patient_fill_data() {
        return this.$store.getters.patient_fill_data;
      },
      patient_fill_finish() {
        return this.$store.getters.patient_fill_finish;
      },
      allpassforConclusion() {
        var data = this.patient;
        if (data.chest_xray && data.sputum && data.gene_xpert && data.fnac) {
          return true;
        } else {
          return false;
        }
      },
      patient_update_success() {
        return this.$store.getters.patient_update_success;
      },
    },
    watch: {
      patient_fill_finish(val) {
        if (val) {
          this.$notify({
            group: "noti",
            type: "success",
            text: "Updated",
          });
          this.$store.dispatch("changePatientFillData", {
            id: this.patient_fill_data,
            patient: this.patient,
          });
        }
      },
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .fill {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
  }
  .fill .card {
    padding: 20px;
    background: var(--card-background-color) !important;
  }
  .btn-box {
    padding: 20px;
  }
  button {
    margin-top: 10px;
  }
  
  .required-box {
    background: rgb(117, 83, 18) !important;
  }
  </style>
  