doc-tool-tip<template>
  <div class="Result">
      
      <div class="row" v-if="loading">
          <div class="col m8 offset-m2" style="padding-top:100px;">
                <div class="progress">
                    <div class="indeterminate"></div>
                </div>
           </div>
      </div>
      <div class="row" v-else>

        <div class="col m8 offset-m2" >
            <div class="center" style="margin-bottom:60px;">
                    <div class="col s6">
                        <h6>{{getMonth($route.query.month)}}/{{$route.query.year}} Report</h6>
                    </div>
                    <div class="col s6">
                        
                        <button @click="exportPAReport" class="btn green darken-2">Export as Excel</button>
                        <back-container link="/pareport"></back-container>
                    </div>
                
            </div>
            
            <table class="app-table">
                <thead>
                    
                    <tr>
                        <th>No</th><th>Indicators</th><th>Male</th><th>Female</th><th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td >No of volunteers who receive refresher training <doc-tool-tip :index="1"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_refresher.male }}</td>
                        <td class="text-right">{{ pa_report.noof_refresher.female }}</td>
                        <td class="text-right">{{ pa_report.noof_refresher.total }}</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>No. of newly trained volunteers (formal training) <doc-tool-tip :index="2"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_new.male }}</td>
                        <td class="text-right">{{ pa_report.noof_new.female }}</td>
                        <td class="text-right">{{ pa_report.noof_new.total }}</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>No. of newly trained volunteers (on-job training) <doc-tool-tip :index="3"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_onjob.male }}</td>
                        <td class="text-right">{{ pa_report.noof_onjob.female }}</td>
                        <td class="text-right">{{ pa_report.noof_onjob.total }}</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>No. of total newly trained volunteers <doc-tool-tip :index="4"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_total_newly_trained.male }}</td>
                        <td class="text-right">{{ pa_report.noof_total_newly_trained.female }}</td>
                        <td class="text-right">{{ pa_report.noof_total_newly_trained.total }}</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>No. of reported volunteers <doc-tool-tip :index="5"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.reported_volunteers_male }}</td>
                        <td class="text-right">{{ pa_report.reported_volunteers_female }}</td>
                        <td class="text-right">
                            <span v-if="parseInt((pa_report.reported_volunteers_male)+parseInt(pa_report.reported_volunteers_female))>0">
                                <router-link :to="'/reported_volunteer/'+$route.query.month+'/'+$route.query.year" style="color:blue;text-decoration:underline;">{{ parseInt(pa_report.reported_volunteers_male)+parseInt(pa_report.reported_volunteers_female) }}</router-link>
                            </span>
                            <span v-else>0</span>
                        </td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>No. of presumptive TB cases referred by volunteers for examination (<15) <doc-tool-tip :index="6"></doc-tool-tip> </td>
                        <td class="text-right">{{ pa_report.noof_tb_cases_by_volunteer_u15.male }}</td>
                        <td class="text-right">{{ pa_report.noof_tb_cases_by_volunteer_u15.female }}</td>
                        <td class="text-right">{{ pa_report.noof_tb_cases_by_volunteer_u15.total }}</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>No. of presumptive TB cases referred by volunteers for examination (≥15) <doc-tool-tip :index="7"></doc-tool-tip></td>
                       <td class="text-right">{{ pa_report.noof_tb_cases_by_volunteer_15.male }}</td>
                        <td class="text-right">{{ pa_report.noof_tb_cases_by_volunteer_15.female }}</td>
                        <td class="text-right">{{ pa_report.noof_tb_cases_by_volunteer_15.total }}</td>
                    </tr>
                     <tr>
                        <td>8</td>
                        <td>No.of presumptive TB cases examined  (<15) <doc-tool-tip :index="8"></doc-tool-tip></td>
                       <td class="text-right">{{ pa_report.noof_monthly_tbcases_u15.male }}</td>
                        <td class="text-right">{{ pa_report.noof_monthly_tbcases_u15.female }}</td>
                        <td class="text-right">{{ pa_report.noof_monthly_tbcases_u15.total }}</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>No.of presumptive TB cases examined  (≥15) <doc-tool-tip :index="9"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_monthly_tbcases_15.male }}</td>
                        <td class="text-right">{{ pa_report.noof_monthly_tbcases_15.female }}</td>
                        <td class="text-right">{{ pa_report.noof_monthly_tbcases_15.total }}</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>No. of presumptive TB cases referred by volunteers for examination <doc-tool-tip :index="10"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.total_of_tbcases_by_volunteers.male }}</td>
                        <td class="text-right">{{ pa_report.total_of_tbcases_by_volunteers.female }}</td>
                        <td class="text-right">{{ pa_report.total_of_tbcases_by_volunteers.total }}</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>No.of presumptive TB cases examined <doc-tool-tip :index="11"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.total_monthly_tbcases.male }}</td>
                        <td class="text-right">{{ pa_report.total_monthly_tbcases.female }}</td>
                        <td class="text-right">{{ pa_report.total_monthly_tbcases.total }}</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>No. of Bacteriologcally confirmed TB patients referred by volunteers <doc-tool-tip :index="12"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_bc_tb_by_volunteer.male }}</td>
                        <td class="text-right">{{ pa_report.noof_bc_tb_by_volunteer.female }}</td>
                        <td class="text-right">{{ pa_report.noof_bc_tb_by_volunteer.total }}</td>
                    </tr>
                     <tr>
                        <td>13</td>
                        <td>No. of Total TB patients referred by volunteers <doc-tool-tip :index="13"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_total_tb_patients.male }}</td>
                        <td class="text-right">{{ pa_report.noof_total_tb_patients.female }}</td>
                        <td class="text-right">{{ pa_report.noof_total_tb_patients.total }}</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>No. of New TB patients referred by volunteers <doc-tool-tip :index="14"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_new_tb_patients.male }}</td>
                        <td class="text-right">{{ pa_report.noof_new_tb_patients.female }}</td>
                        <td class="text-right">{{ pa_report.noof_new_tb_patients.total }}</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>No. of RelapseTB patients referred by volunteers <doc-tool-tip :index="15"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_relapse_tb_patients.male }}</td>
                        <td class="text-right">{{ pa_report.noof_relapse_tb_patients.female }}</td>
                        <td class="text-right">{{ pa_report.noof_relapse_tb_patients.total }}</td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>No. of GPs contacted in this month <doc-tool-tip :index="16"></doc-tool-tip></td>
                        <td class="blank_page"></td>
                        <td class="blank_page"></td>
                        <td class="text-right">{{ pa_report.gp_count }}</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>No. of presumptive TB cases referred and examined by GP <doc-tool-tip :index="17"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.total_gp_refer.male }}</td>
                        <td class="text-right">{{ pa_report.total_gp_refer.female }}</td>
                        <td class="text-right">{{ pa_report.total_gp_refer.total }}</td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>No. of TB patients among presumptive TB cases referred by GP <doc-tool-tip :index="18"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_gp_refer_tb_patient.male }}</td>
                        <td class="text-right">{{ pa_report.noof_gp_refer_tb_patient.female }}</td>
                        <td class="text-right">{{ pa_report.noof_gp_refer_tb_patient.total }}</td>
                    </tr>
                     <tr>
                        <td>19</td>
                        <td>No. of TB patients who tested HIV Testing (GP+Vol) <doc-tool-tip :index="19"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_hivtested_patient.male }}</td>
                        <td class="text-right">{{ pa_report.noof_hivtested_patient.female }}</td>
                        <td class="text-right">{{ pa_report.noof_hivtested_patient.total }}</td>
                    </tr>
                     <tr>
                        <td>20</td>
                        <td>No. of HIV Positive TB patients (GP+Vol) <doc-tool-tip :index="20"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_hivpositive_patient.male }}</td>
                        <td class="text-right">{{ pa_report.noof_hivpositive_patient.female }}</td>
                        <td class="text-right">{{ pa_report.noof_hivpositive_patient.total }}</td>
                    </tr>
                    <tr>
                        <td>21</td>
                        <td>No. of old cohort index TB patients receiving contact investigation <doc-tool-tip :index="21"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_old_cohort.male }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort.female }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort.total }}</td>
                    </tr>
                    <tr>
                        <td>22</td>
                        <td>No. of bact; confirmed old cohort index TB patients receiving contact investigation <doc-tool-tip :index="22"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_bc.male }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_bc.female }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_bc.total }}</td>
                    </tr>
                    <tr>
                        <td>23</td>
                        <td>No. of household members (Old Cohort) identified <doc-tool-tip :index="23"></doc-tool-tip></td>
                        <td class="blank_page"></td>
                        <td class="blank_page"></td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_household }}</td>
                    </tr>
                    <tr>
                        <td>24</td>
                        <td>No. of household members (contacts) identified (Old Cohort) (<5) <doc-tool-tip :index="24"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_household_u5.male }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_household_u5.female }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_household_u5.total }}</td>
                    </tr>
                     <tr>
                        <td>25</td>
                        <td>No. of household members (contacts) identified (Old Cohort) (≥5) <doc-tool-tip :index="25"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_household_eq5.male }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_household_eq5.female }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_household_eq5.total }}</td>
                    </tr>
                    <tr>
                        <td>26</td>
                        <td>No. of closed members (contacts) identified (Old Cohort) (<5) <doc-tool-tip :index="26"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_closed_u5.male }}</td>
                        <td class="text-right" >{{ pa_report.noof_old_cohort_closed_u5.female }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_closed_u5.total }}</td>
                    </tr>
                     <tr>
                        <td>27</td>
                        <td>No. of closed members (contacts) identified (Old Cohort) (≥5) <doc-tool-tip :index="27"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_closed_eq5.male }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_closed_eq5.female }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_closed_eq5.total }}</td>
                    </tr>
                    <tr>
                        <td>28</td>
                        <td>No. of contacts of old cohort index TB patients screened for TB symptoms <doc-tool-tip :index="28"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_tb_patients.male }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_tb_patients.female }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_tb_patients.total }}</td>
                    </tr>
                     <tr>
                        <td>29</td>
                        <td>No. of presumptive (old cohort)TB contacts referred <doc-tool-tip :index="29"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_tb_contacts_refered.male }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_tb_contacts_refered.female }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_tb_contacts_refered.total }}</td>
                    </tr>
                    <tr>
                        <td>30</td>
                        <td>No. of presumptive (old cohort)TB contacts examined <doc-tool-tip :index="30"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_tb_contacts_examined.male }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_tb_contacts_examined.female }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_tb_contacts_examined.total }}</td>
                    </tr>
                    <tr>
                        <td>31</td>
                        <td>No. of presumptive (old cohort)TB contacts diagnosed as TB <doc-tool-tip :index="31"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_tb_contacts_tbcases.male }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_tb_contacts_tbcases.female }}</td>
                        <td class="text-right">{{ pa_report.noof_old_cohort_tb_contacts_tbcases.total }}</td>
                    </tr>
                     <tr>
                        <td>32</td>
                        <td>No. of current cohort index TB patients receiving contact investigation <doc-tool-tip :index="32"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_current_cohort.male }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort.female }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort.total }}</td>
                    </tr>
                    <tr>
                        <td>33</td>
                        <td>No. of bact; confirmed current cohort index TB patients receiving contact investigation <doc-tool-tip :index="33"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_bc.male }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_bc.female }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_bc.total }}</td>
                    </tr>
                    <tr>
                        <td>34</td>
                        <td>No. of household members (New Cohort) <doc-tool-tip :index="34"></doc-tool-tip></td>
                        <td class="blank_page"></td>
                        <td class="blank_page"></td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_household }}</td>
                    </tr>
                    <tr>
                        <td>35</td>
                        <td>No. of household members (contacts) identified (New Cohort) (<5) <doc-tool-tip :index="35"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_household_u5.male }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_household_u5.female }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_household_u5.total }}</td>
                    </tr>
                    <tr>
                        <td>36</td>
                        <td>No. of household members (contacts) identified (New Cohort) (≥5) <doc-tool-tip :index="36"></doc-tool-tip></td>
                        <td class="text-right" >{{ pa_report.noof_current_cohort_household_eq5.male }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_household_eq5.female }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_household_eq5.total }}</td>
                    </tr>
                    <tr>
                        <td>37</td>
                        <td>No. of closed members (contacts) identified (New Cohort) (<5) <doc-tool-tip :index="37"></doc-tool-tip></td>
                        <td  class="text-right">{{ pa_report.noof_current_cohort_closed_u5.male }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_closed_u5.female }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_closed_u5.total }}</td>
                    </tr>
                    <tr>
                        <td>38</td>
                        <td>No. of closed members (contacts) identified (New Cohort) (≥5) <doc-tool-tip :index="38"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_closed_eq5.male }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_closed_eq5.female }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_closed_eq5.total }}</td>
                    </tr>
                    <tr>
                        <td>39</td>
                        <td>No. of contacts of current cohort index TB patients screened for TB symptoms <doc-tool-tip :index="39"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_tb_patients.male }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_tb_patients.female }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_tb_patients.total }}</td>
                    </tr>
                    <tr>
                        <td>40</td>
                        <td>No. of presumptive (current cohort)TB contacts referred <doc-tool-tip :index="40"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_tb_contacts_refered.male }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_tb_contacts_refered.female }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_tb_contacts_refered.total }}</td>
                    </tr>
                    <tr>
                        <td>41</td>
                        <td>No. of presumptive (current cohort)TB contacts examined <doc-tool-tip :index="41"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_tb_contacts_examined.male }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_tb_contacts_examined.female }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_tb_contacts_examined.total }}</td>
                    </tr>
                    <tr>
                        <td>42</td>
                        <td>No. of presumptive (current cohort)TB contacts diagnosed as TB <doc-tool-tip :index="42"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_tb_contacts_tbcases.male }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_tb_contacts_tbcases.female }}</td>
                        <td class="text-right">{{ pa_report.noof_current_cohort_tb_contacts_tbcases.total }}</td>
                    </tr>
                    <tr>
                        <td>43</td>
                        <td>No. of health education sessions conducted by volunteers <doc-tool-tip :index="43"></doc-tool-tip></td>
                        <td class="blank_page"></td>
                        <td class="blank_page"></td>
                        <td class="text-right">{{ pa_report.noof_health_sessions }}</td>
                    </tr>
                    <tr>
                        <td>44</td>
                        <td>No. of health education attendees in this township <doc-tool-tip :index="44"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_health_sessions_attendence.male }}</td>
                        <td class="text-right">{{ pa_report.noof_health_sessions_attendence.female }}</td>
                        <td class="text-right">{{ pa_report.noof_health_sessions_attendence.total }}</td>
                    </tr>
                    <tr>
                        <td>45</td>
                        <td>No. of presumptive TB cases examined from HE sessions <doc-tool-tip :index="45"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_he_tbcase.male }}</td>
                        <td class="text-right">{{ pa_report.noof_he_tbcase.female }}</td>
                        <td class="text-right">{{ pa_report.noof_he_tbcase.total }}</td>
                    </tr>
                    <tr>
                        <td>46</td>
                        <td>No. of TB patients referred by volunteers during HE sessions <doc-tool-tip :index="46"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_he_tbpatient.male }}</td>
                        <td class="text-right">{{ pa_report.noof_he_tbpatient.female }}</td>
                        <td class="text-right">{{ pa_report.noof_he_tbpatient.total }}</td>
                    </tr>
                    <tr>
                        <td>47</td>
                        <td>No. of mini mobile clinics conducted in this township <doc-tool-tip :index="47"></doc-tool-tip></td>
                        <td class="blank_page"></td>
                        <td class="blank_page"></td>
                        <td class="text-right">{{ pa_report.mini_mobile_count }}</td>
                    </tr>
                    <tr>
                        <td>48</td>
                        <td>No. of mini mobile clinic attendees in this township <doc-tool-tip :index="48"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.mini_mobile_attendence.male }}</td>
                        <td class="text-right">{{ pa_report.mini_mobile_attendence.female }}</td>
                        <td class="text-right">{{ pa_report.mini_mobile_attendence.total }}</td>
                    </tr>
                     <tr>
                        <td>49</td>
                        <td>No. of presumptive TB cases examined from mini mobile clinic <doc-tool-tip :index="49"></doc-tool-tip></td>
                         <td class="text-right">{{ pa_report.mini_mobile_tb_cases.male }}</td>
                        <td class="text-right">{{ pa_report.mini_mobile_tb_cases.female }}</td>
                        <td class="text-right">{{ pa_report.mini_mobile_tb_cases.total }}</td>
                    </tr>
                    <tr>
                        <td>50</td>
                        <td>No. of TB patients referred from mini mobile clinics <doc-tool-tip :index="50"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.mini_mobile_tb_patients.male }}</td>
                        <td class="text-right">{{ pa_report.mini_mobile_tb_patients.female }}</td>
                        <td class="text-right">{{ pa_report.mini_mobile_tb_patients.total }}</td>
                    </tr>
                    <tr>
                        <td>51</td>
                        <td>No. of special outreach programs conducted in this township <doc-tool-tip :index="51"></doc-tool-tip></td>
                        <td class="blank_page"></td>
                        <td class="blank_page"></td>
                        <td class="text-right">{{ pa_report.noof_sop_count }}</td>
                    </tr>
                    <tr>
                        <td>52</td>
                        <td>No. of special outreach program attendees in this township <doc-tool-tip :index="52"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.sop_attendes.male }}</td>
                        <td class="text-right">{{ pa_report.sop_attendes.female }}</td>
                        <td class="text-right">{{ pa_report.sop_attendes.total }}</td>
                    </tr>
                    <tr>
                        <td>53</td>
                        <td>No. of TB patients on DOTS provided by volunteers <doc-tool-tip :index="53"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.no_of_dots_update.male }}</td>
                        <td class="text-right">{{ pa_report.no_of_dots_update.female }}</td>
                        <td class="text-right">{{ pa_report.no_of_dots_update.total }}</td>
                    </tr>
                    <tr>
                        <td>54</td>
                        <td>No. of TB patients who completed DOTS <doc-tool-tip :index="54"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.no_of_dots_complete.male }}</td>
                        <td class="text-right">{{ pa_report.no_of_dots_complete.female }}</td>
                        <td class="text-right">{{ pa_report.no_of_dots_complete.total }}</td>
                    </tr>
                    <tr>
                        <td>55</td>
                        <td>No. of TB patients who does not complete DOTS <doc-tool-tip :index="55"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.no_of_dots_not_complete.male }}</td>
                        <td class="text-right">{{ pa_report.no_of_dots_not_complete.female }}</td>
                        <td class="text-right">{{ pa_report.no_of_dots_not_complete.total }}</td>
                    </tr>
                    <tr>
                        <td>56</td>
                        <td>No. of new bacteriologically confirmed TB cases diagnosed in this township <doc-tool-tip :index="56"></doc-tool-tip></td>
                         <td class="text-right">{{ pa_report.noof_tb_new_2BC.male }}</td>
                        <td class="text-right">{{ pa_report.noof_tb_new_2BC.female }}</td>
                        <td class="text-right">{{ pa_report.noof_tb_new_2BC.total }}</td>
                    </tr>
                    <tr>
                        <td>57</td>
                        <td>No. of total bacteriologically confirmed TB cases diagnosed in this township <doc-tool-tip :index="57"></doc-tool-tip></td>
                        <td class="text-right">{{ pa_report.noof_tb_2BC.male }}</td>
                        <td class="text-right">{{ pa_report.noof_tb_2BC.female }}</td>
                        <td class="text-right">{{ pa_report.noof_tb_2BC.total }}</td>
                    </tr>
                    <tr>
                        <td>58</td>
                        <td>No. ot total TB cases diagnosed in this township <doc-tool-tip :index="58"></doc-tool-tip></td>
                         <td class="text-right">{{ pa_report.noof_tb.male }}</td>
                        <td class="text-right">{{ pa_report.noof_tb.female }}</td>
                        <td class="text-right">{{ pa_report.noof_tb.total }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
          
         
  </div>
</template>

<script>
import BackContainer from '@/components/ReUse/BackContainer.vue';
import DocToolTip from './DocToolTip.vue';
export default {
  name: 'Result',
  data () {
    return {
     
    }
  },
  mounted()
  {
     
      var month=this.$route.query.month;
      var year=this.$route.query.year;
      
      this.$store.dispatch("generatePAReport",{month:month,year:year});

  },
  updated()
  {
      var elems = document.querySelectorAll('.tooltipped');
      var instances = M.Tooltip.init(elems, {});
  },
  components: 
  {
    "back-container":BackContainer,
    "doc-tool-tip":DocToolTip
  },
  computed: 
  {
      pa_report()
      {
          return this.$store.getters.pa_report;
      },
       loading()
      {
        return this.$store.getters.pa_report_loading;
      }
  },
  methods: 
  {
     exportPAReport()
     {
         var month=this.$route.query.month;
         var year=this.$route.query.year;
         this.$store.dispatch("exportPAReport",{month:month,year:year});
     },
     getMonth(data)
     {
         if (data=="q1")
         {
             return "Quater 1";
         }
         else if (data=="q2")
         {
             return "Quater 2";
         }
         else if (data=="q3")
         {
             return "Quater 3";
         }
         else if (data=="q4")
         {
             return "Quater 4";
         }
         else if (data=="annual")
         {
             return "Annual";
         }
         else 
         {
             return data;
         }


     }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .Result 
    {
        padding:20px;
    }

   

    .text-right 
    {
        text-align: right;
    }

    .blank_page 
    {
        background:black;
    }

    




</style>
