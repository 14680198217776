<template>
  <div v-if="chest_xray" class="card card-content">
    <h6>Chest X-ray</h6>
    <form>
      <div class="row">
        <div class="input-field col s12">
          <div class="switch">
            <label>
              Not Done
              <input
                type="checkbox"
                @click="switchChange"
                :checked="chest_xray.done == 1"
              />
              <span class="lever"></span>
              Done
            </label>
          </div>
        </div>
        <div class="input-field col s12">
          <span>Date of chest X-ray examination</span>
          <input
            :disabled="chest_xray.done != 1"
            type="text"
            placeholder="YYYY-MM-DD"
            v-model.lazy="chest_xray.date"
            class="datepicker"
            readonly
          />
        </div>
        <div class="input-field col s12" v-show="chest_xray.done == 1">
          <span>Result of chest X-ray</span>
          <select v-model="chest_xray.result">
            <option value="" disabled selected>Choose your option</option>
            <option value="1">Normal</option>
            <option value="2">Abnormal</option>
            <option value="5">TB Suspect</option>
            <option value="6">Active Koch's Lungs</option>
            <option value="7">Healed</option>
            <option value="8">Other</option>
            <option value="3">Missing</option>
            <option value="4">Pending</option>
          </select>
        </div>

        <div class="row center" v-if="buttonpass">
          <button
            type="button"
            class="btn blue darken-3"
            @click="submit"
            :disabled="updateloading"
          >
            Edit
          </button>
        </div>
      </div>
    </form>
  </div>
  <new-chest-xray v-else></new-chest-xray>
</template>

<script>
import NewChestXray from "@/components/Patient/Fill/ChestXray/NewChestXray.vue";
export default {
  name: "ChestXray",
  data() {
    return {
      done: 0,
      date: "",
      result: "",
      temp: null,
    };
  },
  components: {
    "new-chest-xray": NewChestXray,
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },

  watch: {},
  methods: {
    switchChange() {
      this.chest_xray.done = this.chest_xray.done == 0 ? 1 : 0;
    },

    submit() {
      var data = {
        done: this.chest_xray.done,
        date: null,
        result: null,
      };

      if (data.done) {
        data.date = this.chest_xray.date;
        data.result = this.chest_xray.result;
      }

      data.id = this.patient.id;

      this.$store.dispatch("submitChestXRay", data);
    },
  },

  computed: {
    patient() {
      return this.$store.getters.patient;
    },

    chest_xray() {
      var item = localStorage.getItem("current_data");
      var data = this.$store.getters.patient.chest_xray;
      if (!item) {
        localStorage.setItem("current_data", JSON.stringify(data));
      }
      return data;
    },

    updateloading() {
      return this.$store.getters.updateloading;
    },

    buttonpass() {
      if (this.chest_xray.done == 1) {
        if (this.chest_xray.result && this.chest_xray.date) {
          return true;
        } else if (this.chest_xray.result == 4) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fill {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

.fill .card {
  padding: 20px;
}
</style>
