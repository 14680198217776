<template>
 
       <fragment>             
                               
                                <th>Tsttest</th>
                                <th>Tstdate</th>
                                <th>Tstsize</th>
                                <th>Tstres</th>
                            
                              
        </fragment>                     
                    
                  
</template>

<script>



export default {
  name: 'Header',
  data () {
    return {
    }
  },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

     
</style>
