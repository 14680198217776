<template>
  <section>
    <div v-if="loading">
      <pre-loader></pre-loader>
    </div>
    <div class="row" v-else>
      <div
        class="col m8 offset-m2"
        v-show="
          dots_visit_reports.patient.finish != 1 &&
          dots_visit_reports.patient.finish != 2 &&
          dots_visit_reports.patient.dots_app_status == 0
        "
      >
        <div class="card card-content">
          <div class="row">
            <div class="col m4">
              <span>Month</span>
              <select v-model="month">
                <option v-for="index in 12" :value="index" v-bind:key="index">
                  {{ index }}
                </option>
              </select>
            </div>
            <div class="col m4">
              <span>Year</span>
              <input type="number" v-model="year" placeholder="Year ...." />
            </div>
            <div class="col m4">
              <span>Completed DOT supervision (Y/N)</span>
              <select v-model="finish">
                <option value="1">No</option>
                <option value="2">Yes</option>
                <option value="3">On-Going</option>
              </select>
            </div>
          </div>
          <div class="row" v-if="finish == 1 || finish == 2">
            <div class="col m4">
              <span>DOTS End Date</span>
              <input
                type="text"
                class="datepicker"
                v-model.lazy="dots_enddate"
                placeholder="DOTS END DATE"
              />
            </div>
          </div>
          <div class="row">
            <div class="col m12 center">
              <br />
              <button :disabled="action_loading" @click="save" class="btn blue darken-3">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col m8 offset-m2">
        <div class="card card-content">
          <table class="app-table">
            <thead>
              <th>No</th>
              <th>Month</th>
              <th>Year</th>
              <th>Completed DOT supervision (Y/N)</th>
              <th>Volunteer</th>
              <th>Date</th>
            </thead>
            <tbody>
              <tr v-for="(item, index) in dots_visit_reports.reports" v-bind:key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.month }}</td>
                <td>{{ item.year }}</td>
                <td>{{ finishState(item.finish) }}</td>
                <td>{{ item.volunteer.code }} ({{ item.volunteer.name }})</td>
                <td>{{ item.updated_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      month: "",
      year: "",
      finish: "",
      dots_enddate: "",
    };
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
    this.$store.dispatch("getDOTSVisitReport", {
      id: this.$route.params.id,
    });
  },
  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  methods: {
    save() {
      if (this.month && this.year) {
        if (this.checkValid()) {
          this.$store.dispatch("saveDOTSVisitReport", {
            month: this.month,
            year: this.year,
            dots_patient_id: this.$route.params.id,
            finish: this.finish,
            dots_enddate: this.dots_enddate,
          });
        }
      } else {
        alert("Some fields are required");
      }
    },
    checkValid() {
      var result = true;
      var date = new Date();

      if (this.month > date.getMonth() + 1) {
        if (this.year==date.getFullYear())
        {
          return false;
        }
      }

      if (this.year > date.getFullYear()) {
        alert("Your year is larger than current year");
        return false;
      }

      if (parseInt(this.finish) != 3 && this.dots_enddate.length == 0) {
        alert("Dots Enddate is required");
        return false;
      }

      var startMonth =
        new Date(this.dots_visit_reports.patient.dots_startdate).getMonth() + 1;
      var startYear = new Date(
        this.dots_visit_reports.patient.dots_startdate
      ).getFullYear();
      
      if (this.year <= startYear) {
        if (this.month < startMonth) {
          alert("Your selected month is less than DOTS started month");
          return false;
        }
      }

      return result;
    },
    finishState(status) {
      switch (parseInt(status)) {
        case 1:
          return "No";
          break;
        case 2:
          return "Yes";
          break;
        case 3:
          return "On-going";
          break;
      }
    },
  },
  computed: {
    action_loading() {
      return this.$store.getters.dots_action_loading;
    },
    loading() {
      return this.$store.getters.dots_initial_data_loading;
    },
    dots_visit_reports() {
      return this.$store.getters.dots_visit_reports;
    },
  },
};
</script>

<style></style>
