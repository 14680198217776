import moment from "moment";
var weeks = [];

export const generateDate = (start_date, week, phase) => {
  weeks = [];
  var end_date = moment(start_date, "YYYY-MM-DD").add(week * 7 - 1, "days");
  end_date = end_date.format("YYYY-MM-DD");
  var dates = getDates(start_date, end_date);
  dates = dates.map((val) => {
    return { date: val, choosen: false };
  });
  dates = getWeek(dates, phase);

  return dates;
};

function getWeek(dates, phase) {
  var days = [];
  var counter = 0;
  var day_id = 0;
  for (var i in dates) {
    counter++;
    day_id++;

    dates[i].id = day_id;

    days.push(dates[i]);
    if (counter % 7 == 0) {
      day_id = 0;
      weeks.push(findSelected(days, phase, counter / 7));
      days = [];
    }
  }

  return weeks;
}

function findSelected(days, phase, week_index) {
  if (parseInt(phase) == 1) {
    var number_one = Math.floor(Math.random() * 3) + 1;
    var number_two = number_one + 2;
    return days.map((val, index) => {
      if (index == number_one || index == number_two) {
        val.choosen = true;
      }

      return val;
    });
  } else if (parseInt(phase) == 2) {
    var number_one = Math.floor(Math.random() * 6) + 0;

    console.log(number_one);

    return days.map((val, index) => {
      if (week_index % 2 != 0) {
        if (index == number_one) {
          val.choosen = true;
        }
      }

      return val;
    });
  }
  else if (parseInt(phase) == 3) {
    return days;
  }
}

function getDates(startDate, stopDate) {
  var dateArray = [];
  var currentDate = moment(startDate);
  var stopDate = moment(stopDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
    currentDate = moment(currentDate).add(1, "days");
  }
  return dateArray;
}

export const getChoosenDate = (week_id) => {
  return weeks[week_id].filter((val) => {
    return val.choosen;
  });
};

export const dayDiff = (date1, date2) => {
  return parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
};
