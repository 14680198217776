<template>
  <div v-if="sputum_culture" class="card card-content">
    <h6>Sputum Culture</h6>
    <form>
      <div class="row">
        <div class="input-field col s12">
          <div class="switch">
            <label>
              Not Done
              <input
                type="checkbox"
                @click="switchChange"
                :checked="sputum_culture.done == 1"
              />
              <span class="lever"></span>
              Done
            </label>
          </div>
        </div>
        <div class="input-field col s12">
          <span>Date of sputum_culture Smear examination</span>
          <input
            :disabled="sputum_culture.done != 1"
            type="text"
            placeholder="YYYY-MM-DD"
            v-model.lazy="sputum_culture.date"
            class="datepicker"
            readonly
          />
        </div>
        <div class="input-field col s12" v-show="sputum_culture.done == 1">
          <span>sputum_culture Smear Result</span>
          <select v-model="sputum_culture.result">
            <option value="" disabled selected>Choose your option</option>
            <option value="1">Positive</option>
            <option value="2">Negative</option>
            <option value="3">Missing</option>
            <option value="4">Pending</option>
          </select>
        </div>

        <div class="row center" v-if="buttonpass">
          <button
            type="button"
            class="btn blue darken-3"
            @click="submit"
            :disabled="updateloading"
          >
            Edit
          </button>
        </div>
      </div>
    </form>
  </div>
  <new-sputum-culture v-else></new-sputum-culture>
</template>

<script>
import NewSputumCulture from "@/components/Patient/Fill/SputumCulture/NewSputumCulture.vue";
export default {
  name: "SputumCulture",
  data() {
    return {
      done: false,
      date: "",
      result: "",
    };
  },
  components: {
    "new-sputum-culture": NewSputumCulture,
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  watch: {},
  methods: {
    switchChange() {
      this.sputum_culture.done = this.sputum_culture.done == 0 ? 1 : 0;
    },

    submit() {
      var data = {
        done: this.sputum_culture.done,
        date: null,
        result: null,
      };

      if (data.done) {
        data.date = this.sputum_culture.date;
        data.result = this.sputum_culture.result;
      }

      data.id = this.patient.id;

      this.$store.dispatch("submitSputumCulture", data);
    },
  },

  computed: {
    patient() {
      return this.$store.getters.patient;
    },

    sputum_culture() {
      var item = localStorage.getItem("current_data");
      var data = this.$store.getters.patient.sputum_culture;
      if (!item) {
        localStorage.setItem("current_data", JSON.stringify(data));
      }

      return data;
    },

    buttonpass() {
      if (this.sputum_culture.done == 1) {
        if (this.sputum_culture.result && this.sputum_culture.date) {
          return true;
        } else if (this.sputum_culture.result == 4) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    updateloading() {
      return this.$store.getters.updateloading;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fill {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

.fill .card {
  padding: 20px;
}
</style>
