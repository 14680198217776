<template>
  <div class="row pt-5">
    <div class="col m10 offset-m1">
      <div class="card card-content">
        <table class="app-table">
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Age</th>
              <th>Gender</th>
              <th>Phone</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="patients.length > 0">
            <tr
              v-for="(item, index) in patients"
              v-bind:key="item.id"
              v-show="!item.dotspatient"
            >
              <td>
                {{ index + 1 }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.age }}
              </td>
              <td>
                {{ item.gender }}
              </td>
              <td>
                {{ item.phone }}
              </td>
              <td>
                <router-link
                  :to="'/patient/dots/move/' + item.id"
                  class="btn blue darken-3"
                  >Move To DOTS</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from "../../../misc/server";
export default {
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      patients: [],
      loading: false,
    };
  },

  methods: {
    fetchData() {
      this.loading = true;
      getData("/dots/patient/refer-list", (data) => {
        this.patients = data.data;
        this.loading = false;
      });
    },
  },
};
</script>

<style></style>
