<template>
  <div class="CheckingBox">
    <div style="padding-left:20px;margin-bottom:15px;">
      <back-container link="/home"></back-container>
    </div>
    <div class="row">
      <div class="col m4 s12">
        <router-link to="/checkings">
          <div class="card z-depth-3">
            <div class="card-content center">
              <i class="material-icons icons">list</i>
              <h6>Presumptive Raw Data</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col m4 s12">
        <router-link to="/checkings-tpt">
          <div class="card z-depth-3">
            <div class="card-content center">
              <i class="material-icons icons">list</i>
              <h6>TPT Raw Data</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col m4 s12">
        <router-link to="/patients">
          <div class="card z-depth-3">
            <div class="card-content center">
              <i class="material-icons icons">people</i>
              <h6>Presumptive Patient List</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col m4 s12">
        <router-link to="/tpt-patients">
          <div class="card z-depth-3">
            <div class="card-content center">
              <i class="material-icons icons">people</i>
              <h6>TPT Patient List</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col m4 s12">
        <router-link to="/feedback">
          <div class="card z-depth-3">
            <div class="card-content center">
              <i class="material-icons icons">reply_all</i>
              <h6>Unsuccessful Referral List</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col m4 s12">
        <router-link to="/transfer-checkings">
          <div class="card z-depth-3">
            <div class="card-content center">
              <i class="material-icons icons">autorenew</i>
              <h6>Tranfers</h6>
              <i class="material-icons forward">arrow_forward</i>
              <span
                class="mynoti"
                v-show="unseen_counts!=0 && !transferLoading "
              >{{ unseen_counts }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col m4 s12">
        <router-link to="/scc">
          <div class="card z-depth-3">
            <div class="card-content center">
              <i class="material-icons icons">domain</i>
              <h6>SCC</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col m4 s12">
        <router-link to="/activity/choosebox">
          <div class="card z-depth-3">
            <div class="card-content center">
              <i class="material-icons icons">domain</i>
              <h6>Other Activities</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col m4 s12">
        <router-link to="/patient/dots/list">
          <div class="card z-depth-3">
            <div class="card-content center">
              <i class="material-icons icons">people</i>
              <h6>DOTS Patient</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div>
        </router-link>
      </div>
      
    </div>
  </div>
</template>

<script>
import BackContainer from "@/components/ReUse/BackContainer.vue";
export default {
  name: "CheckingBox",
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("loadNewTransfer");
  },
  components: {
    "back-container": BackContainer
  },
  computed: {
    unseen_counts() {
      return this.$store.getters.unseen_counts;
    },
    transferLoading() {
      return this.$store.getters.transferLoading;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.forward {
  color: var(--card-background-color) !important;
  position: absolute;
  right: 0px;
  bottom: 2px;
  font-size: 40px;
}
.CheckingBox {
  padding-top: 10px;
  padding-left: 5%;
  padding-right: 5%;
}

.CheckingBox .icons {
  font-size: 50px;
  color: var(--card-icon-color) !important;
}

.CheckingBox .card-content {
  padding-top: 50px;
  padding-bottom: 50px;
}

.card-content h6 {
  font-size: 22px;
}

.card {
  border-radius: 20px;
  border-left: 20px solid var(--card-icon-color) !important;
  height: 200px;
  background: var(--card-background-color) !important;
}

.card h6 {
  color: var(--card-text-color);
}

.card:hover {
  border-right: 20px solid var(--card-icon-color) !important;
  transition: 2s;
}

.card:hover .forward {
  color: var(--card-icon-color) !important;
  transition: 2s;
}

.mynoti {
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: blue;
  font-size: 22px;
  background: yellow;
  padding: 20px 15px 15px 15px;
  border-radius: 50px 0px 0px 0px;
}

.forward {
  color: white;
  position: absolute;
  right: 0px;
  bottom: 2px;
  font-size: 40px;
}
</style>
