<template>
    <i data-position="bottom" :data-tooltip="docs[index-1].body" class="material-icons right tooltipped">help</i>
</template>
<script>
export default {
    props:["index"],
    mounted()
    {
         var elems = document.querySelectorAll('.tooltipped');
         var instances = M.Tooltip.init(elems, {});
    },
    computed: 
    {
        docs()
      {
          return this.$store.getters.pa_report.docs;
      },
    }
}
</script>
<style>
    
</style>