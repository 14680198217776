<template>
  <div class="PAReport">
          
      <div class="row">
        <div style="padding-left:20px;">
          <back-container link="/home"></back-container>
        </div>
        <div class="col m8 offset-m2">
            <div class="card card-content">
                <div class="center">
                    <h6>Please choose reporting date</h6>
                </div>
                <div class="row">
                     <div class="input-field col s12">
                        <select v-model="month">
                            <option value="" disabled selected>Choose your option</option>
                            
                            <option  value="1">1</option>
                            <option  value="2">2</option>
                            <option  value="3">3</option>
                            <option  value="4">4</option>
                            <option  value="5">5</option>
                            <option  value="6">6</option>
                            <option  value="7">7</option>
                            <option  value="8">8</option>
                            <option  value="9">9</option>
                            <option  value="10">10</option>
                            <option  value="11">11</option>
                            <option  value="12">12</option>
                            <option  value="q1">Quater 1</option>
                            <option  value="q2">Quater 2</option>
                            <option  value="q3">Quater 3</option>
                            <option  value="q4">Quater 4</option>
                            <option  value="annual">Annual</option>

                            
                        </select>
                        <label>Month</label>
                    </div>

                    <div class=" col s12">
                          <label>Year</label>
                          <input id="year" type="number" class="validate" v-model="year">
                        
                    </div>
                    <div class="input-field col s12 center">
                        <input @click="chooseActivityReport"  type="button" class="btn blue darken-1" value="Choose" />
                    </div>
                </div>
            </div>
        </div>          
      </div>
          
         
    </div>
</template>

<script>
import BackContainer from '@/components/ReUse/BackContainer.vue';
export default {
  name: 'PAReport',
  data () {
    return {
      year:"",
      month:"",
    }
  },
  mounted()
  {
       var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems, {});
  },
  components: 
  {
      "back-container":BackContainer,
  },
  computed: 
  {
     
  },
  methods: 
  {
      chooseActivityReport()
      {
          if (this.month && this.year && this.year.length==4)
          {
            this.$router.push({name:"ReportedVolunteer",params:{month:this.month,year:this.year}});
          }
          else 
          {
              alert("Some fields are invalid");
          }
          
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.PAReport 
{
        padding-top:30px;
        padding-left:5%;
        padding-right:5%;
}
    

.PAReport .card-content
{
   padding:15px;
}



.PAReport .card-content h6 
{
    font-size:22px;
    
}




</style>
