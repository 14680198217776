import { sendData,getData } from './../../misc/server.js';
export default {
  state: {

    warning:false,
  
  },
  mutations: {
  
      setMoveWarning(state,payload)
      {
        
          state.warning=payload;
      },
      setCurrentData(state,payload)
      {
          localStorage.setItem("current_data",payload);
      }
   
  },
   actions: {
    checkPatientFill({commit},data)
    { 
        var tempdata=JSON.parse(localStorage.getItem("current_data"));
        
        if (JSON.stringify(data.data) === JSON.stringify(tempdata) )
        {
            commit("setMoveWarning",false);
        }
        else if (tempdata=="changed")
        {
          commit("setMoveWarning",true);
        }
        else 
        {
          commit("setMoveWarning",true);
        }
    },
    changePatientFillData({commit},data)
    {
        var currentdata=null;
        
        if (data.id==1)
        {
          currentdata=data.patient;
        }
        else if (data.id==2)
        {
          currentdata=data.patient.chest_xray;
        }
        else if (data.id==3)
        {
          currentdata=data.patient.sputum;
        }
        else if (data.id==4)
        {
          currentdata=data.patient.gene_xpert;
        }
        else if (data.id==5)
        {
          currentdata=data.patient.tuberculinskin;
        }
        else if (data.id==6)
        {
          currentdata=data.patient.fnac;
        }
        else if (data.id==7)
        {
          currentdata=data.patient.sputum_culture;
        }
        else if (data.id==8)
        {
          currentdata=data.patient.drug_suscept;
        }
        else if (data.id==9)
        {
          currentdata=data.patient.tb_conclusion;
        }

        commit("setCurrentData",JSON.stringify(currentdata));
    }

  },
  getters: {
   
    fill_warning(state)
    {
      return state.warning;
    }
 
  }
}