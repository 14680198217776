// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import { store }  from './store'
import Notifications from 'vue-notification'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import Fragment from 'vue-fragment'
import BackContainer from './components/ReUse/BackContainer';
import Loading from "@/components/Partials/Loading.vue";
import './assets/css/styles.scss'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";


Vue.use(Notifications)
Vue.config.productionTip = false
Vue.use(Datepicker);
Vue.use(moment);
Vue.use(Fragment.Plugin)
Vue.component("back-container",BackContainer);
Vue.component("pre-loader",BackContainer);
Vue.component("v-select",vSelect);

Vue.prototype.moment = moment


/* eslint-disable no-new */



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

