export function getFilterType(route)
{
        var result="";
        if (route.query.status)
          {
             
              switch (route.query.status)
              {
                  case 1: status="Wating";break;
                  case 2: status="Accept";break;
                  case 3: status="Submitted";break;
                  case 4: status="Cannot Connect";break;
                  case 5: status="Fake";break;
                  case 6: status="Wrong Township";break;
              }
              result+=" Status";

              
          }
           if (route.query.startdate && route.query.enddate)
          {
            result+="Date between "+route.query.startdate+" and "+route.query.enddate+" , ";

          }
          if (route.query.register_startdate && route.query.register_enddate)
          {
            result+="Date between "+route.query.register_startdate+" and "+route.query.register_enddate+" , ";
          }

           if (route.query.gender)
          {
              if (route.query.gender==1)
              {
                result+=" Male ,";
              }
              else 
              {
                result+=" Female ,";
              }
          }

          if (route.query.volunteer_id)
          {
            result+="Volunteer ,";
          }

          if (route.query.tb)
          {
              result+=" TB,";
          }

          if (route.query.nopresumptive)
          {
              result+="No Presumptive Number,";
          }

          if (route.query.age)
          {
              if (route.query.age==1)
              {
                result+=" Under 15 ,";
              }
              else 
              {
                result+=" Over 15 ,";
              }
          }

          if (route.query.code && route.query.year)
          {
              result+=route.query.code+"/"+route.query.year+",";
          }



          if (route.params.search)
          {
              result+="Search by "+route.params.search;
          }
          
          if (route.query.refer_from)
          {
          
            switch (parseInt(route.query.refer_from))
            {
              case 1: result="Referred By PICTS Volunteer";break;
              case 2: result="Referred By PICTS GP";break;
              case 3: result="Referred By PICTS Pharmancy";break;
              case 4: result="Referred By Others";break;
              case 5: result="Referred By Township";break;
            }
          }
          

          if (result.length==0)
          {
              result="All";
          }

          return result;
}