<template>
  <div class="Login">
    <div class="wrapper">
      <div class="card z-depth-3">
        <div class="card-content">
          <div class="center">
            <h4>Login Here</h4>
          </div>
          <div class="row">
            <div class="col s12">
              <label>ID</label>
              <input type="text" v-model="username" />
            </div>
          </div>
          <div class="row">
            <div class="col s12">
              <label>Password</label>
              <input type="password" class="validate" v-model="password" />
            </div>
          </div>
          <div class="row">
            <div class="input-field col s12 center">
              <button
                :disabled="loading"
                v-if="complete"
                @click="login"
                class="btn waves-effect btn-large blue darken-3"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    login() {
      var data = {
        username: this.username,
        password: this.password,
      };
      this.$store.dispatch("login", data);
    },
  },
  watch: {
    auth(newVal) {
      if (newVal != null) {
        if (newVal.success) {
          this.$router.push("/home");
        } else {
          this.$notify({
            group: "noti",
            type: "error",
            text: "Login failed",
          });
        }
      } else {
        this.$router.push("/");
      }
    },
  },

  computed: {
    complete() {
      return this.username && this.password;
    },

    loading() {
      return this.$store.getters.login_loading;
    },

    auth() {
      return this.$store.getters.auth;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap");

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.wrapper h4,
.wrapper label {
  color: blue;
  font-weight: bold;
  font-family: "Noto Sans JP", sans-serif;
}

label {
  font-size: 18px;
}

input[type="text"],
input[type="password"] {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: bold;
}

.card {
  width: 70%;
}
</style>
