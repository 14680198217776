<template>
  <div class="AddTraining">
    <div class="row">
      <div class="col l8 offset-l2 m10 offset-m1 s12">
        <div class="card card-content z-depth-3 ">
          <div class="row">
            <div class="col s12 m8 offset-m2">
              <label class="black-text">Type of Training</label>
              <select v-model="training_type_id">
                <option value="" disabled selected>Choose training type</option>
                <option v-bind:value="item.id" v-for="item in training_types">{{
                  item.name
                }}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col s12 m8 offset-m2">
              <label class="black-text">Date of Training</label>
              <input v-model.lazy="date" type="text" class="datepicker" />
            </div>
          </div>
          <div class="row center" v-if="buttonpass">
            <button
              class="btn blue darken-3"
              @click="add"
              :disabled="actionloader"
            >
              Add
            </button>
          </div>
        </div>
        <div class="card card-content z-depth-3 ">
          <div class="center">
            <h6>Volunteer Training Type</h6>
          </div>
          <table>
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
            <tr v-for="item in volunteer_training_types">
              <td>{{ item.training_type.name }}</td>
              <td>{{ item.date }}</td>
              <td>
                <button
                  :disabled="actionloader"
                  @click="trainingDelete(item.id)"
                  class="btn red darken-3"
                >
                  <i class="material-icons">delete</i>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loadng from "@/components/Partials/Loading.vue";
export default {
  name: "AddTraining",
  data() {
    return {
      training_type_id: "",
      date: "",
    };
  },
  mounted() {
    this.$store.dispatch("getVolunteerTrainingType", {
      id: this.$route.params.id,
    });
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
    });
  },
  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
  },
  methods: {
    add() {
      var id = this.$route.params.id;
      var data = {
        volunteer_id: id,
        training_type_id: this.training_type_id,
        date: this.date,
      };

      this.$store.dispatch("addTrainingType", data);
    },
    trainingDelete(id) {
      this.$store.dispatch("deleteTrainingType", { id: id });
    },
  },

  components: {},
  watch: {
    addFinish(newVal) {
      location.reload();
    },
  },
  computed: {
    training_types() {
      return this.$store.getters.training_type;
    },
    buttonpass() {
      if (this.training_type_id && this.date) {
        return true;
      } else {
        return false;
      }
    },
    volunteer_training_types() {
      return this.$store.getters.volunteer_training_types;
    },
    addFinish() {
      return this.$store.getters.addFinish;
    },
    actionloader() {
      return this.$store.getters.actionloader;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.AddTraining {
  padding-top: 30px;
}

.AddTraining .card-content {
  padding: 30px;
}
</style>
