<template>
  <div class="card card-content">
    <h6>Sputum</h6>
    <form>
      <div class="row">
        <div class="input-field col s12">
          <div class="switch">
            <label>
              Not Done
              <input type="checkbox" @click="switchChange" />
              <span class="lever"></span>
              Done
            </label>
          </div>
        </div>
        <div class="input-field col s12">
          <span>Date of Sputum Smear examination</span>
          <input
            type="text"
            placeholder="YYYY-MM-DD"
            v-model.lazy="date"
            class="datepicker"
            :disabled="!done"
            readonly
          />
        </div>
        <div class="input-field col s12" v-show="done">
          <span>Sputum Smear Result</span>
          <select v-model="result">
            <option value="" disabled selected>Choose your option</option>
            <option value="1">Negative</option>
            <option value="2">Positive</option>
            <option value="3">Missing</option>
            <option value="4">Pending</option>
          </select>
        </div>

        <div class="row center">
          <button
            type="button"
            class="btn blue darken-3"
            @click="submit"
            :disabled="updateloading"
            v-if="buttonpass"
          >
            Edit
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "NewSputum",
  data() {
    return {
      done: false,
      date: "",
      result: "",
    };
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  watch: {
    updateloading(newVal) {
      if (!newVal) {
        location.reload();
      }
    },
  },
  methods: {
    switchChange() {
      this.done = this.done == 0 ? 1 : 0;
      if (this.done == 1) {
        localStorage.setItem("current_data", "changed");
      } else {
        localStorage.setItem("current_data", null);
      }
    },

    submit() {
      var data = {
        done: this.done,
        date: null,
        result: null,
      };

      if (this.done) {
        data.date = this.date;
        data.result = this.result;
      }

      data.id = this.patient.id;

      this.$store.dispatch("submitSputum", data);
    },
  },
  components: {},
  computed: {
    patient() {
      return this.$store.getters.patient;
    },

    buttonpass() {
      if (this.done) {
        if (this.result && this.date) {
          return true;
        } else if (this.result == 4) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    updateloading() {
      return this.$store.getters.updateloading;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fill {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

.fill .card {
  padding: 20px;
}
</style>
