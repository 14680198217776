<template>
  <div v-if="gene_xpert" class="card card-content">
    <form>
      <div class="row">
        <div class="input-field col s6">
          <h6>Gene Xpert</h6>
          <div class="switch">
            <label>
              Not Done
              <input
                type="checkbox"
                @click="switchChange"
                :checked="gene_xpert.done == 1"
              />
              <span class="lever"></span>
              Done
            </label>
          </div>
        </div>
      </div>
      <div class="input-field col s12" v-show="gene_xpert.done == 1">
        <span>Type of Gene Xpert</span>
        <select v-model="gene_xpert.gxp_ultra">
          <option value="1" selected>GXP</option>
          <option value="2" selected>GXP Ultra</option>
        </select>
      </div>
      <div class="input-field col s12">
        <span>Date of Gene Xpert examination</span>
        <input
          type="text"
          placeholder="YYYY-MM-DD"
          v-model.lazy="gene_xpert.date"
          class="datepicker"
          :disabled="gene_xpert.done != 1"
          readonly
        />
      </div>
      <div class="input-field col s12" v-show="gene_xpert.done == 1">
        <span>Result of Gene Xpert</span>
        <select v-model="gene_xpert.result">
          <option value="" disabled selected>Choose your option</option>
          <option
            v-for="option in resultOptions"
            :value="option.value"
            :key="option.value"
            >{{ option.label }}</option
          >
        </select>
      </div>
      <div class="row center" v-if="buttonpass">
        <button
          type="button"
          class="btn blue darken-3"
          @click="submit"
          :disabled="updateloading"
        >
          Edit
        </button>
      </div>
    </form>
  </div>
  <new-gene-xpert v-else></new-gene-xpert>
</template>

<script>
import NewGeneXpert from "@/components/Patient/Fill/GeneXpert/NewGeneXpert.vue";
export default {
  name: "GeneXpert",
  data() {
    return {
      done: false,
      gene_xpert_ultra: false,
      date: "",
      result: "",
      resultOptions: [
      { label: "N-MTB not detected", value: "1" },
      { label: "T-MTB detected", value: "2" },
      { label: "RR-Rif resistance", value: "3" },
      { label: "Tl-MTB detected, RR indeterminate", value: "4" },
      { label: "l-invalid result", value: "5" },
      { label: "Missing", value: "6" },
      { label: "Pending", value: "7" },
      { label: "TT-MTB detected (trace)", value: "8" },
    ]
    };
  },
  components: {
    "new-gene-xpert": NewGeneXpert,
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  watch: {},
  methods: {
    switchChange() {
      this.gene_xpert.done = this.gene_xpert.done == 0 ? 1 : 0;
    },
    getResultOptions() {
    if (this.gene_xpert.gxp_ultra === 1) {
      return this.resultOptions;
    } else {
      return this.resultOptions.slice(0, this.resultOptions.length - 1);
    }
  },

    submit() {
      var data = {
        done: this.gene_xpert.done,
        date: null,
        result: null,
      };
      if (data.done) {
        data.date = this.gene_xpert.date;
        data.result = this.gene_xpert.result;
        data.gxp_ultra = this.gene_xpert.gxp_ultra;
      }

      data.id = this.patient.id;

      this.$store.dispatch("submitGeneExpert", data);
    },
  },

  computed: {
    patient() {
      return this.$store.getters.patient;
    },
    gene_xpert() {
      var item = localStorage.getItem("current_data");
      var data = this.$store.getters.patient.gene_xpert;
      if (!item) {
        localStorage.setItem("current_data", JSON.stringify(data));
      }

      return data;
    },
    buttonpass() {
      if (this.gene_xpert.done) {
        if (this.gene_xpert.result && this.gene_xpert.date) {
          return true;
        } else if (this.gene_xpert.result == 7) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    updateloading() {
      return this.$store.getters.updateloading;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fill {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

.fill .card {
  padding: 20px;
}
</style>
