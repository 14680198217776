<template>
 
       <fragment v-if="data.gene_xpert">             
                               
                                <td >{{ done }}</td>
                                <td >{{ data.gene_xpert.date }}</td>
                                <td  >{{ result }}</td>
                               
                                
                              
        </fragment>                     
        <fragment v-else>             
                               
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                               
                                
                              
        </fragment>                     
                    
                  
</template>

<script>



export default {
  name: 'Body',
  props:["data"],
  data () {
    return {
    }
  },

  computed: 
  {
     done()
     {
         if (this.data.gene_xpert.done==1)
         {
             return "Done";
         }
         else 
         {
             return "Not Done";
         }
     },
     result()
     {
         var result=null;
          var value=parseInt(this.data.gene_xpert.result);
          
         switch (value)
         {
             case 1: result="N-MTB not detected";break;
             case 2: result="T-MTB detected";break;
             case 3: result="RR-Rif resistance";break;
             case 4: result="Tl-MTB detected, RR indeterminate";break;
             case 5: result="l-invalid result";break;
             case 6: result="Missing";break;
         }

         return result;
     }
  }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    
     
</style>
