<template>
  <div class="Setting">
    <div class="row">
      <div style="padding-left: 20px">
        <back-container link="/home"></back-container>
      </div>
      <div class="col m8 offset-m2">
        <div class="card profile">
          <div class="row title">
            <h5>Profile</h5>
          </div>
          <div class="row info">
            <table>
              <tr>
                <td class="header_column center">Township</td>
                <td>{{ auth.user.township.name }}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="header_column center">Code</td>
                <td>{{ auth.user.username }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackContainer from "@/components/ReUse/BackContainer.vue";
export default {
  name: "Setting",
  data() {
    return {};
  },
  components: {
    "back-container": BackContainer,
  },
  computed: {
    auth() {
      return this.$store.getters.auth;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Setting {
  padding-top: 20px;
}

.Setting .card {
  padding: 20px;
  padding-left: 40px;
  background: var(--card-background-color);
  color: var(--general-text-color);
}
.profile .info {
  padding-top: 20px;
}

.profile .title {
  border-bottom: 0.5px solid black;
  padding-bottom: 20px;
}

.profile .info h6 {
  color: blue;
}

tr td {
  font-size: 18px;
}

.header_column {
  border-left: 10px solid #1976d2 !important;
}
</style>
