<template>
  <div class="Home" v-if="!loading">
    <div class="row center">
      <h5 id="title">{{ user.user.township.name }}</h5>
    </div>
    <div
      class="row center"
      style="background:orange;padding:20px;"
      v-show="patients.length > 0"
    >
      <h6>
        You have {{ patients.length }} refer dots patients from fever clinic
      </h6>
    </div>
    <div class="row">
      <div class="col  m4 s12">
        <router-link to="/checkingbox"
          ><div class="card z-depth-3 white  lighten-4">
            <div class="card-content center">
              <i class="material-icons icons">check_circle</i>
              <h6>Activities</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div></router-link
        >
      </div>
      <div class="col  m4 s12">
        <router-link to="/volunteers"
          ><div class="card z-depth-3 white  lighten-4">
            <div class="card-content center">
              <i class="material-icons icons">wc</i>
              <h6>Volunteers</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div></router-link
        >
      </div>
      <div class="col  m4 s12">
        <router-link to="/setting"
          ><div class="card z-depth-3 white  lighten-4">
            <div class="card-content center">
              <i class="material-icons icons">settings</i>
              <h6>Setting</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div></router-link
        >
      </div>
      <div class="col  m4 s12">
        <router-link to="/pareport"
          ><div class="card z-depth-3 white  lighten-4">
            <div class="card-content center">
              <i class="material-icons icons">book</i>
              <h6>Report</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div></router-link
        >
      </div>
      <div class="col  m4 s12">
        <router-link to="/volunteer_report"
          ><div class="card z-depth-3 white  lighten-4">
            <div class="card-content center">
              <i class="material-icons icons">book</i>
              <h6>Volunteer Report</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from "../../misc/server";
export default {
  name: "Home",
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      loading: false,
      patients: [],
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      getData("/dots/patient/refer-list", (data) => {
        this.patients = data.data.filter((data) => !data.dotspatient);
        this.loading = false;
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters.auth;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Courier+Prime&display=swap");

.forward {
  color: var(--card-background-color) !important;
  position: absolute;
  right: 0px;
  bottom: 2px;
  font-size: 40px;
}

.Home {
  padding-top: 10px;
  padding-left: 5%;
  padding-right: 5%;
}

.icons {
  font-size: 50px;
  color: var(--card-icon-color) !important;
}

.card-content {
  padding-top: 35px;
  padding-bottom: 30px;
}

.card {
  border-radius: 20px;
  border-left: 20px solid var(--card-icon-color) !important;
  height: 200px;
  background: var(--card-background-color) !important;
}

.card h6 {
  color: var(--card-text-color);
}

.card:hover {
  border-right: 20px solid var(--card-icon-color) !important;
  transition: 2s;
}

.card:hover .forward {
  color: var(--card-icon-color) !important;
  transition: 2s;
}

.card-content h6 {
  font-size: 22px;
}

#title {
  color: var(--sub-header-text-color);

  padding: 20px;
  border-radius: 50px 0px 50px 0px;
  background: var(--sub-header-background-color);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  font-family: "Courier Prime", monospace;
  font-size: 30px;
}
</style>
