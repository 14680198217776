<template>
    
                    <router-link :to="link" class="btn blue darken-3">
                        <i class="material-icons">arrow_back</i>
                    </router-link>
    
</template>
<script>
export default {
    name:"BackContainer",
    props:["link"],
}
</script>
<style scoped>

</style>