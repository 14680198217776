<template>
  <div v-if="fnac" class="card card-content">
    <h6>Fine Needle Aspiration Cytology (FNAC)</h6>
    <form>
      <div class="row">
        <div class="input-field col s12">
          <div class="switch">
            <label>
              Not Done
              <input
                type="checkbox"
                @click="switchChange"
                :checked="fnac.done == 1"
              />
              <span class="lever"></span>
              Done
            </label>
          </div>
        </div>
        <div class="input-field col s12">
          <span>FNAC Date</span>
          <input
            :disabled="fnac.done != 1"
            type="text"
            placeholder="YYYY-MM-DD"
            v-model.lazy="fnac.date"
            class="datepicker"
            readonly
          />
        </div>
        <div class="input-field col s12" v-show="fnac.done == 1">
          <span>FNAC Result</span>
          <select v-model="fnac.result">
            <option value="" disabled selected>Choose your option</option>
            <option value="1">Other</option>
            <option value="2">TB lymphadenitis</option>
            <option value="3">Missing</option>
            <option value="4">Pending</option>
          </select>
        </div>
        <div class="input-field col s12" v-show="fnac.done == 1">
          <span>AFB seen/not</span>
          <select v-model="fnac.seen_not_seen">
            <option value="" disabled selected>Choose your option</option>
            <option value="seen">Seen</option>
            <option value="not seen">Not Seen</option>
            <option value="not done">Not Done</option>
          </select>
        </div>

        <div class="row center" v-if="buttonpass">
          <button
            type="button"
            class="btn blue darken-3"
            @click="submit"
            :disabled="updateloading"
          >
            Edit
          </button>
        </div>
      </div>
    </form>
  </div>
  <add-fnac v-else></add-fnac>
</template>

<script>
import AddFNAC from "@/components/Patient/Fill/FNAC/AddFNAC.vue";
export default {
  name: "FNAC",
  data() {
    return {
      done: false,
      date: "",
      result: "",
    };
  },
  components: {
    "add-fnac": AddFNAC,
  },
  mounted() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});

    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  watch: {},
  methods: {
    switchChange() {
      this.fnac.done = this.fnac.done == 0 ? 1 : 0;
    },

    submit() {
      var data = {
        done: this.fnac.done,
        date: null,
        result: null,
      };

      if (data.done) {
        data.date = this.fnac.date;
        data.result = this.fnac.result;
        data.seen_not_seen = this.fnac.seen_not_seen;
      }

      data.id = this.patient.id;

      this.$store.dispatch("submitFNAC", data);
    },
  },

  computed: {
    patient() {
      return this.$store.getters.patient;
    },

    fnac() {
      var item = localStorage.getItem("current_data");
      var data = this.$store.getters.patient.fnac;
      if (!item) {
        localStorage.setItem("current_data", JSON.stringify(data));
      }

      return data;
    },

    buttonpass() {
      if (this.fnac.done == 1) {
        if (this.fnac.result && this.fnac.date) {
          return true;
        } else if (this.fnac.result == 4) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    updateloading() {
      return this.$store.getters.updateloading;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fill {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}

.fill .card {
  padding: 20px;
}
</style>
