<template>
  <div>
    <div class="card card-content">
      <h6>Edit Raw Data </h6>
      <form>
        <div class="row">
          <div class="input-field col s12">
            <span>Name</span>
            <input type="text" v-model="patient.name" />
          </div>
          <div class="input-field col s12">
            <span>Age</span>
            <input type="number" v-model="patient.age" />
          </div>
          <div class="input-field col s12">
            <span>Submitted Date</span>
            <input
              type="text"
              v-model.lazy="patient.submitted_date"
              class="datepicker"
              readonly
            />
          </div>
          <div class="input-field col s12">
            <span>Gender</span>
            <p>
              <label>
                <input name="group1" type="radio" value="1" :checked="patient.gender == 1" @click="gender = 1" />
                <span>ကျား</span>
              </label>
            </p>
            <p>
              <label>
                <input name="group1" type="radio" value="2" :checked="patient.gender == 2" @click="gender = 2" />
                <span>မ</span>
              </label>
            </p>
          </div>
          <div class="input-field col s12">
            <span>Address</span>
            <input type="text" v-model="patient.address" />
          </div>
          <div class="input-field col s12">
            <span>Phone</span>
            <input type="text" v-model="patient.phone" />
          </div>

          <div class="input-field col s12">
            <span>Refer From</span>

            <select v-model="patient.metadata.refer_from">
              <option value disabled selected>Choose your option</option>
              <option value="1">PICTS volunteer</option>
              <option value="2">PICTS GP</option>
              <option value="5">Township</option>
              <option value="6">Non-PICTS</option>
              <option value="7">PICTS LTBI</option>

            </select>
          </div>
          <div class="input-field col s12" v-show="patient.metadata.refer_from == 2">
            <span>GP Name</span>
            <input type="text" v-model="patient.metadata.refer_description" />
          </div>
          <div v-show="patient.metadata.refer_from == 1 || patient.metadata.refer_from == 7">
            <span>Volunteers</span>
            <select v-model="patient.volunteer_id">
              <option value disabled selected>Choose your option</option>
              <option v-for="item in volunteers" v-bind:key="item.id" v-bind:value="item.id">
                {{ item.code }} ({{ item.name }})
              </option>
            </select>
          </div>
          <div class="col s12" v-show="patient.metadata.refer_from == 6">
            <span>Non-PICTS</span>
            <select v-model="patient.metadata.non_picts_description">
              <option value disabled selected>Choose your option</option>
              <option value="HS">HS</option>
              <option value="C">C</option>
              <option value="PP">PP</option>
              <option value="UnionDR">UnionDR</option>
              <option value="FC">FC</option>
              <option value="IHC">IHC</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div class="input-field col s12" v-show="patient.metadata.refer_from !== 5">
            <span>Case Founded Activity</span>

            <p v-for="item in foundplaces" v-bind:key="item.id">
              <label>
                <input name="patient_found_place_id" type="radio" v-model="patient_found_place_id" :value="item.id" />
                <span>{{ item.eng_name }}</span>
              </label>
            </p>
            <div class="row" v-show="patient_found_place_id == 2 || patient_found_place_id == 3
              ">
              <div class="col s6">
                <input type="number" v-model="patient.metadata.pfp_description" />
              </div>
              <div class="col s6">
                <select v-model="patient.metadata.sub_pfp">
                  <option value disabled selected>Choose your option</option>
                  <option value="1">Household</option>
                  <option value="2">Non-household</option>
                </select>
              </div>
            </div>
          </div>
          <div class="input-field col s12" v-show="patient.metadata.refer_from !== 5">
            <span>Referred Type</span>

            <p v-for="item in submit_types" v-bind:key="item.id">
              <label>
                <input name="submit_type_id" type="radio" v-model="patient_submit_type_id" :value="item.id" />
                <span>{{ item.eng_name }}</span>
              </label>
            </p>
          </div>
          <div class="input-field col s12">
            <span>Referred To</span>

            <p v-for="item in submit_departments" v-bind:key="item.id">
              <label>
                <input name="submit_department_id" v-model="patient_submit_department_id" type="radio"
                  :value="item.id" />
                <span>{{ item.eng_name }}</span>
              </label>
            </p>

            <div v-show="patient_submit_department_id == 3">
              <select v-model="patient.metadata.psd_description">
                <option value disabled selected>Choose your option</option>
                <option v-for="item in sccs" v-bind:value="item.id" v-bind:key="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div v-show="patient_submit_department_id == 5">
              <span>Other</span>
              <input type="text" v-model="patient.metadata.psd_other" />
            </div>
          </div>
          
          <div class="row center">
            <button :disabled="updateloading" type="button" @click="edit" class="btn blue darken-3">
              Edit
            </button>
          </div>
        </div>
      </form>
    </div>

  </div>
  </div>
</template>

<script>
export default {
  name: "PatientEditForm",
  props: {
    patient: {
      type: Object,
      required: true,
      default: () => ({
        presumptive_tb: {
          metadata: {
            refer_from: null,
          },
        }
      }),
    },
  },
  data() {
    return {
      presumptiveNumber: null,
      gender: null,
      patient_found_place_id: 1,
      patient_submit_department_id: 1,
      patient_submit_type_id: 1,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  mounted() {
    this.$store.dispatch("getPatientMetaData");
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
  },
  methods: {
    edit() {
      var data = {
        name: this.patient.name,
        address: this.patient.address,
        phone: this.patient.phone,
        age: this.patient.age,
        gender: this.gender,
        refer_from: this.patient.metadata.refer_from,
        volunteer_id: this.patient.volunteer_id,
      };

      if (this.patient.metadata.refer_from != 5) {
        data.patient_submit_department_id = this.patient_submit_department_id;
        data.patient_found_place_id = this.patient_found_place_id;
        data.patient_submit_type_id = this.patient_submit_type_id;
        data.pfp_description = null;
        data.psd_description = null;

        data.submitted_date = this.patient.submitted_date;

        if (this.patient_submit_department_id == 3) {
          data.psd_description = this.patient.metadata.psd_description;
        } else {
          data.psd_description = null;
        }

        if (this.patient_submit_department_id == 5) {
          data.psd_other = this.patient.metadata.psd_other;
        } else {
          data.psd_other = null;
        }

        if (
          this.patient_found_place_id == 2 ||
          this.patient_found_place_id == 3
        ) {
          data.pfp_description = this.patient.metadata.pfp_description;
          data.sub_pfp = this.patient.metadata.sub_pfp;
          data.type_index_case = this.patient.metadata.type_index_case;
        } else {
          data.pfp_description = null;
        }
      } else if (
        this.patient.metadata.refer_from == 2 ||
        this.patient.metadata.refer_from == 3
      ) {
        data.refer_description = this.patient.metadata.refer_description;
      } 
      if (this.gender == null) {
        data.gender = this.patient.gender;
      }

      if (this.patient.metadata.refer_from == 2) {
        data.refer_description = this.patient.metadata.refer_description;
      }

      if (this.patient.metadata.refer_from == 6) {
        data.non_picts_description =
          this.patient.metadata.non_picts_description;
      }
      data.id = this.patient.id;

      this.$store.dispatch("editCheckingData", data);
    },
  },
  watch: {
  
  },
  components: {},
  computed: {
    volunteers() {
      return this.$store.getters.meta_volunteers;
    },
    submit_types() {
      var data = this.$store.getters.submit_types;
      var id = this.patient.metadata.patient_submit_type_id;
      if (id) {
        this.patient_submit_type_id = id;
      } else {
        this.patient_submit_type_id = 1;
      }
      return data;
    },
    foundplaces() {
      var data = this.$store.getters.foundplaces;
      var id = this.patient.metadata.patient_found_place_id;
      if (id) this.patient_found_place_id = id;
      else this.patient_found_place_id = 1;
      return data;
    },
    submit_departments() {
      var data = this.$store.getters.submit_departments;
      var id = this.patient.metadata.patient_submit_department_id;
      if (id) this.patient_submit_department_id = id;
      else this.patient_submit_department_id = 1;
      return data;
    },
    updateloading() {
      return this.$store.getters.updateloading;
    },
   
    sccs() {
      return this.$store.getters.patient_sccs;
    },
    

  },
};
</script>