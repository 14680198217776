<template>
<div class="SCC">
         <div class="row">
                <div style="padding-left:20px;margin-bottom:10px;">
                <back-container link="/checkingbox"></back-container>
                </div>
                <div class="col l8 offset-l2 m10 offset-m1 s12">
                        <div class="card card-content z-depth-3 ">
                                
                                        
                                        <div class="row">
                                            <div class="col s12 m8 offset-m2">
                                                <label >Name</label>
                                                <input  type="text"  v-model="name" />
                                        </div>
                                       
                                        </div>
                                        <div class="row center" v-if="buttonpass">
                                            <button class="btn blue darken-3"
                                                @click="add"
                                                :disabled="action_loader"
                                            >Add</button>
                                        </div>
                                
                                
                        </div>

                        <div class="card card-content z-depth-3 center">
                            <table class="app-table">
                                <thead>
                                <tr>
                                    <th>No</th><th>Name</th><th>Action</th>
                                </tr>
                                </thead>
                                <tr v-for="(item,index) in sccs">
                                    <td>{{ index+1 }}</td><td>{{ item.name }}</td><td>
                                        <button v-if="item.patients.length==0"
                                            @click="deleteScc(item.id)"
                                            :disabled="action_loader"
                                        class="btn red darken-3">
                                        <i class="material-icons">delete</i></button></td>
                                </tr>
                            </table>
                            <loading v-if="scc_loading"></loading>
                        </div>
                </div>
         </div>
         
</div>
</template>

<script>
import Loading from '@/components/Partials/Loading.vue';
import BackContainer from '@/components/ReUse/BackContainer.vue';
export default {
  name: 'SCC',
  data () {
    return {
      name:"",
    }
  },
  mounted()
  {
       this.$store.dispatch("getSccs");
  },
  updated()
  {
        
  },
  methods: {
      add()
      {
          var data={
              name:this.name
          };

          this.$store.dispatch("addSCC",data);
      },
      deleteScc(id)
      {
           this.$store.dispatch("deleteSCC",{id:id});
      }
  },
  
  components: {
    "loading":Loading,
    "back-container":BackContainer,
  },
  watch: 
  {
    add_finish(newVal)
    {
        location.reload();
    }
  },
  computed:
  {
       buttonpass()
       {
           if (this.name)
           {
               return true;
           }
           else 
           {
               return false;
           }
       },
       action_loader()
       {
           return this.$store.getters.action_loader;
       },
       add_finish()
       {
           return this.$store.getters.add_finish;
       },
       sccs()
       {
           
           return this.$store.getters.sccs;
       },
       scc_loading()
       {
           return this.$store.getters.scc_loading;
       }
  },
   
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .SCC 
    {
        padding-top:30px;
    }

    .SCC .card-content 
    {
        padding:30px;
        background:var(--card-background-color);
        

    }
   
</style>
