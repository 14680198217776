<template>
  <div class="eachvolunteer">
    <div class="row">
      <div class="col l8 offset-l2 m10 offset-m1 s12">
        <div class="row">
          <back-container link="/volunteer/list"></back-container>
        </div>
        <div class="card card-content z-depth-3 ">
          <div class="center" v-if="loading">
            <loading></loading>
          </div>
          <section v-else>
            <table class="app-table" v-if="volunteer">
              <tr>
                <td>Name</td>
                <td>{{ volunteer.name }}</td>
              </tr>
              <tr v-if="volunteer.unconfirmed_pw != null">
                <td>Password</td>
                <td>{{ volunteer.unconfirmed_pw }}</td>
              </tr>
              <tr>
                <td>Code</td>
                <td>{{ volunteer.code }}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>{{ gender }}</td>
              </tr>
              <tr>
                <td>Age</td>
                <td>{{ volunteer.age }}</td>
              </tr>

              <tr>
                <td>Village</td>
                <td>{{ volunteer.village }}</td>
              </tr>
              <tr>
                <td>Ward</td>
                <td>{{ volunteer.ward }}</td>
              </tr>
              <tr>
                <td>Part History of TB</td>
                <td>{{ history }}</td>
              </tr>
              <tr>
                <td>Previous Volunteer Experience</td>
                <td>{{ experience }}</td>
              </tr>

              <tr>
                <td>Education Status</td>
                <td>{{ volunteer.education_status && volunteer.education_status.name }}</td>
              </tr>
              <tr>
                <td>Occupation</td>
                <td>{{ volunteer.occupation && volunteer.occupation.name }}</td>
              </tr>
                  <tr>
                <td>Recruited by</td>
                <td>{{ volunteer.recruited_by  }}</td>
              </tr>
              <tr>
                <td>PICTS AND MDR</td>
                <td>
                   <span v-if="volunteer.is_in_picts== 1 && volunteer.is_in_mdr== 1">PICT AND MDR</span>
                <span v-else-if="volunteer.is_in_mdr==1">MDR</span>
                <span v-else-if="volunteer.is_in_picts==1">PICTS</span>
                </td>
              </tr>
               <tr v-show="user.user.township.short_name==='TGI' || user.user.township.short_name==='LSO'">
                <td>Township 1 or 2</td>
                <td>
                  <span v-if="volunteer.seperated_side == 1">Township 1</span>
                  <span v-else-if="volunteer.seperated_side == 2">Township 2</span>
                </td>
              </tr>
              <tr>
                <td>Phone Supported</td>
                <td>
                  {{ volunteer.phone_provided }}
                </td>
              </tr>
              <tr>
                <td>Formal Trainings</td>
                <td>{{ trainings }}</td>
              </tr>

               <tr>
                <td>Onjob Trainings</td>
                <td>{{ onjob_trainings }}</td>
              </tr>

              <tr>
                <td colspan="2">
                  <button
                    class="btn blue darken-2"
                    @click="edit"
                    :disabled="actionloader"
                  >
                    <i class="material-icons">edit</i>
                  </button>
                  <button
                    class="btn yellow darken-2"
                    @click="block"
                    :disabled="actionloader"
                  >
                    <i class="material-icons">block</i>
                  </button>
                  <button
                    class="btn red darken-2"
                    @click="trash"
                    :disabled="actionloader"
                  >
                    <i class="material-icons">delete</i>
                  </button>
                  <button
                    v-show="volunteer.recruited_by==='PICTS'"
                    class="btn orange darken-2"
                    @click="permenantDelete"
                    :disabled="actionloader"
                  >
                    <i class="material-icons">cancel</i>
                  </button>
                  <button
                    class="btn green darken-2"
                    @click="recoverpw"
                    :disabled="actionloader"
                  >
                    Password Recovery
                  </button>
                  <button
                    class="btn pink darken-2"
                    @click="addtraining"
                    :disabled="actionloader"
                  >
                    Training
                  </button>
                </td>
              </tr>
            </table>

            <div class="center" v-else>
              <h5>Volunteer Not found</h5>
            </div>
            <div class="remark" v-if="volunteer">
              <h6>Remark</h6>
              <p class="text-justify">
                {{ volunteer.remark }}
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loadng from "@/components/Partials/Loading.vue";
export default {
  name: "EachAllVolunteer",
  data() {
    return {};
  },
  mounted() {
    var id = this.$route.params.id;
    this.$store.dispatch("getEachVolunteer", { id: id });
  },
  methods: {
    block() {
      var data = {
        id: this.volunteer.id,
      };

      this.$store.dispatch("blockVolunteer", data);
    },
    trash() {
      var data = {
        id: this.volunteer.id,
      };

      this.$store.dispatch("deleteVolunteer", data);
    },
    permenantDelete() {
      var data = {
        id: this.volunteer.id,
      };

      if (
        confirm(
          "Are you sure to delete?You cannot see the deleted data again"
        ) == true
      ) {
        this.$store.dispatch("deleteVolunteerPermanent", data);
      }
    },
    recoverpw() {
      var data = {
        id: this.volunteer.id,
      };

      this.$store.dispatch("recoverPw", data);
    },
    edit() {
      this.$router.push({
        name: "EditVolunteer",
        params: { id: this.volunteer.id },
      });
    },
    addtraining() {
      this.$router.push({
        name: "VolunteerAddTraining",
        params: { id: this.volunteer.id },
      });
    },
  },
  watch: {
    actionReturn(newVal) {
      if (newVal.type == "block" || newVal.type == "delete") {
        this.$router.push("/volunteer/list");
      } else if (newVal.type == "recoverpw") {
        location.reload();
      }
    },
  },
  components: {
    loading: Loadng,
  },
  computed: {
    volunteer() {
      return this.$store.getters.eachvolunteer;
    },
    loading() {
      return this.$store.getters.loading;
    },
    actionloader() {
      return this.$store.getters.actionloader;
    },

    actionReturn() {
      return this.$store.getters.actionReturn;
    },
      user() {
      return this.$store.getters.auth;
    },
    trainings() {
      var trainings = this.volunteer.training_types;
      var value = "";

      trainings=trainings.filter((training)=>training.name!=="On job");


      trainings.map((val) => {
        value += val.name + " (" + val.pivot.date + "), ";
      });

      return value;
    },
     onjob_trainings() {
      var trainings = this.volunteer.training_types;
      var value = "";

      trainings=trainings.filter((training)=>training.name==="On job");


      trainings.map((val) => {
        value += val.name + " (" + val.pivot.date + "), ";
      });

      return value;
    },
    gender() {
      var value = "";
      if (this.volunteer.gender == 1) {
        value = "Male";
      } else if (this.volunteer.gender == 2) {
        value = "Female";
      }
      return value;
    },
    history() {
      var value = "";

      if (this.volunteer.tb_history == 1) {
        value = "Yes";
      } else {
        value = "No";
      }

      return value;
    },
    experience() {
      var value = "";

      if (this.volunteer.experience == 1) {
        value = "Yes";
      } else {
        value = "No";
      }

      return value;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.eachvolunteer {
  padding-top: 30px;
}

.eachvolunteer .card-content {
  padding: 10px;
}

.eachvolunteer .remark {
  padding: 10px;
}
</style>
