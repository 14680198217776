<template>
  <div class="checking_list">
    <div>
      <back-container link="/feedback"></back-container>
    </div>
    <div class="center">
      <h5>{{ title }}</h5>
      <router-link
        v-if="$route.params.status == 7"
        to="/refer/new"
        class="btn blue darken-3"
        >Add new</router-link
      >

      <router-link
        v-if="$route.params.status == 8"
        to="/tpt/refer/new"
        class="btn blue darken-3"
        >Add new</router-link
      >
      <router-link
        v-if="$route.params.status == 7"
        to="/refer/trash"
        class="btn blue darken-3"
        >Trash</router-link
      >
      <router-link
        v-if="$route.params.status == 8"
        to="/tpt/refer/trash"
        class="btn blue darken-3"
        >Trash</router-link
      >
      <button
        @click="excelExport"
        v-if="$route.params.status == 7 || $route.params.status == 8"
        to="/refer/trash"
        class="btn green darken-3"
      >
        Excel Export
      </button>
    </div>
    <div class="row center">
      <div
        class="card card-content"
        style="margin-top: 30px; max-width: 100%; overflow-x: auto"
      >
        <wrongtownship
          v-bind:data="checkings"
          v-if="$route.params.status == 6"
        ></wrongtownship>

        <table class="app-table" v-else>
          <thead>
            <tr>
              <th>No</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Age</th>
              <th>PhoneID</th>
              <th>Referral Date</th>
              <th>Volunteer</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(checking, index) in checkings">
              <td>{{ index + 1 }}</td>
              <td>{{ checking.name }}</td>

              <td>{{ checking.phone }}</td>
              <td>{{ checking.age }}</td>
              <td>{{ checking.phoneID }}</td>
              <td>{{ checking.submitted_date }}</td>
              <td>
                {{ checking.volunteer.name }} ({{ checking.volunteer.code }})
              </td>
              <td>
                <router-link
                  :disabled="confirmCheckingLoading"
                  :to="'/refer/edit/' + checking.id"
                  v-if="$route.params.status == 7"
                  class="btn blue darken-3"
                  >Edit</router-link
                >
                <router-link
                  :disabled="confirmCheckingLoading"
                  :to="'/tpt/refer/edit/' + checking.id"
                  v-if="$route.params.status == 8"
                  class="btn blue darken-3"
                  >Edit</router-link
                >
                <button
                  :disabled="confirmCheckingLoading"
                  @click="showDetail(checking)"
                  class="btn blue darken-3"
                >
                  Detail
                </button>
                <button
                  :disabled="confirmCheckingLoading"
                  @click="recoverPatient(checking)"
                  class="btn blue darken-3"
                >
                  Recover
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row center" style="margin-top: 10px">
          <loading v-if="loading"></loading>
        </div>
      </div>
      <div
        class="row center"
        v-if="!loading && checkings.length < checking_count"
      >
        <button class="btn blue darken-2" @click="loadMore">Load More</button>
      </div>
    </div>
    <detail
      @close="detailShow = false"
      v-bind:show="detailShow"
      v-bind:detailData="detailData"
    ></detail>
  </div>
</template>

<script>
import Loading from "@/components/Partials/Loading.vue";
import Detail from "@/components/Checkings/Checking/Detail.vue";
import WrongTownship from "@/components/Checkings/WrongTownship.vue";
import BackContainer from "@/components/ReUse/BackContainer.vue";

export default {
  name: "ConfirmedList",
  data() {
    return {
      search: "",
      detailShow: false,
      detailData: null,
    };
  },
  mounted() {
    this.$store.dispatch("clearCheckings");
    this.getData();
  },
  methods: {
    recoverPatient(checking) {
      this.$store.dispatch("recoverChecking", checking.id);
    },
    loadMore() {
      this.getData(true);
    },
    searchCheckings() {
      this.$router.push({
        name: "CheckingsSearch",
        params: { search: this.search },
      });
    },
    getData(load = false) {
      var data = {
        lastid: 0,
        load: load,
      };

      if (this.checkings.length > 0) {
        data.lastid = this.checkings.length;
      }

      if (this.$route.params.status) {
        data.status = this.$route.params.status;
        if (!data.load) {
          data.lastid = 0;
        }

        data.confirmed = true;
      }

      if (this.$route.params.status == 8) {
        data.tptStatus = 1;
        data.status = 7;
      }

      this.$store.dispatch("getCheckings", data);
    },

    showDetail(value) {
      this.detailShow = true;
      this.detailData = value;
    },
    recoverChecking(item) {
      this.$store.dispatch("recoverChecking", { id: item.id });
    },
    excelExport() {
      let tpt_status = 0
      if (this.$route.params.status == 8) {
        tpt_status = 1;
      }
      this.$store.dispatch("exportDeletedPatient", tpt_status);
    },
  },
  components: {
    loading: Loading,
    wrongtownship: WrongTownship,
    detail: Detail,
    "back-container": BackContainer,
  },
  computed: {
    checkings() {
      return this.$store.getters.checkings;
    },
    loading() {
      return this.$store.getters.loading;
    },
    checking_count() {
      return this.$store.getters.checking_count;
    },
    title() {
      if (this.$route.params.status == 4) {
        return "Cannot Contact List";
      } else if (this.$route.params.status == 5) {
        return "Fake List";
      } else if (this.$route.params.status == 7) {
        return "PA Deleted List";
      } else if (this.$route.params.status == 8) {
        return "PA Deleted TPT List";
      } else {
        return "Wrong Township List";
      }
    },
    confirmCheckingLoading() {
      return this.$store.getters.confirmCheckingLoading;
    },
    confirmCheckingFinish() {
      return this.$store.getters.confirmCheckingFinish;
    },
  },
  watch: {
    $route(to, from) {
      this.getData();
    },
    confirmCheckingFinish(val) {
      if (val) {
        location.reload();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.checking_list {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
}

.checking_list .card-content {
  padding: 10px;
  background: var(--card-background-color);
}
</style>
