<template>
  <div class="FeedBack">
    <div style="margin-left:20px;margin-bottom:10px;">
      <back-container link="/checkingbox"></back-container>
    </div>
    <div class="row">
      <div class="col  m4 s6">
        <router-link to="/checkings/4/confirmed"
          ><div class="card z-depth-3 white  lighten-4">
            <div class="card-content center">
              <i class="material-icons icons " style="color:blue !important;"
                >list</i
              >
              <h6>Can't Connect</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div></router-link
        >
      </div>
      <div class="col  m4 s6">
        <router-link to="/checkings/5/confirmed"
          ><div class="card z-depth-3 white  lighten-4">
            <div class="card-content center">
              <i class="material-icons icons " style="color:red !important;"
                >list</i
              >
              <h6>Fake</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div></router-link
        >
      </div>
      <div class="col  m4 s6">
        <router-link to="/checkings/6/confirmed"
          ><div class="card z-depth-3 white  lighten-4">
            <div class="card-content center">
              <i class="material-icons icons " style="color:orange !important;"
                >list</i
              >
              <h6>Wrong Township</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div></router-link
        >
      </div>
      <div class="col  m4 s6">
        <router-link to="/checkings/7/confirmed"
          ><div class="card z-depth-3 white  lighten-4">
            <div class="card-content center">
              <i class="material-icons icons " style="color:brown !important;"
                >delete</i
              >
              <h6>Deleted Presumptive Patient</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div></router-link
        >
      </div>
      <div class="col  m4 s6">
        <router-link to="/checkings/8/confirmed"
          ><div class="card z-depth-3 white  lighten-4">
            <div class="card-content center">
              <i class="material-icons icons " style="color:brown !important;"
                >delete</i
              >
              <h6>Deleted TPT Patient</h6>
              <i class="material-icons forward">arrow_forward</i>
            </div>
          </div></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import BackContainer from "@/components/ReUse/BackContainer.vue";
export default {
  name: "FeedBack",
  data() {
    return {};
  },
  components: {
    "back-container": BackContainer,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.FeedBack {
  padding-top: 30px;
  padding-left: 5%;
  padding-right: 5%;
}

.Home {
  padding-top: 10px;
  padding-left: 5%;
  padding-right: 5%;
}

.icons {
  font-size: 50px;
  color: var(--card-icon-color) !important;
}

.card-content {
  padding-top: 35px;
  padding-bottom: 30px;
}

.card {
  border-radius: 20px;
  border-left: 20px solid var(--card-icon-color) !important;
  height: 200px;
  background: var(--card-background-color) !important;
}

.card h6 {
  color: var(--card-text-color);
}

.card:hover {
  border-right: 20px solid var(--card-icon-color) !important;
  transition: 2s;
}

.card:hover .forward {
  color: var(--card-icon-color) !important;
  transition: 2s;
}

.forward {
  color: var(--card-background-color) !important;
  position: absolute;
  right: 0px;
  bottom: 2px;
  font-size: 40px;
}
</style>
