<template>
  <div>
    <div class="card card-content">
      <h6>TPT Information</h6>
      <form>
        <div class="row">
          <fragment v-if="!is_township">
            <div class="input-field col s12">
              <span>Sr. No </span>
              <input type="number" v-model="computePresumptiveNumber" />
            </div>
          </fragment>
          <div class="input-field col s12">
            <span>Registeration Date</span>
            <input
              type="text"
              v-model.lazy="patient.presumptive_tb.registeration_date"
              class="datepicker"
              readonly
            />
          </div>
          <div
            class="input-field col s12"
            v-show="patient.metadata.refer_from !== 5"
          >
            <span>Referred Type</span>

            <p v-for="item in submit_types">
              <label>
                <input
                  name="submit_type_id"
                  type="radio"
                  v-model="patient_submit_type_id"
                  :value="item.id"
                />
                <span>{{ item.eng_name }}</span>
              </label>
            </p>
          </div>
          <div class="input-field col s12">
            <span>Name</span>
            <input type="text" v-model="patient.name" />
          </div>
          <div class="input-field col s12">
            <span>Age</span>
            <input type="number" v-model="patient.age" />
          </div>

          <div class="input-field col s12">
            <span>Gender</span>
            <p>
              <label>
                <input
                  name="group1"
                  type="radio"
                  value="1"
                  :checked="patient.gender == 1"
                  @click="gender = 1"
                />
                <span>ကျား</span>
              </label>
            </p>
            <p>
              <label>
                <input
                  name="group1"
                  type="radio"
                  value="2"
                  :checked="patient.gender == 2"
                  @click="gender = 2"
                />
                <span>မ</span>
              </label>
            </p>
          </div>
          <div class="input-field col s12">
            <span>Address</span>
            <input type="text" v-model="patient.address" />
          </div>
          <div class="input-field col s12">
            <span>Phone</span>
            <input type="text" v-model="patient.phone" />
          </div>

          <div class="input-field col s12">
            <span>Refer From</span>

            <select v-model="patient.metadata.refer_from">
              <option value disabled selected>Choose your option</option>
              <option value="1">PICTS volunteer</option>
              <option value="2">PICTS GP</option>

              <option value="5">Township</option>
              <option value="6">Non-PICTS</option>
              <option value="7">PICTS LTBI</option>
            </select>
          </div>
          <div v-show="is_volunteer">
            <span>Volunteers</span>
            <select v-model="patient.volunteer_id">
              <option value disabled selected>Choose your option</option>
              <option v-for="item in volunteers" v-bind:value="item.id">
                {{ item.code }} ({{ item.name }})
              </option>
            </select>
          </div>
          <div class="col s12" v-show="refer_from == 6">
            <span>Non-PICTS</span>
            <select v-model="patient.metadata.non_picts_description">
              <option value disabled selected>Choose your option</option>
              <option value="HS">HS</option>
              <option value="C">C</option>
              <option value="PP">PP</option>
              <option value="UnionDR">UnionDR</option>
              <option value="FC">FC</option>
              <option value="IHC">IHC</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div class="input-field col s12">
            <span>Weight (kg)</span>
            <input v-model="patient.presumptive_tb.weight" />
          </div>

          <div
            class="input-field col s12"
            v-show="patient.metadata.refer_from !== 5"
          >
            <span>Case Founded Activity</span>

            <p v-for="item in foundplaces">
              <label>
                <input
                  name="patient_found_place_id"
                  type="radio"
                  v-model="patient_found_place_id"
                  :value="item.id"
                />
                <span>{{ item.eng_name }}</span>
              </label>
            </p>
            <div
              class="row"
              v-show="
                patient_found_place_id == 2 || patient_found_place_id == 3
              "
            >
              <div class="col s6">
                <input
                  type="number"
                  v-model="patient.metadata.pfp_description"
                />
              </div>
              <div class="col s6">
                <select v-model="patient.metadata.sub_pfp">
                  <option value disabled selected>Choose your option</option>
                  <option value="1">Household</option>
                  <option value="2">Non-household</option>
                  <option value="3">Township</option>
                </select>
              </div>
              <div class="col s6">
                <span>Type of Index Case</span>
                <select v-model="patient.metadata.type_index_case">
                  <option value disabled selected>Choose your option</option>
                  <option value="1">Bact_Confirmed</option>
                  <option value="2">Under_5yrs</option>
                  <option value="3">PLHIV</option>
                  <option value="4">TB_DM</option>
                  <option value="5">Other</option>
                </select>
              </div>
            </div>
          </div>
          <div class="input-field col s12" v-show="is_doctor">
            <span>Name</span>
            <input type="text" v-model="patient.metadata.refer_description" />
          </div>

          <div class="input-field col s12" v-show="is_volunteer">
            <span>Referral Date</span>
            <input
              type="text"
              v-model.lazy="patient.submitted_date"
              class="datepicker"
              readonly
            />
          </div>
          <div class="input-field col s12" v-show="is_volunteer">
            <span>Referred To</span>

            <p v-for="item in submit_departments">
              <label>
                <input
                  name="submit_department_id"
                  v-model="patient_submit_department_id"
                  type="radio"
                  :value="item.id"
                />
                <span>{{ item.eng_name }}</span>
              </label>
            </p>

            <div v-show="patient_submit_department_id == 3">
              <select v-model="patient.metadata.psd_description">
                <option value disabled selected>Choose your option</option>
                <option v-for="item in sccs" v-bind:value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div v-show="patient_submit_department_id == 5">
              <span>Other</span>
              <input type="text" v-model="patient.metadata.psd_other" />
            </div>
          </div>

          <fragment v-if="!is_township">
            <div class="input-field col s12">
              <span>Risk Factor</span>

              <p v-for="item in risk_factors">
                <label v-show="item.id == 1 || !hideRiskFactor">
                  <input
                    type="checkbox"
                    name="risk_factors"
                    :value="item.id"
                    v-model="checked_risk_factors"
                  />
                  <span>{{ item.name }}</span>
                </label>
              </p>
            </div>
          </fragment>
          <div class="row center" v-if="buttonpass">
            <button
              :disabled="updateloading"
              type="button"
              @click="edit"
              class="btn blue darken-3"
            >
              Edit
            </button>
          </div>
        </div>
      </form>
    </div>
    <div
      class="row"
      v-if="patient.dotspatient == null"
      style="padding-left: 30px"
    >
      <button
        :disabled="delete_loading"
        @click="deletePatient"
        class="btn red darken-3"
      >
        Delete
      </button>
      <button
        v-show="
          user.user.township.link_short_name &&
          user.user.township.link_short_name.length > 0
        "
        @click="submitToLinkTownship"
        :disabled="patient.dots_refer === 1"
        class="btn blue darken-3"
        style="margin-left: 20px"
      >
        Submit to Link Township
      </button>

      <a
        v-show="
          patient.tb_conclusion &&
          (patient.tb_conclusion.tb_status == 1 ||
            (patient.tb_conclusion.tb_status == 2 &&
              patient.tb_conclusion.tpt_status == 1)) &&
          !user.user.township.link_short_name
        "
        :href="'/patient/dots/move/' + patient.id"
        style="margin-left: 20px"
        :disabled="delete_loading"
        class="btn blue darken-3"
        >Move to DOTS patient</a
      >
    </div>
    <div class="row" v-else style="padding-left: 30px">
      <a
        :href="'/patient/dots/edit/' + patient.dotspatient.id"
        style="margin-left: 20px"
        :disabled="delete_loading"
        class="btn blue darken-3"
        >View DOTS Info</a
      >
    </div>
  </div>
</template>

<script>
import { sendData } from "../../../misc/server";
export default {
  name: "PersonalInfo",
  data() {
    return {
      risk_factor: null,
      presumptiveNumber: null,
      gender: null,
      patient_found_place_id: 1,
      patient_submit_department_id: 1,
      patient_submit_type_id: 1,
      checked_symptons: [],
      checked_risk_factors: [],
      hideRiskFactor: false,
      is_doctor: false,
      is_volunteer: false,
      is_township: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  mounted() {
    this.computePresumptiveNumber = this.patient.presumptive_tb
      .presumptive_tb_number
      ? this.patient.presumptive_tb.presumptive_tb_number
      : null;
    this.$store.dispatch("getPatientMetaData");
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
      showClearBtn: true,
    });
  },
  updated() {
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
  },
  methods: {
    deletePatient() {
      this.$store.dispatch("deletePatient", {
        id: this.patient.id,
      });
    },
    submitToLinkTownship() {
      sendData(
        "/presumptive/dots-referral/save",
        { id: this.patient.id },
        (data) => {
          window.location.reload();
        }
      );
    },
    edit() {
      var data = {
        name: this.patient.name,
        address: this.patient.address,
        phone: this.patient.phone,
        age: this.patient.age,
        gender: this.gender,
        presumptive_tb_number:
          this.patient.presumptive_tb.presumptive_tb_number,
        registeration_date: this.patient.presumptive_tb.registeration_date,
        weight: this.patient.presumptive_tb.weight,
        temperature: this.patient.presumptive_tb.temperature,
        refer_from: this.patient.metadata.refer_from,
        symptons: this.checked_symptons,
        risk_factors: this.checked_risk_factors,
        volunteer_id : this.patient.volunteer_id,
      };
      if (this.hideRiskFactor) {
        data.risk_factors = [];
        data.risk_factors.push(1);
      }

      if (this.patient.metadata.refer_from != 5) {
        data.patient_submit_department_id = this.patient_submit_department_id;
        data.patient_found_place_id = this.patient_found_place_id;
        data.patient_submit_type_id = this.patient_submit_type_id;
        data.pfp_description = null;
        data.psd_description = null;
       
        data.submitted_date = this.patient.submitted_date;

        if (this.patient_submit_department_id == 3) {
          data.psd_description = this.patient.metadata.psd_description;
        } else {
          data.psd_description = null;
        }

        if (this.patient_submit_department_id == 5) {
          data.psd_other = this.patient.metadata.psd_other;
        } else {
          data.psd_other = null;
        }

        if (
          this.patient_found_place_id == 2 ||
          this.patient_found_place_id == 3
        ) {
          data.pfp_description = this.patient.metadata.pfp_description;
          data.sub_pfp = this.patient.metadata.sub_pfp;
          data.type_index_case = this.patient.metadata.type_index_case;
        } else {
          data.pfp_description = null;
        }
      } else if (
        this.patient.metadata.refer_from == 2 ||
        this.patient.metadata.refer_from == 3
      ) {
        data.refer_description = this.patient.metadata.refer_description;
      } else if (this.patient.metadata.refer_from == 5) {
        data.presumptive_tb_number = null;
        data.height = null;
      }
      if (this.gender == null) {
        data.gender = this.patient.gender;
      }

      if (this.patient.metadata.refer_from == 2) {
        data.refer_description = this.patient.metadata.refer_description;
      }

      if (this.patient.metadata.refer_from == 6) {
        data.non_picts_description =
          this.patient.metadata.non_picts_description;
      }

      data.presumptive_tb_number = this.presumptiveNumber;

      if (this.patient.presumptive_tb.presumptive_tb_number != null) {
        data.is_already_update = false;
      } else {
        data.is_already_update = true;
      }
      data.tpt_status = 1;
      data.id = this.patient.id;

      console.log(data.type_index_case);

      this.$store.dispatch("submitPresumptiveInfo", data);
    },
  },
  watch: {
    refer_from(val) {
      if (val == 1 || val == 7) {
        this.is_doctor = false;
        this.is_volunteer = true;
      } else if (val == 2 || val == 3) {
        this.is_volunteer = false;
        this.is_doctor = true;
      } else {
        this.is_volunteer = false;
        this.is_doctor = false;
      }
    },
    symptons(val) {
      this.checked_symptons = [];
      this.symptons = this.symptons.map((value) => {
        if (value.checked) {
          this.checked_symptons.push(value.id);
        }
      });
    },
    risk_factors(val) {
      this.checked_risk_factors = [];
      this.risk_factors = this.risk_factors.map((value) => {
        if (value.checked) {
          this.checked_risk_factors.push(value.id);
        }
        if (value.checked && value.id == 1) {
          this.hideRiskFactor = true;
        }
      });
    },

    updateloading(newVal) {
      if (!newVal) {
        //  this.$notify({
        //   group: "noti",
        //   type: "success",
        //   text: "Updated"
        // });
        // location.reload();
      }
    },
    personalInfoFinish(val) {
      if (val == true) {
        location.reload();
      } else if (val == false) {
        this.$notify({
          group: "noti",
          type: "danger",
          text: "TPT code already existed",
        });
      }
    },
    checked_risk_factors(val) {
      this.hideRiskFactor = false;
      val.map((val) => {
        if (val == 1) {
          this.hideRiskFactor = true;
        }
      });
    },
    delete_patient_finish(val) {
      if (val) {
        this.$router.push("/patients");
      }
    },
  },
  components: {},
  computed: {
    computePresumptiveNumber: {
      get() {
        return this.presumptiveNumber
          ? this.presumptiveNumber
          : this.patient.presumptive_tb.presumptive_tb_number;
      },
      set(val) {
        if (val) {
          this.presumptiveNumber = val;
        } else {
          this.presumptiveNumber =
            this.patient.presumptive_tb.presumptive_tb_number;
        }
      },
    },
    volunteers() {
      return this.$store.getters.meta_volunteers;
    },
    personalInfoFinish() {
      return this.$store.getters.personalInfoFinish;
    },
    buttonpass() {
      var value = true;
      if (
        this.patient.name &&
        this.patient.address &&
        this.patient.phone &&
        this.patient.age &&
        this.patient.gender &&
        this.patient.presumptive_tb.registeration_date &&
        this.checked_risk_factors.length > 0 &&
        parseInt(this.patient.presumptive_tb.weight) == 0
      ) {
        value = false;
      }
      return value;
    },
    patient() {
      var item = localStorage.getItem("current_data");
      var data = this.$store.getters.patient;
      if (!item) {
        localStorage.setItem("current_data", JSON.stringify(data));
      }
      return data;
    },
    submit_types() {
      var data = this.$store.getters.submit_types;
      var id = this.patient.metadata.patient_submit_type_id;
      if (id) {
        this.patient_submit_type_id = id;
      } else {
        this.patient_submit_type_id = 1;
      }
      return data;
    },
    foundplaces() {
      var data = this.$store.getters.foundplaces;
      var id = this.patient.metadata.patient_found_place_id;
      if (id) this.patient_found_place_id = id;
      else this.patient_found_place_id = 1;
      return data;
    },
    submit_departments() {
      var data = this.$store.getters.submit_departments;
      var id = this.patient.metadata.patient_submit_department_id;
      if (id) this.patient_submit_department_id = id;
      else this.patient_submit_department_id = 1;
      return data;
    },
    updateloading() {
      return this.$store.getters.updateloading;
    },
    symptons() {
      var symptons = this.$store.getters.symptons;
      if (symptons) {
        symptons = symptons.filter((value) => {
          if (this.patient.age >= 15 && value.type == 2) {
            return value;
          } else if (this.patient.age < 15 && value.type == 1) {
            return value;
          }
        });
        var patient_symptons = this.patient.symptons.map((value) => {
          return value.id;
        });
        this.checked_symptons = [];
        symptons = symptons.map((value) => {
          if (patient_symptons.indexOf(value.id) != -1) {
            value.checked = true;
          } else {
            value.checked = false;
          }
          return value;
        });
      }
      return symptons;
    },
    sccs() {
      return this.$store.getters.patient_sccs;
    },
    refer_from() {
      var val = this.$store.getters.patient.metadata.refer_from;
      if (val == 1 || val == 7) {
        this.is_doctor = false;
        this.is_volunteer = true;
        this.is_township = false;
      } else if (val == 2 || val == 3) {
        this.is_volunteer = false;
        this.is_doctor = true;
        this.is_township = false;
      } else if (val == 5) {
        this.is_volunteer = false;
        this.is_doctor = false;
        this.is_township = true;
      } else {
        this.is_volunteer = false;
        this.is_doctor = false;
        this.is_township = false;
      }
      return val;
    },
    risk_factors() {
      var risk_factors = this.$store.getters.risk_factors;
      var patient_risk_factors = this.patient.presumptive_tb.risk_factors.map(
        (value) => {
          return value.id;
        }
      );
      this.checked_risk_factors = [];
      if (risk_factors) {
        risk_factors = risk_factors.map((value) => {
          if (patient_risk_factors.indexOf(value.id) != -1) {
            value.checked = true;
          } else {
            value.checked = false;
          }
          return value;
        });
      }
      return risk_factors;
    },
    delete_patient_finish() {
      return this.$store.getters.delete_patient_finish;
    },
    delete_loading() {
      return this.$store.getters.delete_loading;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.fill {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
}
</style>
