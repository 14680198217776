<template>
  <ul
    class="collapsible"
    style="margin: 0px; margin-top: 20px; margin-bottom: 5px"
  >
    <li>
      <div class="collapsible-header">
        <i class="material-icons">settings</i>Setting
      </div>
      <div class="collapsible-body">
        <div class="row">
          <div class="col s8">
            <router-link
              style="margin-left: 20px"
              to="/patient/dots/trash"
              class="btn blue darken-3"
              >Trash</router-link
            >
            <router-link
              style="margin-left: 20px"
              to="/patient/dots/add"
              class="btn blue darken-3"
              >Add New</router-link
            >
            <router-link
              style="margin-left: 20px"
              to="/patient/dots/refer"
              class="btn blue darken-3"
              >Refer List</router-link
            >
            <button
              style="margin-left: 20px"
              @click="$router.push('/patient/dots/list?page=1')"
              class="btn green darken-3"
            >
              View All
            </button>
            <button
              style="margin-left: 20px"
              @click="excelExport"
              class="btn green darken-3"
            >
              Export
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col s2">
            <input type="text" placeholder="Search by name" v-model="name" />
          </div>
          <div class="col s2">
            <select v-model="filter">
              <option value selected>Choose your option</option>
              <option value="1">DOTS Finish</option>
              <option value="2">DOTS Not Finish</option>
            </select>
          </div>
          <div class="col s2">
            <select v-model="month">
              <option value selected>Choose your month</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
          </div>
          <div class="col s2">
            <input type="text" placeholder="Year" v-model="year" />
          </div>
          <div class="col s2">
            <input
              placeholder="DOTS Start Date"
              type="text"
              class="datepicker"
              v-model.lazy="startDate"
              readonly
            />
          </div>
          <div class="col s2">
            <input
              placeholder="DOTS End Date"
              type="text"
              class="datepicker"
              v-model.lazy="endDate"
              readonly
            />
          </div>
          <div class="col s4" style="margin-top:20px;">
            <span>Volunteer</span>
            <select v-model="volunteer_id">
              <option
                v-for="item in volunteer"
                v-bind:key="item.id"
                v-bind:value="item.id"
                >{{ item.code }} ({{ item.name }})</option
              >
            </select>
          </div>
          <div class="col s12" style="margin-top:20px;text-align:center;">
            <button type="button" class="btn blue darken-3" @click="submitData">
              Search
            </button>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "PatientFilter",
  props: ["type"],
  data() {
    return {
      filter: "",
      name: "",
    };
  },
  mounted() {
    var elems = document.querySelectorAll(".collapsible");
    var instances = M.Collapsible.init(elems, {});
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
    });
  },
  updated() {
    var elems = document.querySelectorAll(".collapsible");
    var instances = M.Collapsible.init(elems, {});
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      format: "yyyy-mm-dd",
    });
  },

  methods: {
    excelExport() {
      var data = {
        page: 1,
      };
      if (this.$route.query.filter) {
        data.filter = this.$route.query.filter;
      }

      if (this.$route.query.month && this.$route.query.year) {
        data.month = this.$route.query.month;
        data.year = this.$route.query.year;
      }

      if (this.$route.query.name) {
        data.name = this.$route.query.name;
      }
      if (this.$route.query.page) {
        data.page = this.$route.query.page;
      }
      if (this.$route.query.volunteer_id) {
        data.volunteer_id = this.$route.query.volunteer_id;
      }

      if (this.$route.query.startDate && this.$route.query.endDate) {
        data.startDate = this.$route.query.startDate;
        data.endDate = this.$route.query.endDate;
      }

      this.$store.dispatch("dotsExcelExport", data);
    },

    submitData() {
      var data = {
        page: 1,
      };
      if (this.name) {
        data.name = this.name;
        this.$router.push(`/patient/dots/list?name=${this.name}&page=1`);
      }
      if (this.filter) {
        data.filter = this.filter;
      }
      if (this.month && this.year) {
        data.month = this.month;
        data.year = this.year;
      }

      if (this.startDate && this.endDate) {
        data.startDate = this.startDate;
        data.endDate = this.endDate;
      }

      if (this.volunteer_id) {
        data.volunteer_id = this.volunteer_id;
      }

      this.$router.push(
        `/patient/dots/list?${new URLSearchParams(data).toString()}`
      );
    },
  },
  components: {},
  computed: {
    volunteer() {
      return this.$store.getters.volunteers;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped></style>
